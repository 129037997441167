import { twMerge } from 'tailwind-merge';
import { IIconComponentProps } from '../../types/icons';

export function SortingIcon(props: IIconComponentProps & { variant?: string }) {
  return (
    <>
      {!props.variant && (
        <svg
          className={twMerge('h-5 w-5', props.className)}
          fill={'currentColor'}
          path={'currentColor'}
          viewBox='0 0 320 512'
          xmlns='http://www.w3.org/2000/svg'>
          <path d='M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8L32 224c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z' />
        </svg>
      )}
      {props.variant === 'asc' && (
        <svg
          className={twMerge('h-5 w-5', props.className)}
          fill={'currentColor'}
          viewBox='0 0 320 512'
          xmlns='http://www.w3.org/2000/svg'>
          <path d='M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z' />
        </svg>
      )}
      {props.variant === 'desc' && (
        <svg
          className={twMerge('h-5 w-5', props.className)}
          fill={'currentColor'}
          viewBox='0 0 320 512'
          xmlns='http://www.w3.org/2000/svg'>
          <path d='M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z' />
        </svg>
      )}
    </>
  );
}
