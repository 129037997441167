import React from 'react';
import { twMerge } from '../../index';

interface StepIndicatorProps {
  totalSteps: number;
  currentStep: number;
}

const StepIndicator = ({ totalSteps, currentStep }: StepIndicatorProps) => {
  const steps = Array.from({ length: totalSteps }, (_, i) => i + 1);

  return (
    <div className='tw-flex tw-items-center'>
      {steps.map((step, index) => (
        <>
          <div
            className={twMerge(
              'tw-border-nau-green-light-2 tw-text-nau-green-light-2 tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-bg-white tw-text-lg',
              step === currentStep && ' tw-bg-nau-green-light-2 tw-text-white',
            )}
            key={step}>
            {step}
          </div>
          {index < steps.length - 1 && (
            <div className='tw-bg-dashed-line tw-mx-2 tw-h-0.5 tw-flex-grow tw-text-nau-sea-green'></div>
          )}
        </>
      ))}
    </div>
  );
};

export default StepIndicator;
