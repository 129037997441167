import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { PhoneOfferType, PhoneSubscriptionLead, StepType } from '../../../types/phoneSubscription';
import { usePhoneSubscriptionLeadStore } from '../../../store/PhoneSubscription/phoneSubscriptionStore';
import agent from '../../../api/agent';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import MyCheckbox from '../../../components/FormControls/MyCheckbox/MyCheckbox';
import * as Yup from 'yup';

import {
  allRequiredApprovalsForPhoneSubscription,
  approvalForPhoneSubscriptionContract1,
  approvalForPhoneSubscriptionContract2,
  approvalForPhoneSubscriptionContract3,
  approvalForPhoneSubscriptionContract4,
  approvalForPhoneSubscriptionContract5,
  approvalForPhoneSubscriptionContract6,
  approvalForPhoneSubscriptionContract7,
  approvalForPhoneSubscriptionContract8,
  approvalForPhoneSubscriptionContractSecond1,
  approvalForPhoneSubscriptionContractSecond2,
} from '../phoneSubscriptionContants';
import RegexHelpers from '../../../helpers/RegexHelpers';
import ValidationHelper from '../../../helpers/ValidationHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import StepIndicator from '../../../components/Steps/StepIndicator';
import { OptionalApproval } from '../Approvals/OptionalApproval';
import { RequiredApproval } from '../Approvals/RequiredApproval';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().nullable().required('Pole jest wymagane'),
  lastName: Yup.string().nullable().required('Pole jest wymagane'),
  pesel: Yup.string()
    .nullable()
    .required('Pole jest wymagane')
    .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
    .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value ?? '')),
  contractEmail: Yup.string().nullable().email('Nieprawidłowy adres email').required('Wymagany jest adres email'),
  contractPhoneNumber: Yup.string()
    .nullable()
    .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
    .required('Pole jest wymagane'),
  approvalForContract1: Yup.bool().required('Zgoda jest wymagana').oneOf([true], 'Zgoda jest wymagana'),
  approvalForContract2: Yup.bool().required('Zgoda jest wymagana').oneOf([true], 'Zgoda jest wymagana'),
  approvalForContract3: Yup.bool().required('Zgoda jest wymagana').oneOf([true], 'Zgoda jest wymagana'),
  approvalForContract4: Yup.bool().required('Zgoda jest wymagana').oneOf([true], 'Zgoda jest wymagana'),
  approvalForContract5: Yup.bool().required('Zgoda jest wymagana').oneOf([true], 'Zgoda jest wymagana'),
  approvalForContract6: Yup.bool().required('Zgoda jest wymagana').oneOf([true], 'Zgoda jest wymagana'),
  approvalForContract7: Yup.bool().required('Zgoda jest wymagana').oneOf([true], 'Zgoda jest wymagana'),
  approvalForContract8: Yup.bool().required('Zgoda jest wymagana').oneOf([true], 'Zgoda jest wymagana'),
});

interface IInstallmentsFormOneProps {
  afterSave?: () => void;
}

export const InstallmentsFormOne = (props: IInstallmentsFormOneProps) => {
  const { lead, setStep } = usePhoneSubscriptionLeadStore();

  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-px-2 tw-py-6 tw-shadow-md sm:tw-px-6'>
      <StepIndicator currentStep={1} totalSteps={3} />
      <div className={'tw-my-4 tw-w-fit tw-cursor-pointer'} onClick={() => setStep(StepType.BuyPhone)}>
        <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...lead,
        }}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<PhoneSubscriptionLead>) => {
          const optionalApprovals: string[] = [];
          if (values.approvalForContractSecond1) {
            optionalApprovals.push(`1. ${approvalForPhoneSubscriptionContractSecond1}`);
          }
          if (values.approvalForContractSecond2) {
            optionalApprovals.push(`2. ${approvalForPhoneSubscriptionContractSecond2}`);
          }
          const requiredApprovals = allRequiredApprovalsForPhoneSubscription.map(
            (approval, index) => `${index + 1}. ${approval}`,
          );
          const allSelectedApprovals = requiredApprovals.concat(optionalApprovals);
          const concatenatedApprovals = allSelectedApprovals.map((approval) => `${approval}`).join('<br />');
          agent.NauMobile.updateLeadFormModel({
            ...values,
            currentStepId: StepType.LoanYourOfferOne,
            approvals: concatenatedApprovals,
          })
            .then(() => {
              props.afterSave?.();
            })
            .finally(() => setSubmitting(false));
        }}
        validationSchema={validationSchema}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className='tw-w-full'>
            <div className='tw-mb-4 tw-mt-4  tw-text-xl tw-font-semibold tw-text-nau-sea-green'>Dane osobowe</div>
            <div className='tw-flex tw-flex-col tw-gap-4'>
              <MyTextInput disabled={true} name='firstName' placeholder='Imię' />
              <MyTextInput disabled={true} name='lastName' placeholder='Nazwisko' />
              <MyTextInput name='contractPhoneNumber' placeholder='Nr telefonu' />
              <MyTextInput disabled={true} name='contractEmail' placeholder='Adres mailowy' />
              <MyTextInput disabled={true} name='pesel' placeholder='PESEL' />
            </div>

            <div className={'tw-text-nau-tw-text-nau-sea-green-green tw-pb-4 tw-pt-6 tw-text-center'}>
              Zgody są wymagane do ukończenia transakcji
            </div>

            <MyCheckbox
              checked={
                values.approvalForContract1 &&
                values.approvalForContract2 &&
                values.approvalForContract3 &&
                values.approvalForContract4 &&
                values.approvalForContract5 &&
                values.approvalForContract6 &&
                values.approvalForContract7 &&
                values.approvalForContract8 &&
                values.approvalForContractSecond1 &&
                values.approvalForContractSecond2 &&
                values.approvalForContractSecond3
              }
              label={<div>Zaznacz wszystkie zgody</div>}
              labelClassName={'tw-text-nau-gray'}
              name={`allApprovals`}
              onChange={(event) => {
                const allChecked = (event.target as HTMLInputElement).checked;
                setFieldValue('approvalForContract1', allChecked);
                setFieldValue('approvalForContract2', allChecked);
                setFieldValue('approvalForContract3', allChecked);
                setFieldValue('approvalForContract4', allChecked);
                setFieldValue('approvalForContract5', allChecked);
                setFieldValue('approvalForContract6', allChecked);
                setFieldValue('approvalForContract7', allChecked);
                setFieldValue('approvalForContract8', allChecked);
                setFieldValue('approvalForContractSecond1', allChecked);
                setFieldValue('approvalForContractSecond2', allChecked);
                setFieldValue('approvalForContractSecond3', allChecked);
              }}
              variant={'big'}
            />
            <div className={'tw-mt-4 tw-flex tw-flex-col tw-gap-4'}>
              <div>
                <MyCheckbox
                  label={RequiredApproval(approvalForPhoneSubscriptionContract1)}
                  name={`approvalForContract1`}
                  type='checkbox'
                  align='start'
                  variant={'big'}
                />
              </div>
              <div>
                <MyCheckbox
                  label={RequiredApproval(approvalForPhoneSubscriptionContract2)}
                  name={`approvalForContract2`}
                  type='checkbox'
                  align='start'
                  variant={'big'}
                />
              </div>
              <div>
                <MyCheckbox
                  label={RequiredApproval(approvalForPhoneSubscriptionContract3)}
                  name={`approvalForContract3`}
                  type='checkbox'
                  align='start'
                  variant={'big'}
                />
              </div>
              <div>
                <MyCheckbox
                  label={RequiredApproval(approvalForPhoneSubscriptionContract4)}
                  name={`approvalForContract4`}
                  type='checkbox'
                  align='start'
                  variant={'big'}
                />
              </div>
              <div>
                <MyCheckbox
                  label={RequiredApproval(approvalForPhoneSubscriptionContract5)}
                  name={`approvalForContract5`}
                  type='checkbox'
                  align='start'
                  variant={'big'}
                />
              </div>
              <div>
                <MyCheckbox
                  label={RequiredApproval(approvalForPhoneSubscriptionContract6)}
                  name={`approvalForContract6`}
                  type='checkbox'
                  align='start'
                  variant={'big'}
                />
              </div>
              <div>
                <MyCheckbox
                  label={RequiredApproval(approvalForPhoneSubscriptionContract7)}
                  name={`approvalForContract7`}
                  type='checkbox'
                  align='start'
                  variant={'big'}
                />
              </div>
              <div>
                <MyCheckbox
                  label={RequiredApproval(approvalForPhoneSubscriptionContract8)}
                  name={`approvalForContract8`}
                  type='checkbox'
                  align='start'
                  variant={'big'}
                />
              </div>
              <div>
                <MyCheckbox
                  label={OptionalApproval(approvalForPhoneSubscriptionContractSecond1)}
                  name={`approvalForContractSecond1`}
                  type='checkbox'
                  align='start'
                  variant={'big'}
                />
              </div>
              <div>
                <MyCheckbox
                  label={OptionalApproval(approvalForPhoneSubscriptionContractSecond2)}
                  name={`approvalForContractSecond2`}
                  type='checkbox'
                  align='start'
                  variant={'big'}
                />
              </div>
            </div>

            <div className='tw-mt-8'>
              <MyButton
                className='tw-w-full'
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type='submit'
                variant={'greenDark'}>
                Dalej
              </MyButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
