import React, { ReactNode } from 'react';

export const approvalForPhoneSubscriptionContract1 =
  'Na podstawie art. 24 ust. 1 ustawy z dnia 9 kwietnia 2010 r. o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych (t. j. Dz.U. z 2018 r., poz. 470), upoważniam Nauczycielską Agencję Ubezpieczeniową S.A. do pozyskania z biur informacji gospodarczej, a w szczególności z Biura Informacji Gospodarczej InfoMonitor S.A. z siedzibą w Warszawie przy ul. Zygmunta Modzelewskiego 77 (BIG InfoMonitor) dotyczących mnie informacji gospodarczych oraz do pozyskania za pośrednictwem BIG InfoMonitor danych gospodarczych z Biura Informacji Kredytowej S.A. (BIK) i Związku Banków Polskich (ZBP) dotyczących mojego wymagalnego od co najmniej 60 dni zadłużenia wobec banków lub instytucji upoważnionych do udzielania kredytów, przekraczających 200 złotych (dwieście złotych) lub braku o takim zadłużeniu. Jednocześnie upoważniam Nauczycielską Agencję Ubezpieczeniową S.A. (NAU S.A.) do pozyskania z BIG InfoMonitor informacji dotyczących składania zapytań na mój temat do Rejestru BIG InfoMonitor w ciągu ostatnich 12 miesięcy.';
export const approvalForPhoneSubscriptionContract2 =
  'Oświadczam, że wszystkie podane przeze mnie w niniejszym Wniosku informacje są prawdziwe i kompletne, według stanu na dzień złożenia Wniosku. Wyrażam zgodę na przetwarzanie moich danych osobowych przez Nauczycielską Agencję Ubezpieczeniową S.A. (NAU S.A.) na potrzeby weryfikacji informacji zawartych we Wniosku, w szczególności na kontakt z osobą obsługującą wynagrodzenie celem potwierdzenia zatrudnienia. Zobowiązuję się do aktualizacji swoich danych osobowych podanych we Wniosku w celu świadczenia usług przez NAU S.A. oraz oświadczam, że w przypadku ewentualnych późniejszych zmian danych osobowych, także będą one zgodne z prawdą.';
export const approvalForPhoneSubscriptionContract3 =
  'Oświadczam, że nie jestem osobą zajmującą eksponowane stanowisko polityczne (PEP), członkiem rodziny PEP ani bliskim współpracownikiem PEP w rozumieniu Ustawy z dnia 1 marca 2018 r. o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu (t. j. z 2021 r., poz. 1132).';
export const approvalForPhoneSubscriptionContract4 =
  'Na podstawie art. 24 ust. 1 ustawy z dnia 9 kwietnia 2010 r. o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych (t. j. Dz.U. 2021 poz. 2057) upoważniam Nauczycielską Agencję Ubezpieczeniową S.A. z siedzibą w Warszawie do wystąpienia do Krajowego Rejestru Długów Biura Informacji Gospodarczej S.A. o ujawnienie informacji gospodarczych dotyczących mojej osoby.';
export const approvalForPhoneSubscriptionContract5 =
  'Upoważniam Nauczycielską Agencję Ubezpieczeniową S.A. z siedzibą w Warszawie (dalej: „NAU S.A.”) do przekazania do Biura Informacji Kredytowej S.A. z siedzibą w Warszawie moich danych osobowych (zapytanie) i wyrażam zgodę na przetwarzanie przez Biuro Informacji Kredytowej S.A, w tym udostępnienie w odpowiedzi na złożone zapytanie informacji mnie dotyczących, w tym stanowiących tajemnicę bankową dla oceny zdolności kredytowej i analizy ryzyka kredytowego oraz na przetwarzanie w tym celu moich danych osobowych przekazanych przez NAU S.A. w zapytaniu przez okres nie dłuższy niż 2 lata, w tym ich udostępnianie bankom, instytucjom ustawowo upoważnionym do udzielania kredytów, instytucjom kredytowym, instytucjom pożyczkowym i podmiotom, o których mowa w art. 59d ustawy z dnia 12 maja 2011 r. o kredycie konsumenckim a także innym podmiotom na podstawie udzielonej im przeze mnie zgody.';
export const approvalForPhoneSubscriptionContract6 =
  'Upoważniam Nauczycielską Agencję Ubezpieczeniową S.A. z siedzibą w Warszawie (dalej: „NAU S.A.”) do przekazania do Biura Informacji Kredytowej S.A. z siedzibą w Warszawie moich danych osobowych i informacji dotyczących mojego zobowiązania wynikającego z zawartej z NAU S.A. umowy o kredyt konsumencki, w tym także zobowiązania po odstąpieniu od umowy, a także wyrażam zgodę na przetwarzanie przez Biuro Informacji Kredytowej, w tym udostępnianie S.A. informacji mnie dotyczących, w tym stanowiących tajemnicę bankową do NAU S.A. dla celów dokonywanej przez ten podmiot oceny zdolności kredytowej i analizy ryzyka kredytowego w trakcie obowiązywania zawartej z NAU S.A. umowy o kredyt konsumencki lub istnienia mojego zobowiązania po odstąpieniu od umowy oraz przetwarzanie przez Biuro Informacji Kredytowej S.A. moich danych osobowych i informacji dotyczących mojego zobowiązania wynikającego z zawartej z NAU S.A. umowy o kredyt konsumencki, w tym odstąpienia od umowy, po wygaśnięciu zobowiązania w celu oceny zdolności kredytowej i analizy ryzyka kredytowego i udostępnianie w tym celu tych danych bankom, instytucjom ustawowo upoważnionym do udzielania kredytów, instytucjom kredytowym, instytucjom pożyczkowym i podmiotom, o których mowa w art. 59d ustawy z dnia 12 maja 2011 r. o kredycie konsumenckim a także innym podmiotom na podstawie udzielonej im przeze mnie zgody.';
export const approvalForPhoneSubscriptionContract7 =
  'Potwierdzam, że zostałam(em) poinformowana(y) o prawie do odwołania powyższych zgód. Przyjmuję do wiadomości, że odwołanie zgody nie wpływa na zgodność z prawem przetwarzania danych przed jej odwołaniem.';
export const approvalForPhoneSubscriptionContract8 =
  'Na podstawie art. 24 ust. 1 Ustawy z dnia 9 kwietnia 2010 roku o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych (Dz.U. 2010 Nr 81 poz. 530) upoważniam Nauczycielską Agencję Ubezpieczeniową S.A. z siedzibą w Warszawie do wystąpienia za pośrednictwem Biura Informacji Kredytowej S.A. z siedzibą w Warszawie do biur informacji gospodarczej o ujawnienie informacji gospodarczych dotyczących moich zobowiązań.';
export const approvalForPhoneSubscriptionContractSecond1 =
  'BIG InfoMonitor S.A. \n Czy upoważnia Pan/Pani NAU Profit sp. z o.o. do pozyskania z biur informacji gospodarczej, a w szczególności z BIG InfoMonitor S.A. danych w zakresie Pana/Pani zadłużenia wobec banku lub innej instytucji ustawowo upoważnionej do udzielania kredytów lub o braku takiego mojego zadłużenia?';
export const approvalForPhoneSubscriptionContractSecond2 =
  'Wcześniejsze Świadczenie Usługi \n Czy żąda Pan/Pani rozpoczęcia świadczenia usług przez NAU Profit sp. z o.o. przed upływem terminu do odstąpienia od Umowy, tj. przed upływem 14 dni od dnia zawarcia Umowy? Nie będzie się to wiązać z utratą Pani/Pana prawa do odstąpienia od Umowy, jednak w razie jego późniejszego wykonania, będziecie Pan/Pani zobowiązany do zapłaty za usługi świadczone do chwili, w której poinformował nas Pan/Pani o odstąpieniu od Umowy?';

export const allRequiredApprovalsForPhoneSubscription = [
  approvalForPhoneSubscriptionContract1,
  approvalForPhoneSubscriptionContract2,
  approvalForPhoneSubscriptionContract3,
  approvalForPhoneSubscriptionContract4,
  approvalForPhoneSubscriptionContract5,
  approvalForPhoneSubscriptionContract6,
  approvalForPhoneSubscriptionContract7,
  approvalForPhoneSubscriptionContract8,
];
export const optionalApprovalsForPhoneSubscription = [
  approvalForPhoneSubscriptionContractSecond1,
  approvalForPhoneSubscriptionContractSecond2,
];
