import React, { useRef, useState } from 'react';
import MyLinkIcon from '../../components/Icons/MyLinkIcon';
import { Spinner } from 'react-bootstrap';

interface FileInputProps {
  label: string;
  onFileChange: (file: File | null) => Promise<void>;
}

const FileInput: React.FC<FileInputProps> = ({ label, onFileChange }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  const handleClick = () => {
    if (!loading) {
      fileInputRef.current?.click();
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) return;

    setLoading(true);
    try {
      await onFileChange(file);
      setUploadedFileName(file.name); // Update the displayed file name
    } catch (error) {
      console.error('File upload failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      aria-label={`Upload ${label}`}
      className={`tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-full tw-border tw-border-gray-300 tw-bg-white tw-px-4 tw-py-2 tw-shadow-md ${
        loading ? 'tw-pointer-events-none tw-opacity-50' : 'hover:tw-bg-gray-100'
      }`}
      onClick={handleClick}
      role='button'>
      <div className='tw-flex tw-items-center tw-space-x-2'>
        {loading ? (
          <div className=' tw-text-gray-500'>
            <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='dark' />
          </div>
        ) : (
          <MyLinkIcon className='tw-h-5 tw-w-5 tw-text-gray-500' />
        )}
        <span className='tw-text-gray-500'>{uploadedFileName ? uploadedFileName : label}</span>
      </div>
      <input className='tw-hidden' onChange={handleChange} ref={fileInputRef} type='file' />
    </div>
  );
};

export default FileInput;
