import React from 'react';
import { useStore } from '../../../store/store';
import { Link } from 'react-router-dom';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { Button } from 'react-bootstrap';
import { usePhoneSubscriptionLeadStore } from '../../../store/PhoneSubscription/phoneSubscriptionStore';

export const InstallmentThankYouPage = () => {
  const { modalStore } = useStore();
  const { lead } = usePhoneSubscriptionLeadStore();
  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-px-2 tw-py-6 tw-shadow-md sm:tw-px-6'>
      <div className={'tw-flex tw-flex-col tw-gap-10 '}>
        <div className={'tw-text-center tw-text-2xl tw-font-semibold tw-text-nau-sea-green'}>Podsumowanie</div>
          <div className={'tw-text-center tw-text-xl tw-text-nau-sea-green'}>
            Dziękujemy za zainteresowanie naszą ofertą
            <br />
            <br />
            Zweryfikujemy Twoje dane w ciągu 1 dnia roboczego. Następnie otrzymasz sms z informacją o wysłaniu umowy.
            <br />
            <br />
            Telefon wyślemy kurierem po uregulowaniu opłat i podpisaniu wyżej wskazanej umowy.
          </div>
        <div className='tw-px-4'>
          <Link className={'tw-w-full'} to={PathRoute.PUSTY}>
            <Button
              className={'tw-w-full'}
              onClick={() => {
                modalStore.closeModal();
              }}
              variant='primary'>
              Wróć do strony głównej
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
