import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from '../../../store/store';
import InstitutionAdminDashboard from '../../../containers/InstitutionAdminDashboard/InstitutionAdminDashboard';
import { institutionAdminDashboardRoutes } from '../../../routes/routes';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { history } from '../../../index';

export default observer(() => {
  const { userStore } = useStore();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const getAvailableMenuOptions = () => {
    return institutionAdminDashboardRoutes.filter((route) => {
      if (route.requiredInstitutionPermissions) {
        return userStore.hasInstitutionPermissions(route.requiredInstitutionPermissions);
      }
      return true;
    });
  };

  useEffect(() => {
    setIsLoading(true);
    const availableMenuOptions = getAvailableMenuOptions();
    if (availableMenuOptions.length === 1) {
      history.push(availableMenuOptions[0].to);
    }
    setIsLoading(false);
  }, []);

  return (
    <InstitutionAdminDashboard>
      {isLoading && <LoadingComponent />}
      <div className='tw-mx-auto tw-max-w-3xl tw-rounded-xl tw-bg-white tw-px-4 tw-py-8'>
        <div className={'tw-mb-10 tw-text-center tw-text-2xl tw-font-bold'}>Gdzie chcesz przejść?</div>
        <div className={'tw-grid tw-grid-cols-1 tw-gap-6 tw-p-4'}>
          {getAvailableMenuOptions().map((route) => (
            <Link key={route.name} to={route.to}>
              <div
                className={
                  'tw-relative tw-h-40 tw-cursor-pointer tw-rounded-2xl tw-bg-nau-green-light tw-p-4 tw-text-2xl tw-font-bold tw-text-white tw-transition-all hover:tw-scale-105'
                }>
                {route.name}
                <ArrowRightCircle
                  className={'tw-absolute tw-bottom-3 tw-right-3 tw-ml-4 tw-inline-flex tw-h-10 tw-w-10'}
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </InstitutionAdminDashboard>
  );
});
