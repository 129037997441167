import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { InstitutionAdminRouteProps } from './types';
import { PathRoute } from '../constants/pathRoute/Route';
import { useStore } from '../store/store';

const InstitutionAdminRoute: React.FC<InstitutionAdminRouteProps> = (props: InstitutionAdminRouteProps) => {
  const { userStore } = useStore();

  if (!userStore.isLoggedIn) {
    return <Redirect to={PathRoute.LOGIN} />;
  } else if (!userStore.isLoggedInstAdmin) {
    return <Redirect to={PathRoute.PUSTY} />;
  } else return <Route component={props.component} exact={props.exact} path={props.path} />;
};
export default InstitutionAdminRoute;
