import React from 'react';

import { twMerge } from 'tailwind-merge';
import { Cell, flexRender, HeaderGroup, Table as ReactTable } from '@tanstack/react-table';
import { TableFilter } from '../TableFilter/TableFilter';
import { SortingIcon } from '../Icons/SortingIcon';

interface IMyTanstackTableProps<T> {
  table: ReactTable<T>;
  showAllResultsCount?: boolean;
}

export function MyTanstackTable<T>(props: IMyTanstackTableProps<T>) {
  const { table, showAllResultsCount } = props;

  const filteredRowCount = table.getFilteredRowModel().rows.length;

  return (
    <>
      {showAllResultsCount && filteredRowCount > 0 && (
        <div className={'tw-flex'}>
          <div className={'tw-p-2'}>Znaleziono {filteredRowCount} elementów</div>
        </div>
      )}
      <table className={'tw-w-full'}>
        <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
          {table.getHeaderGroups().map((headerGroup: HeaderGroup<T>) => (
            <tr
              className={'tw-divide-x tw-divide-gray-300 tw-border-b-2 tw-border-gray-300 tw-bg-white'}
              key={headerGroup.id}
              style={{ width: '100%' }}>
              {headerGroup.headers.map((header) => (
                <th className={'tw-p-2 tw-align-top'} key={header.id}>
                  <div
                    className={twMerge(
                      'tw-flex tw-flex-col tw-p-2',
                      header.column.getCanSort() && 'tw-cursor-pointer',
                      'tw-flex tw-items-center',
                    )}>
                    <div
                      className={twMerge('tw-flex tw-items-center')}
                      onClick={() => header.column.getCanSort() && header.column.toggleSorting()}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort() && (
                        <div className={'tw-ml-2 tw-inline-flex tw-rounded-lg tw-bg-nau-sea-green tw-p-1'}>
                          <SortingIcon
                            className='tw-inline tw-h-4 tw-w-4 tw-text-white'
                            variant={header.column.getIsSorted() as string}
                          />
                        </div>
                      )}
                    </div>
                    {header.column.getCanFilter() && (
                      <div className={'tw-mt-2 tw-flex tw-flex-1 tw-items-end'}>
                        <div className={'tw-max-w-32'}>
                          {header.column.getCanFilter() && <TableFilter column={header.column} />}
                        </div>
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={'tw-divide-y tw-divide-gray-300'} style={{ position: 'relative' }}>
          {table.getSortedRowModel().rows.map((row) => {
            return (
              <tr
                className={'tw-divide-x tw-divide-gray-300 tw-bg-white tw-text-gray-900 even:tw-bg-gray-50'}
                data-index={row.index}
                key={row.id}>
                {row?.getVisibleCells().map((cell: Cell<T, unknown>) => (
                  <td className={'tw-p-2'} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {filteredRowCount === 0 && (
        <div className={'tw-flex'}>
          <div className={'tw-p-2 tw-font-semibold'}>Brak danych</div>
        </div>
      )}
    </>
  );
}
