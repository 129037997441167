import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useStore } from '../../store/store';
import InstitutionSelectItem from '../../components/InstitutionSelectItem/InstitutionSelectItem';
import { UserInstitution } from '../../types/user';
import { InstitutionRole } from '../../enums/roles.enums';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';

export default observer(() => {
  const { userStore } = useStore();
  const [adminInstitutions, setAdminInstitutions] = useState<UserInstitution[]>([]);
  const [individualInstitution, setIndividualInstitution] = useState<UserInstitution | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    checkInstitutions().finally(() => {
      setLoading(false);
      userStore.setIsLoggedToInst();
    });
  }, [userStore]);

  const checkInstitutions = async () => {
    const userInstitutionsFromApi: UserInstitution[] = await userStore.getUserInstitutions();
    if (userInstitutionsFromApi.length == 1) {
      await userStore.setUserInstitution(userInstitutionsFromApi[0]);
      return;
    } else {
      const individualInstitutionsFiltered = userInstitutionsFromApi
        .filter((ui: { roleId: number }) => ui.roleId == InstitutionRole.Customer)
        .sort((ui) => ui.institutionId);

      const adminInstitutionsFiltered = userInstitutionsFromApi
        .filter(
          (ui: { roleId: number }) =>
            ui.roleId == InstitutionRole.SuperAdmin || ui.roleId == InstitutionRole.InstitutionAdmin,
        )
        .sort((ui) => ui.institutionId);

      if (adminInstitutionsFiltered.length === 0 && individualInstitutionsFiltered.length > 0) {
        await userStore.setUserInstitution(individualInstitutionsFiltered[0]);
        return;
      }

      if (individualInstitutionsFiltered.length > 0) {
        setIndividualInstitution(individualInstitutionsFiltered[0]);
      }
      setAdminInstitutions(adminInstitutionsFiltered);
    }
  };
  return (
    <Container className='institutionSelect' style={{ textAlign: 'center' }}>
      {loading && <LoadingComponent />}
      <h2>Gdzie chcesz przejść?</h2>
      <div className={' tw-mx-auto tw-mt-10 tw-grid tw-max-w-2xl tw-grid-cols-1 '}>
        <div className={'tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-6 tw-p-6'}>
          {individualInstitution != null && (
            <InstitutionSelectItem
              item={individualInstitution}
              key={individualInstitution.userInstitutionId + individualInstitution.roleId}
            />
          )}
          {adminInstitutions.map((item: UserInstitution) => (
            <InstitutionSelectItem item={item} key={item.userInstitutionId + item.roleId} />
          ))}
        </div>
      </div>
    </Container>
  );
});
