import { Column } from '@tanstack/react-table';
import React from 'react';
import { DebouncedInput } from '../FormControls/DebouncedInput';

export function TableFilter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue() as string | boolean | undefined;
  const { filterVariant } = column.columnDef.meta ?? ({} as any);

  return (
    <div>
      {filterVariant === 'text' && (
        <DebouncedInput
          className='tw-w-full tw-rounded-xl tw-border-gray-600 tw-text-gray-700 tw-shadow placeholder:tw-text-gray-700 focus:tw-border-nau-sea-green focus:tw-ring-0'
          debounce={200}
          onChange={(value) => column.setFilterValue(value)}
          placeholder={`Filtruj...`}
          type='text'
          value={(columnFilterValue ?? '') as string}
        />
      )}
      {filterVariant === 'boolean' && (
        <select
          className='tw-w-28 tw-rounded-xl tw-border tw-border-gray-600 tw-p-2 tw-text-gray-700 tw-shadow focus:tw-border-nau-sea-green focus:tw-ring-0'
          onChange={(e) =>
            column.setFilterValue(e.target.value === 'true' ? true : e.target.value === 'false' ? false : undefined)
          }
          value={columnFilterValue === true ? 'true' : columnFilterValue === false ? 'false' : ''}>
          <option value=''>Filtruj...</option>
          <option value='true'>Tak</option>
          <option value='false'>Nie</option>
        </select>
      )}
    </div>
  );
}
