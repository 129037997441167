import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { PathRoute } from '../constants/pathRoute/Route';
import { InAppBrowser } from '@capgo/inappbrowser';

export const openLink = async (link: string, newTab = true) => {
  if (Capacitor.isNativePlatform()) {
    await Browser.open({ url: link });
  } else {
    const target = newTab ? '_blank' : '_self';
    window.open(link, target, 'noreferrer');
  }
};
export const openLinkInAppBrowser = async (
  link: string,
  newTab = true,
  linkToClose?: string,
  callbackFunction?: (link: string) => void,
) => {
  if (Capacitor.isNativePlatform()) {
    await InAppBrowser.openWebView({
      url: link,
      visibleTitle: false,
      title: '',
    });
    if (!linkToClose) return;
    closeBrowserOnLink(linkToClose, callbackFunction);
  } else {
    const target = newTab ? '_blank' : '_self';
    window.open(link, target, 'noreferrer');
  }
};
export const closeBrowserOnLink = (linkToClose: string, callbackFunction?: (link: string) => void) => {
  let currentUrl = '';
  if (Capacitor.isNativePlatform()) {
    InAppBrowser.addListener('closeEvent', () => {
      if (callbackFunction) callbackFunction(currentUrl);
    });
    InAppBrowser.addListener('urlChangeEvent', async (event: any) => {
      currentUrl = event.url;
      if (currentUrl.includes(linkToClose)) {
        currentUrl = currentUrl.replace('https://dev.app.nau.pl', '');
        currentUrl = currentUrl.replace('https://klub.nau.pl', '');
        await InAppBrowser.close();
      }
    });
  }
};

export const getPathOrRedirect = (path: (typeof PathRoute)[keyof typeof PathRoute]) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('redirect') || path;
};

export const getPathWithRedirectIfExists = (path: (typeof PathRoute)[keyof typeof PathRoute]) => {
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');

  if (redirect) {
    return `${path}?redirect=${encodeURIComponent(redirect)}`;
  }

  return path;
};
