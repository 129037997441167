import React, { useEffect, useState } from 'react';
import { useSwrAgent } from '../../api/useSwrAgent';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import {
  ContractModel,
  PhoneOfferType,
  PhonePurchaseType,
  PhonePurchaseTypeOptions,
  PhoneSubscriptionLead,
  StepType,
} from '../../types/phoneSubscription';
import { usePhoneSubscriptionLeadStore } from '../../store/PhoneSubscription/phoneSubscriptionStore';
import agent from '../../api/agent';
import { twMerge } from '../../index';
import _ from 'lodash';
import Config from '../../Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import { PhoneDescription } from './PhoneDescription';
import { PhoneSubscriptionDescription } from './PhoneSubscriptionDescription';
import { useStore } from '../../store/store';
import CalculationHelpers from '../../helpers/CalculationHelpers';

interface PhoneAndPromoFormProps {
  afterSave?: (nextStep: StepType) => void;
}

const availableInitialPayment: number[] = [0, 200, 400, 800];
const INSTALLMENTS_SUBSCRIPTION_COUNT = 24;
const INSTALLMENTS_PHONE_COUNT = 12;

export const PhonePaymentForm = (props: PhoneAndPromoFormProps) => {
  const { data: phoneModels, isLoading: arePhoneModelsLoading } = useSwrAgent().NauMobile.GetAvailablePhonesModel();
  const { lead, setStep, updateLead } = usePhoneSubscriptionLeadStore();
  const [selectedSubscription, setSelectedSubscription] = useState<ContractModel>();
  const phoneToShow = phoneModels?.find((phone) => phone.id === lead.selectedPhoneId);
  const { modalStore } = useStore();

  const getPercentageDiscount = (values: PhoneSubscriptionLead) => {
    const hasBrOrRrPolicy = values.hasBrOrRrPolicy;
    const lifeInsuranceOwnership = values.lifeInsuranceOwnership;
    let percentageDiscount = 0;
    if (hasBrOrRrPolicy) {
      percentageDiscount += 10;
    }
    if (lifeInsuranceOwnership) {
      percentageDiscount += 10;
    }
    return percentageDiscount;
  };
  const getOneMonthInstallmentPriceDecimal = (values: PhoneSubscriptionLead) => {
    const priceAfterDiscountAndInitialPayment = getPhonePriceAfterDiscount(values) - (values.initialPhonePayment ?? 0);

    if (values.offerTypeId != PhoneOfferType.Phone) {
      return getInstallmentWithPercent(priceAfterDiscountAndInitialPayment);
    } else {
      return priceAfterDiscountAndInitialPayment / INSTALLMENTS_PHONE_COUNT;
    }
  };
  const getInstallmentWithPercent = (phonePrice: number): number => {
    const annualPercent = 0.1;
    const commission = 0.09;
    const numberOfMonths: number = INSTALLMENTS_SUBSCRIPTION_COUNT;
    const installmentWithPercent = CalculationHelpers.calculateMonthlyInstllment(
      annualPercent,
      commission,
      numberOfMonths,
      phonePrice,
    );
    return installmentWithPercent;
  };

  const getOneMonthInstallmentPrice = (values: PhoneSubscriptionLead) => {
    return getOneMonthInstallmentPriceDecimal(values).toFixed(2).replaceAll('.', ',');
  };

  const getPhonePriceAfterDiscount = (values: PhoneSubscriptionLead) => {
    const phoneValue = (phoneToShow?.price ?? 0) * ((100 - getPercentageDiscount(values)) / 100);
    return Math.round((phoneValue * 100) / 100);
  };
  useEffect(() => {
    if (lead.providerTypeId && lead.selectedSubscriptionId) {
      agent.NauMobile.getAvailableProviderContracts(lead.providerTypeId).then((response) => {
        setSelectedSubscription(response.find((x) => x.id === lead.selectedSubscriptionId));
      });
    }
    if (lead.initialPhonePayment && !availableInitialPayment.includes(lead.initialPhonePayment)) {
      updateLead({ initialPhonePayment: undefined });
    }
  }, [lead]);
  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white  tw-shadow-md'>
      <LoadingComponent content='Ładowanie...' visible={arePhoneModelsLoading} />

      <Formik
        enableReinitialize={true}
        initialValues={lead}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<PhoneSubscriptionLead>) => {
          const valuesCopy = _.cloneDeep(values);
          if (valuesCopy.phonePurchaseTypeId === PhonePurchaseType.Installments) {
            if (valuesCopy.offerTypeId != PhoneOfferType.Phone && selectedSubscription) {
              valuesCopy.initialPhonePayment =
                getOneMonthInstallmentPriceDecimal(valuesCopy) +
                (valuesCopy.initialPhonePayment ?? 0) +
                selectedSubscription.monthlyPrice * 12 +
                20 +
                1.23;
            } else {
              valuesCopy.initialPhonePayment =
                getOneMonthInstallmentPriceDecimal(valuesCopy) + (valuesCopy.initialPhonePayment ?? 0);
            }
          } else {
            valuesCopy.initialPhonePayment = undefined;
          }
          if (valuesCopy.offerTypeId != PhoneOfferType.Phone) {
            valuesCopy.installmentMonths = INSTALLMENTS_SUBSCRIPTION_COUNT;
          } else {
            valuesCopy.installmentMonths = INSTALLMENTS_PHONE_COUNT;
          }
          agent.NauMobile.updateLeadFormModel({ ...valuesCopy, currentStepId: StepType.BuyPhone })
            .then(() => {
              if (valuesCopy.phonePurchaseTypeId === PhonePurchaseType.Installments) {
                props.afterSave?.(StepType.LoanYourOfferOne);
              } else if (valuesCopy.phonePurchaseTypeId === PhonePurchaseType.Cash) {
                props.afterSave?.(StepType.CashYourOfferOne);
              }
            })
            .finally(() => setSubmitting(false));
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className='tw-w-full'>
            <div className={'tw-w-full tw-px-2 tw-pt-6 sm:tw-px-6 '}>
              <div className={'tw-mb-4 tw-w-fit tw-cursor-pointer '} onClick={() => setStep(StepType.SelectProvider)}>
                <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
              </div>
              {!!phoneToShow && (
                <>
                  <div className={'tw-mx-auto tw-max-w-[300px] tw-rounded-xl tw-border-gray-300  tw-p-4  tw-shadow'}>
                    <div
                      className=' tw-min-h-0'
                      style={{
                        paddingBottom: '100%', // Ensures the div is square
                        position: 'relative',
                      }}>
                      <img
                        alt={'phone_thumbnail'}
                        className='tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-contain'
                        src={
                          (Config.appUrl + '/File/GetPhoneImage?fileId=').replace('//File', '/File') +
                          phoneToShow.imageId
                        }
                      />
                    </div>
                    <div className={'tw-mt-4 tw-text-center tw-text-nau-sea-green'}>{phoneToShow.modelName}</div>
                  </div>

                  <PhoneDescription phone={phoneToShow} showParameters={true} />
                </>
              )}
              {selectedSubscription && (
                <div className='tw-my-[50px]'>
                  <PhoneSubscriptionDescription subscription={selectedSubscription} />
                </div>
              )}
            </div>

            <div className={'tw-mt-8 tw-bg-nau-green-light tw-p-4 tw-text-white'}>
              <div className='tw-mb-2  tw-text-center tw-text-xl tw-font-semibold tw-text-white'>
                Wybierz rodzaj płatności za telefon
              </div>
              <div className={'tw-w-full tw-pt-2'}>
                <div
                  className={
                    'tw-mx-auto tw-flex tw-w-fit tw-max-w-full tw-flex-col tw-gap-4 tw-overflow-x-auto sm:tw-flex-row'
                  }>
                  {PhonePurchaseTypeOptions.map((option) => (
                    <div key={option.value}>
                      <div
                        className={twMerge(
                          'tw-flex tw-h-[43px] tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-white tw-px-4 tw-text-center',
                          values.phonePurchaseTypeId === option.value && 'tw-bg-white tw-text-black',
                        )}
                        onClick={() => {
                          setFieldValue('phonePurchaseTypeId', option.value);
                          if (option.value === PhonePurchaseType.Installments) {
                            setFieldValue('initialPhonePayment', 0);
                          }
                        }}>
                        {option.label}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={' tw-bg-nau-green-light tw-px-2 tw-py-4 tw-text-white sm:tw-px-6'}>
              {phoneToShow && (
                <div className=' tw-text-sm tw-text-white'>
                  <div>
                    <div className={' tw-font-semibold'}>
                      <div className={'tw-relative tw-inline-block tw-text-6xl tw-leading-none'}>
                        <span>{phoneToShow.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>
                        {phoneToShow.price != getPhonePriceAfterDiscount(values) && (
                          <div
                            className={
                              'tw-absolute tw-inset-x-0 tw-top-1/2 tw-h-[6px]  tw-w-full tw--translate-y-1/2 tw--rotate-[13deg]  tw-rounded-full tw-bg-[#f90707]'
                            }></div>
                        )}
                      </div>
                      <span className={'tw-text-xl '}> zł</span>
                    </div>
                  </div>
                  {phoneToShow.price != getPhonePriceAfterDiscount(values) && (
                    <div>
                      <div className={' tw-mt-4 tw-text-xs tw-font-normal'}>Cena telefonu po rabatach</div>
                      <div className={' tw-font-semibold'}>
                        <span className={'tw-text-6xl '}>
                          {getPhonePriceAfterDiscount(values)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                        <span className={'tw-text-xl '}> zł</span>
                      </div>
                      {phoneToShow.description && (
                        <div
                          className={'tw-mt-2 tw-w-full tw-font-normal'}
                          dangerouslySetInnerHTML={{ __html: phoneToShow.description }}></div>
                      )}
                    </div>
                  )}
                  {values.phonePurchaseTypeId === PhonePurchaseType.Cash && (
                    <>
                      {lead.offerTypeId == PhoneOfferType.Phone && (
                        <div>
                          <div className='tw-mt-8 tw-flex tw-flex-col tw-items-start tw-justify-start tw-border-t tw-pt-4'>
                            <div className='tw-font-normal'>Do zapłaty</div>
                            <div className={'tw-font-semibold'}>
                              <span className={'tw-text-6xl '}>
                                {getPhonePriceAfterDiscount(values)
                                  .toFixed(0)
                                  .replaceAll('.', ',')
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                              </span>
                              <span className={'tw-text-xl '}> zł</span>
                            </div>
                          </div>
                        </div>
                      )}
                      {lead.offerTypeId != PhoneOfferType.Phone && selectedSubscription && (
                        <div>
                          <div className='tw-mt-4 tw-flex tw-items-center tw-justify-between'>
                            <div className='tw-font-semibold'>{getPhonePriceAfterDiscount(values) + 'zł'}</div>
                            <div className='tw-font-normal'>Cena telefonu</div>
                          </div>
                          <div className='tw-mt-4 tw-flex tw-items-center tw-justify-between'>
                            <div className='tw-font-semibold'>{`${selectedSubscription.monthlyPrice} zł * 12 mc = ${(selectedSubscription.monthlyPrice * 12).toFixed(2).replaceAll('.', ',')} zł`}</div>
                            <div className='tw-font-normal'>Kwota usługi SIM NAU</div>
                          </div>
                          <div className='tw-mt-4 tw-flex tw-items-center tw-justify-between'>
                            <div className='tw-font-semibold'>{`20 zł`}</div>
                            <div className='tw-font-normal'>Opłata operacyjna</div>
                          </div>
                          <div className='tw-mt-4 tw-flex tw-items-center tw-justify-between'>
                            <div className='tw-font-semibold'>{`1,23 zł`}</div>
                            <div className='tw-font-normal'>Opłata aktywacyjna</div>
                          </div>
                          <div className='tw-mt-8 tw-flex tw-flex-col tw-items-start tw-justify-start tw-border-t tw-pt-4'>
                            <div className='tw-font-normal'>Do zapłaty</div>
                            <div className={'tw-font-semibold'}>
                              <span className={'tw-text-6xl '}>
                                {(
                                  getPhonePriceAfterDiscount(values) +
                                  selectedSubscription.monthlyPrice * 12 +
                                  20 +
                                  1.23
                                )
                                  .toFixed(2)
                                  .replaceAll('.', ',')
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                              </span>
                              <span className={'tw-text-xl '}> zł</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {values.phonePurchaseTypeId === PhonePurchaseType.Installments && (
                    <div className='tw-font-normal tw-text-white'>
                      <div className='tw-mb-2  tw-text-center tw-text-xl tw-font-normal tw-text-white'>
                        WARUNKI FINANSOWANIA
                      </div>
                      <div>
                        Wybrana opcja płatności:{' '}
                        <strong>{lead.offerTypeId == PhoneOfferType.Phone ? '12 rat' : '24 raty'}</strong>
                      </div>
                      <div className={'tw-mt-2'}>Opłata na start</div>
                      <div className={'tw-mx-auto tw-mt-2 tw-grid tw-max-w-[400px] tw-grid-cols-2 tw-gap-2'}>
                        {availableInitialPayment.map((item) => (
                          <div className={'tw-flex tw-w-full tw-justify-center'} key={item}>
                            <div
                              className={twMerge(
                                'tw-flex tw-h-[43px] tw-w-[140px] tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-white tw-text-center',
                                item === values.initialPhonePayment && 'tw-bg-white tw-text-black',
                              )}
                              key={item}
                              onClick={() => {
                                setFieldValue('initialPhonePayment', item);
                                const newValues = _.cloneDeep(values);
                                newValues.initialPhonePayment = item;
                                const installmentPrice = getOneMonthInstallmentPriceDecimal(newValues);
                                setFieldValue('nextMonthsPayments', installmentPrice);
                              }}>
                              {item} zł
                            </div>
                          </div>
                        ))}
                      </div>
                      {lead.offerTypeId == PhoneOfferType.Phone ? (
                        <div className={'tw-mb-4 tw-mt-4 tw-text-xs tw-font-light'}>
                          Przykład reprezentatywny: Rzeczywista Roczna Stopa Oprocentowania (RRSO) dla oferty pożyczek
                          wynosi 0,00 %. Okres obowiązywania umowy: 12 miesiące, równe raty miesięczne w wysokości
                          250,00 zł i ostatnia rata w wysokości 250,00 zł, całkowita kwota pożyczki 3 000,00 zł;
                          oprocentowanie stałe: 0,00 %; całkowity koszt zobowiązania: 0,00 zł (w tym prowizja: 0,00 zł,
                          odsetki: 0,00 zł); całkowita kwota do zapłaty: 3 000,00 zł. Kalkulacja została dokonana na
                          dzień 28.11.2024 roku na reprezentatywnym przykładzie. Przyznanie pożyczki zależy od wyniku
                          oceny zdolności kredytowej wnioskodawcy. Założenia przyjęte do obliczenia RRSO: − Umowa
                          Pożyczki będzie obowiązywała przez czas, na który została zawarta, a Pożyczkodawca i Pan/Pani
                          wypełnią zobowiązania wynikające z umowy w terminach w niej określonych.
                          <br />
                          <br />
                          Zakup urządzenia na raty wiąże się z koniecznością zawarcia umowy pożyczki. Pożyczkodawcą jest
                          Nauczycielska Agencja Ubezpieczeniowa S.A. z siedzibą w Warszawie (00-379), przy ulicy
                          Wybrzeże Kościuszkowskie 31/33, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru
                          Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XII Wydział
                          Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000093218, posiadająca NIP:
                          5252100353, REGON: 014871876, kapitał zakładowy w wysokości 1.000.000 złotych (wpłacony w
                          całości), wpisana do Rejestru Instytucji Pożyczkowych, prowadzonego przez Komisję Nadzoru
                          Finansowego pod numerem: RIP000410.
                        </div>
                      ) : (
                        <div className={'tw-mb-4 tw-mt-4 tw-text-xs tw-font-light'}>
                          Przykład reprezentatywny: Rzeczywista Roczna Stopa Oprocentowania (RRSO) dla oferty pożyczek
                          wynosi 19,48 %. Okres obowiązywania umowy: 24 miesiące, równe raty miesięczne w wysokości
                          149,68 zł i ostatnia rata w wysokości 149,46 zł, całkowita kwota pożyczki 3 000,00 zł;
                          oprocentowanie stałe: 10,00 %; całkowity koszt zobowiązania: 592,10 zł (w tym prowizja: 270,00
                          zł, odsetki: 322,10 zł); całkowita kwota do zapłaty: 3 592,10 zł. Kalkulacja została dokonana
                          na dzień 03.12.2024 roku na reprezentatywnym przykładzie. Przyznanie pożyczki zależy od wyniku
                          oceny zdolności kredytowej wnioskodawcy. Założenia przyjęte do obliczenia RRSO: − Umowa
                          Pożyczki będzie obowiązywała przez czas, na który została zawarta, a Pożyczkodawca i Pan/Pani
                          wypełnią zobowiązania wynikające z umowy w terminach w niej określonych.
                          <br />
                          <br />
                          Zakup urządzenia na raty wiąże się z koniecznością zawarcia umowy pożyczki. Pożyczkodawcą jest
                          Nauczycielska Agencja Ubezpieczeniowa S.A. z siedzibą w Warszawie (00-379), przy ulicy
                          Wybrzeże Kościuszkowskie 31/33, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru
                          Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XII Wydział
                          Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000093218, posiadająca NIP:
                          5252100353, REGON: 014871876, kapitał zakładowy w wysokości 1.000.000 złotych (wpłacony w
                          całości), wpisana do Rejestru Instytucji Pożyczkowych, prowadzonego przez Komisję Nadzoru
                          Finansowego pod numerem: RIP000410.
                        </div>
                      )}

                      {selectedSubscription && lead.offerTypeId != PhoneOfferType.Phone && (
                        <div className='tw-mb-[50px]'>
                          <div className={'tw-flex tw-items-center tw-justify-between tw-gap-4 tw-py-4'}>
                            <div className='tw-font-semibold'>{values.initialPhonePayment ?? 0} zł</div>
                            <div>Do zapłaty na start</div>
                          </div>
                          <div className={'tw-flex tw-items-center tw-justify-between tw-gap-4 tw-py-4'}>
                            <div className='tw-font-semibold'>{getOneMonthInstallmentPrice(values)} zł</div>
                            <div>Kwota miesięcznej raty telefonu</div>
                          </div>
                          <div className='tw-mt-4 tw-flex tw-items-center tw-justify-between'>
                            <div className='tw-font-semibold'>{`${selectedSubscription.monthlyPrice.toFixed(2).replaceAll('.', ',')} zł * 12 mc = ${(selectedSubscription.monthlyPrice * 12).toFixed(2).replaceAll('.', ',')} zł`}</div>
                            <div className='tw-font-normal'>Kwota usługi SIM NAU</div>
                          </div>
                          <div className='tw-mt-4 tw-flex tw-items-center tw-justify-between'>
                            <div className='tw-font-semibold'>{`20 zł`}</div>
                            <div className='tw-font-normal'>Opłata operacyjna</div>
                          </div>
                          <div className='tw-mt-4 tw-flex tw-items-center tw-justify-between'>
                            <div className='tw-font-semibold'>{`1,23 zł`}</div>
                            <div className='tw-font-normal'>Opłata aktywacyjna</div>
                          </div>
                          <div className='tw-mt-[50px] tw-flex tw-flex-col tw-items-start tw-justify-start tw-border-t tw-pt-4'>
                            <div className='tw-font-normal'>Pierwsza płatność</div>
                            <div className='tw-font-normal'>(usługa SIM NAU + płatności ratalne)</div>
                            <div className='tw-text-6xl tw-font-semibold'>
                              {(
                                getOneMonthInstallmentPriceDecimal(values) +
                                (values.initialPhonePayment ?? 0) +
                                selectedSubscription.monthlyPrice * 12 +
                                20 +
                                1.23
                              )
                                .toFixed(2)
                                .replaceAll('.', ',')
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' zł'}
                            </div>
                          </div>
                          <div className='tw-mt-[50px] tw-flex tw-flex-col tw-items-start tw-justify-start tw-pt-4'>
                            <div className='tw-font-normal'>Wysokość raty miesięcznej</div>
                            <div className='tw-text-6xl tw-font-semibold'>
                              {getOneMonthInstallmentPrice(values).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' zł'}
                            </div>
                          </div>
                        </div>
                      )}
                      {lead.offerTypeId == PhoneOfferType.Phone && (
                        <div className='tw-mb-[50px]'>
                          <div className={'tw-flex tw-items-center tw-justify-between tw-gap-4 tw-py-4'}>
                            <div className='tw-font-semibold'>{values.initialPhonePayment ?? 0} zł</div>
                            <div>Do zapłaty na start</div>
                          </div>
                          <div className={'tw-flex tw-items-center tw-justify-between tw-gap-4 tw-py-4'}>
                            <div className='tw-font-semibold'>{getOneMonthInstallmentPrice(values)} zł</div>
                            <div>Kwota miesięcznej raty telefonu</div>
                          </div>
                          <div className='tw-mt-[50px] tw-flex tw-flex-col tw-items-start tw-justify-start tw-border-t tw-pt-4'>
                            <div className='tw-font-normal'>Pierwsza płatność</div>
                            <div className='tw-text-6xl tw-font-semibold'>
                              {(getOneMonthInstallmentPriceDecimal(values) + (values.initialPhonePayment ?? 0))
                                .toFixed(2)
                                .replaceAll('.', ',')
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' zł'}
                            </div>
                          </div>
                          <div className='tw-mt-[50px] tw-flex tw-flex-col tw-items-start tw-justify-start tw-pt-4'>
                            <div className='tw-font-normal'>Wysokość raty miesięcznej</div>
                            <div className='tw-text-6xl tw-font-semibold'>
                              {getOneMonthInstallmentPrice(values).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' zł'}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              <div className='tw-mb-2 tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
                <MyButton
                  className='tw-w-full tw-text-nau-green-light'
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                  variant={'white'}>
                  Przejdź do uzupełnienia danych do oferty
                </MyButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
