import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import Config from '../Config';
import { store } from '../store/store';
import { history } from '../index';
import {
  AuthenticationResult,
  CreateUserPhoneModel,
  CustomerRegisterFormValues,
  EditUserPhoneModel,
  ForgotPasswordFormValues,
  LoanBenefitManagment,
  User,
  UserApprovals,
  UserAvailableBenefits,
  UserFileModel,
  UserFormValues,
  UserInstitution,
  UserInstitutionSearchModel,
  UserList,
  UserLoansFileModel,
  UserMarketingData,
  UserPhoneModel,
  UserProfileModel,
  UserProfileModelResponse,
  UserProfileNewModel,
  UserRefresh,
  UserRegisterFormValues,
  VerifyNewPasswordFormValues,
  VerifyUserPhoneModel,
} from '../types/user';
import { CircleKCardModel, CircleKContractFormValues } from '../types/circleK';
import { ContactFormValues, DeleteAccountFormValues } from '../types/contactForm';
import { NotifcationModel } from '../store/notificationStore/notificationStore';
import { ArticleModel } from '../store/articlesStore/articlesStore';
import { PathRoute } from '../constants/pathRoute/Route';
import {
  Loan,
  LoanApplicationUserData,
  LoanContractAdditionalData,
  LoanContractAddressData,
  LoanContractEmploymentData,
  LoanContractUserData,
  LoanDataCashmir,
  MonthlyLoanApplicationForInstitutionM,
  UserLoanLimit,
} from '../types/loans';
import { MySelectInputOptions } from '../components/FormControls/MySelectInput/types';
import { TableResultM } from '../types/tableResult';
import {
  DiscountCodeCampaignM,
  DiscountCodeContractAddressData,
  DiscountCodeContractData,
  DiscountCodeLeadM,
  DiscountCodeM,
  DiscountCodeOrderDataM,
} from '../types/discountCode';
import { ErrorMessagesE } from '../enums/errorMessages.enums';
import { AppVersionM } from '../types/appVersion';
import { BirthdayCodeCampaignM, BirthdayCodeCampaignOrderM } from '../types/birthdayCode';
import { UserWalletLimit, WalletApplicationUserData, WalletNauContractData } from '../types/walletNau';
import { UserPayment } from '../types/payments';
import getUrls from './getUrls';
import {
  AddCinemaVoucherToOrderM,
  CinemaVoucherCompaniesM,
  CinemaVoucherIsAvailableM,
  CinemaVoucherM,
  CinemaVoucherOrderClientDataM,
  CinemaVoucherOrderDataM,
  ICinemaVoucherDirectorFormValues,
  IDirectorCinemaPromoWithinInstitutionValidationResult,
  RateCinemaVouchersOrderM,
} from '../types/cinemaVoucher';
import { CustomerWithCashbackDataDTO, ICashbackSopPolicyGroupDto } from '../types/cashback';
import { Capacitor } from '@capacitor/core';
import {
  InstitutionFromBirFromSopDto,
  InstitutionSopCreateModel,
  InstitutionWithAddressFromSopDTO,
} from '../types/institution';
import { CallBookLeadFormValues } from '../types/leads';
import {
  CheckInsuredPersonByPeselResponseDTO,
  KlInsuredPersonBRM,
  KlOrderFormM,
  KlSaveOrderResponse,
} from '../types/kl';
import { IRequestForSportCardFormValues, ISportCardApplicationFormValues, ISportCardDto } from '../types/sportCard';
import { SportCardCompany } from '../enums/sportCardCompany.enums';
import { PropertyInsuranceFormValues } from '../types/propertyInsurance';
import { CarInsuranceFormValues } from '../types/carInsurance';
import { TripInsuranceFormValues } from '../types/tripInsurance';
import { IGetCitiesFormValues, IGetStreetsFormValues } from '../types/address';
import { extractQueryParams } from '../utils/requestUtils';
import {
  ContractModel,
  IInterestedInAnotherPhoneFormValues,
  IPhoneSubscriptionPromotionValidationFormValues,
  PhoneSubscriptionLead,
} from '../types/phoneSubscription';

let isRefreshing = false;
let preventLogout = false;
const customAxios = axios.create({
  baseURL: 'https://centrum24.nau.pl/circlek_api/',
});

export const articleAxios = axios.create({
  baseURL: 'https://centrum24.nau.pl/articles_api/',
});

const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

const waitUntilRefresh = async () => {
  let errorCounter = 0;
  while (isRefreshing && errorCounter < 10) {
    errorCounter++;
    await sleep(500);
  }
  return true;
};
axios.defaults.baseURL = Config.appUrl;
axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
  if (isRefreshing && config.url != '/identity/refresh') {
    waitUntilRefresh();
  }
  const { token } = store.commonStore;
  if (token) {
    config.headers!.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(
  async (response) => {
    // await sleep(400);
    return response;
  },
  async (error: AxiosError) => {
    const { data, status } = error.response!;
    const originalRequest = error.config;
    if (isRefreshing && originalRequest.url != '/identity/refresh') {
      await waitUntilRefresh();
      const token = window.localStorage.getItem('jwt');
      if (token) {
        originalRequest.headers!.Authorization = `Bearer ${token}`;
      }
      return axios(originalRequest);
    }
    switch (status) {
      case 400: {
        if (originalRequest.url == '/identity/refresh') {
          return Promise.reject(error);
        }
        if (typeof data === 'string' && data != ErrorMessagesE.DiscountCodeTechnicalBreak) {
          toast.error(data);
        }

        // if(config.method === 'get' && data.errors.hasOwnProperty('id')) {
        //    history.push('/not-found');
        // }
        if (data.errors) {
          const modelStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modelStateErrors.push(data.errors[key]);
            }
          }
          throw modelStateErrors.flat();
        } else if (data) {
          const modelStateErrors = [];
          modelStateErrors.push(data);
          throw modelStateErrors.flat();
        }
        break;
      }
      case 401: {
        isRefreshing = true;
        return token
          .refreshToken()
          .then((response) => {
            originalRequest.headers!.Authorization = `Bearer ${response}`;
            isRefreshing = false;
            preventLogout = true;
            return axios(originalRequest);
          })
          .catch((error) => {
            if (!preventLogout) {
              store.userStore.isLoggedIn = false;
              store.userStore.isLoggedInToInst = false;
              window.localStorage.removeItem('jwt');
              window.localStorage.removeItem('inst');
              window.localStorage.removeItem('refreshToken');
              history.push(PathRoute.LOGIN);
            }
            isRefreshing = false;
            preventLogout = false;
            return Promise.reject(error);
          });
      }

      case 404: {
        // if(config.method === 'get' && data.errors.hasOwnProperty('id')) {
        //    history.push('/not-found');
        // }

        const modelStateErrors = [];
        modelStateErrors.push('Wystąpił błąd');
        throw modelStateErrors.flat();
        history.push('/not-found');
        break;
      }

      case 500: {
        if (typeof data === 'string') {
          toast.error(data);
        }

        if (data.errors) {
          const modelStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modelStateErrors.push(data.errors[key]);
            }
          }
          throw modelStateErrors.flat();
        } else if (data) {
          const modelStateErrors = [];
          modelStateErrors.push(data);
          throw modelStateErrors.flat();
        }
        break;
      }

      default:
        store.userStore.isLoggedIn = false;
        store.userStore.isLoggedInToInst = false;
        window.localStorage.removeItem('jwt');
        window.localStorage.removeItem('inst');
        window.localStorage.removeItem('refreshToken');
        history.push(PathRoute.LOGIN);
        throw Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  getFile: <T>(url: string) =>
    axios
      .get<T>(url, {
        responseType: 'blob',
      })
      .then((response) => response.data),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Account = {
  current: () => requests.get<User>('/account'),
  login: (user: UserFormValues) => requests.post<User>('/identity/login', user),
  register: (values: UserRegisterFormValues) => requests.post<number>('/identity/register', values),
  forgotPassword: (values: ForgotPasswordFormValues) => requests.post<number>('/identity/forgotPassword', values),
  requestTokenToSetNewPassword: (email: string, phone: string, firstLogin: boolean) =>
    requests.post(`Identity/RequestTokenToSetNewPassword`, { email, phone, firstLogin }),
  authorizeToken: (token: string) => requests.post<User>('/Identity/VerifyUser', { token }),
  requestAdminAuthorizatonToken: (institutionId: number, phoneId: number) =>
    requests.post<string>(`AdminPanel/RequestAuthorizeAdminToken`, { institutionId, phoneId }),
  requestInstitutionAdminAuthorizatonToken: (institutionId: number, phoneId: number) =>
    requests.post<string>(`AdminPanel/RequestAuthorizeInstitutionAdminToken`, { institutionId, phoneId }),
  checkInstitutionAdminAuthorization: () =>
    requests.get<boolean>(`AdminPanel/CheckInstitutionAdminAuthorization?guid=${store.commonStore.authGuid}`),
  authorizeInstitutionAdmin: (token: string, institutionId: number, guid: string) =>
    requests.post<string>('AdminPanel/AuthorizeInstitutionAdmin', { token, institutionId, guid }),
  verifyToken: () => requests.get('/Identity/RequestVerifyToken'),
  verifyNewPassword: (params: VerifyNewPasswordFormValues) =>
    requests.post<AuthenticationResult>('/Identity/VerifyAndSetNewPassword', params),
  sendVerificationEmail: () => requests.post('/identity/SendEmailVerificationRequest', {}),
  getCurrentUser: () => requests.get<User>('/identity/CurrentUser'),
  getAppVersion: (system: string) => requests.get<AppVersionM>(`/Identity/GetAppVersion?system=${system}`),
  addUserDevice: (token: string) =>
    requests.post<string>('user/addUserDevice', { token, system: Capacitor.getPlatform() }),
};

const CircleKCard = {
  createCircleKCard: (request: CircleKContractFormValues) => requests.post<number>('/CircleK/CardCreate', request),
  createCircleKCardOperatorChange: (request: CircleKContractFormValues) =>
    requests.post<number>('/CircleK/CardCreateOperatorChange', request),
  generateTokenToChangeFuelCardLimit: (cardId: number, limit: number) =>
    requests.post(`/CircleK/GenerateTokenToChangeFuelCardLimit`, { cardId, limit }),
  circleKBlockCardRequest: (cardId: number, message: string) =>
    requests.post(`/CircleK/RequestForBlockCard`, { cardId, message }),
  circleKUnblockCardRequest: (cardId: number, message: string) =>
    requests.post(`/CircleK/RequestForUnblockCard`, { cardId, message }),
  circleKCancellationCardRequest: (cardId: number, message: string) =>
    requests.post(`/CircleK/RequestForCancellationCard`, { cardId, message }),
  getCircleKAllTransactionByUserId: () => requests.get(`/CircleK/GetAllTransactionByUserId`),
  getCircleKAllTransactionByCardId: (cardId: number) =>
    requests.get(`/CircleK/GetAllTransactionByCardId?cardId=${cardId}`),
  getUserCircleKContractById: (contractId?: number) => {
    if (contractId) {
      return requests.get<CircleKContractFormValues>(
        `/CircleK/GetUserCircleKContractFormById?contractId=${contractId}`,
      );
    } else {
      return requests.get<CircleKContractFormValues>(`/CircleK/GetUserCircleKContractFormById`);
    }
  },
  getUserCircleKContractFormOperatorChange: () => {
    return requests.get<CircleKContractFormValues>(`/CircleK/GetUserCircleKContractFormOperatorChange`);
  },
  generateTokenToCircleKContract: (contractId: number) =>
    requests.post(`/CircleK/GenerateTokenToCircleKContract`, { contractId }),
  getUserCircleKContractVersion: (contractId: number) =>
    requests.get<number | null>(`/CircleK/getUserCircleKContractVersion?contractId=${contractId}`),
  signCircleKContract: (contractId: number, token: string) =>
    requests.post(`/CircleK/SignCircleKContract`, { contractId, token }),
  getCircleKCardsByUserId: () => requests.get<CircleKCardModel[]>(`/CircleK/GetCardsByUserId`),
  getCircleKBalanceByUserId: () => requests.get(`/CircleK/GetUserBalance`),
  getCircleKMyExpensesByUserId: () => requests.get(`/CircleK/GetMyExpensesByUserId`),
  getCircleStationByPostCode: (postcode: string) =>
    customAxios.get(`/v1/circlek/stations_by_postcode?postcode=${postcode}`),
  getCircleStationByCity: (city: string) => customAxios.get(`/v1/circlek/stations_by_city?city=${city}`),
  editUserCircleKContract: (request: CircleKContractFormValues) =>
    requests.put(`/CircleK/EditUserCircleKContract`, request),
  getInstitutionByPostCodeAndPlace: (postCode: string, place: string) =>
    requests.get<MySelectInputOptions[]>(
      `/CircleK/GetCustomerListByPostCodeAndPlace?postCode=${postCode}&place=${place}`,
    ),
  signFuelCardLimitChangeAnnex: (cardId: number, limit: number, token: string) =>
    requests.post(`/CircleK/SignFuelCardLimitChangeAnnex`, { cardId, limit, token }),
};
const CircleKSettlements = {
  getUserSettlements: () => requests.get(`/CircleK/GetUserSettlements`),
};

const Users = {
  registerCustomer: (user: CustomerRegisterFormValues) => requests.post<User>('/user/registerCustomer', user),
  editCustomer: (user: CustomerRegisterFormValues) => requests.post<User>('/user/editCustomer', user),
  institutionUsers: (request: UserInstitutionSearchModel) =>
    requests.get<UserList>(
      `/user/institutionUsers?InstitutionId=${request.InstitutionId}&FirstName=${request.FirstName}&LastName=${
        request.LastName
      }&Email=${request.Email}&BenefitStatus=${request.BenefitStatus ?? ''}&Sorting=${request.Sorting}&Offset=${
        request.Offset
      }&Limit=${request.Limit}&IsFired=${request.IsFired ?? ''}`,
    ),
  customerFormData: (userInstitutionId: number) =>
    requests.get<CustomerRegisterFormValues>(`/user/customerFormData?userInstitutionId=${userInstitutionId}`),
  saveUserApprovals: (approvals: UserApprovals[]) =>
    requests.post<User>('/identity/saveUserApprovals', { userApprovals: approvals }),
  saveUserApprovalsForBenefit: (approvals: UserApprovals[], benefitTypeId: number) =>
    requests.post<User>('/identity/saveUserApprovalsForBenefit', {
      userApprovals: approvals,
      benefitTypeId: benefitTypeId,
    }),
  getUserApprovals: () => requests.get<UserApprovals[]>('/User/getUserApprovals'),
  getUserApprovalsForBenefit: (benefitTypeId: number) =>
    requests.get<UserApprovals[]>(`/User/GetUserApprovalsForBenefit?benefitTypeId=${benefitTypeId}&benefit=true`),
  hasRequiredApprovals: () => requests.get<boolean>('/User/hasRequiredApprovals'),
  hasPesel: () => requests.get<boolean>('/User/CheckPesel'),
  hasUserPersonalData: () => requests.get<boolean>('/User/HasUserPersonalData'),
  updatePesel: (pesel: string) => requests.post<boolean>('/User/UpdatePesel', { pesel }),
  usersImport: (formData: FormData) => requests.post<Array<string>>('AdminPanel/UsersImport', formData),
  usersImportWithLoanBenefit: (formData: FormData) =>
    requests.post<Array<string>>('AdminPanel/UsersImportWithLoanBenefit', formData),
  getUserAvailableBenefits: () => requests.get<UserAvailableBenefits[]>('/User/getUserAvailableBenefit'),
  getUserInstitutions: () => requests.get<UserInstitution[]>('/User/getUserInstitutions'),
  hasRequiredApprovalsForBenefit: (benefitId: number) =>
    requests.get<boolean>(`/User/hasRequiredApprovalsForBenefit?benefitId=${benefitId}`),
  getUserMarketingData: () => requests.get<UserMarketingData>('/User/GetUserMarketingData'),
  registerUseActivity: (activityCode: number) => requests.post('/User/RegisterUserActivity', { activityCode }),
  getUserPesel: () => requests.get<string | null>('/User/GetUserPesel'),
};

const Email = {
  sendContactForm: (message: ContactFormValues) => requests.post('/email/sendContactForm', message),
  sendDeleteAccountForm: (message: DeleteAccountFormValues) => requests.post('/email/sendDeleteAccountForm', message),
};

const Notification = {
  getUserNotifications: () => requests.get<NotifcationModel[]>('/User/GetUserNotifications'),
  deleteUserNotifications: (id: number) => requests.delete(`/User/DeleteUserNotification?id=${id}`),
  ReadUserNotification: (id: number) => requests.put('/User/ReadUserNotification', { id: id }),
};
const token = {
  refreshToken: () => {
    return new Promise<string>((resolve, reject) => {
      if (!store.commonStore.token) {
        store.userStore.logout();
        reject();
        return;
      }
      axios
        .post<UserRefresh>('/identity/refresh', {
          token: store.commonStore.token,
          refreshToken: store.commonStore.refreshToken,
        })
        .then((resp) => {
          if (resp.status >= 200 && resp.status < 300) {
            window.localStorage.setItem('jwt', resp.data.token);
            window.localStorage.setItem('refreshToken', resp.data.refreshToken);
            store.commonStore.setToken(resp.data.token);
            store.commonStore.setRefreshToken(resp.data.refreshToken);
            resolve(resp.data.token);
          }
        })
        .catch(() => {
          store.userStore.logout();
          reject();
        });
    });
  },
};

const UserProfile = {
  getUserProfileNew: () => requests.get<UserProfileNewModel>(`/User/GetUserProfileNew`),
  editUserProfileNew: (userProfileModel: Omit<UserProfileNewModel, 'email'>) =>
    requests.post<User>(`/User/EditUserProfileNew`, userProfileModel),
  getUserProfile: () => requests.get<UserProfileModelResponse>(`/User/GetUserProfile`),
  getUserProfilePhones: () => requests.get<UserPhoneModel[] | null>(`/User/GetUserPhones`),
  editUserProfile: (userProfileModel: UserProfileModel) =>
    requests.put<User>(`/User/EditUserProfile`, userProfileModel),
  editUserProfilePhone: (editUserPhoneModel: EditUserPhoneModel) =>
    requests.put(`/User/EditUserPhone`, editUserPhoneModel),
  setMainUserProfilePhone: (phoneId: number) => requests.put(`/User/EditUserPhoneMainFlage`, { phoneId: phoneId }),
  createUserProfilePhone: (createUserPhoneModel: CreateUserPhoneModel) =>
    requests.post(`/User/CreateUserPhone`, createUserPhoneModel),
  deleteUserProfilePhone: (phoneId: number) => requests.delete(`/User/DeleteUserPhone?phoneId=${phoneId}`),
  requestVerifyUserProfilePhone: (phoneId: number) =>
    requests.post(`/User/RequestUserPhoneVerifyToken`, { phoneId: phoneId }),
  verifyUserProfilePhone: (verifyUserPhoneModev: VerifyUserPhoneModel) =>
    requests.post(`/User/VerifyUserPhone`, verifyUserPhoneModev),
};
const Files = {
  getMyCircleKFiles: () => requests.get<UserFileModel[]>(`/File/GetMyCircleKFiles`),
  getMyDiscountCodeFiles: () => requests.get<UserFileModel[]>(`/File/GetMyDiscountCodeFiles`),
  getMyLoansFiles: () => requests.get<UserLoansFileModel[]>(`/File/GetMyLoansFiles`),
  getMyWalletFiles: () => requests.get<UserLoansFileModel[]>(`/File/GetMyWalletFiles`),
  getMyWalletApplicationFiles: () => requests.get<UserFileModel[]>(`/File/GetMyWalletApplicationFiles`),
  getFile: (id: number) => requests.getFile<Blob>(`/File/GetFile?fileId=` + id),
  getLoansFile: (clientId: string, documentId: string) =>
    requests.getFile<Blob>(`/File/getLoansFile?clientId=${clientId}&documentId=${documentId}`),
  getWalletFile: (clientId: string, documentId: string) =>
    requests.getFile<Blob>(`/File/getWalletFile?clientId=${clientId}&documentId=${documentId}`),
  getCircleKPdf: (id: number) => requests.getFile<Blob>(`/File/GetCircleKContractPdf?contractId=` + id),
  getFuelCardLimitChangeAnnexPdf: (cardId: number, newLimit: number) =>
    requests.getFile<Blob>(`/File/GetFuelCardLimitChangeAnnexPdf?cardId=` + cardId + '&newLimit=' + newLimit),
  getDiscountCodeContractPdf: () => requests.getFile<Blob>(`/File/getDiscountCodeContractPdf`),
  getDiscountCodeOrderPdf: (id: string) => requests.getFile<Blob>(`/File/GetDiscountCodeOrderPdf?orderId=` + id),
  getLoanApplicationFormPdf: (loanContractId: number) =>
    requests.getFile<Blob>(`/File/GetLoanApplicationForm?loanContractId=` + loanContractId),

  getLoanApplicationContractPdf: (loanContractId: number, availableBenefitIsInstitutional: boolean | null) =>
    requests.getFile<Blob>(
      `/File/GetLoanApplicationContract?loanContractId=` +
        loanContractId +
        `&availableBenefitIsInstitutional=` +
        availableBenefitIsInstitutional,
    ),

  getDiscountCodeBarcode: (id: number) => requests.getFile<Blob>(`/File/GetDiscountCodeBarcode?discountCodeId=` + id),
  getBirthdayCodeBarcode: (id: number) => requests.getFile<Blob>(`/File/GetBirthdayCodeBarcode?birthdayCodeId=` + id),
  getWalletApplicationContractPdf: (walletContractId: number) =>
    requests.getFile<Blob>(`/File/GetWalletApplicationContract?walletApplicationId=` + walletContractId),
  getWalletApplicationFormPdf: (walletApplicationId: number) =>
    requests.getFile<Blob>(`/File/GetWalletApplicationForm?walletApplicationId=` + walletApplicationId),
};
const Loans = {
  createLoan: (limit: number) => requests.post<Loan>(`/Loan/CreateLoan`, { limit }),
  getLoggedUserLimit: (availableBenefitId: number) =>
    requests.get<UserLoanLimit>(`/Loan/GetLoggedUserLimit?AvailableBenefitId=${availableBenefitId}`),
  getUserLoansApplications: (availableBenefitId: number) =>
    requests.get(`/Loan/GetLoansApplications?loanUserAvailableBenefitId=${availableBenefitId}`),
  getLoansApplicationsFromCurrentMonth: (availableBenefitId: number) =>
    requests.get(`/Loan/GetLoansApplicationsFromCurrentMonth?loanUserAvailableBenefitId=${availableBenefitId}`),
  getLoanApplicationUserData: (loanApplicationId: number) =>
    requests.get<LoanApplicationUserData>(`/Loan/getLoanApplicationUserData?loanApplicationId=${loanApplicationId}`),
  getLoanContractUserData: (id: number) => requests.get<LoanContractUserData>(`/Loan/GetLoanContractUserData?id=${id}`),
  getLoanContractAddressData: (id: number) =>
    requests.get<LoanContractAddressData>(`/Loan/GetLoanContractAddressData?id=${id}`),
  getLoanContractEmploymentData: (id: number) =>
    requests.get<LoanContractEmploymentData>(`/Loan/GetLoanContractEmploymentData?id=${id}`),
  getLoanContractAdditionalData: (id: number) =>
    requests.get<LoanContractAdditionalData>(`/Loan/GetLoanContractAdditionalData?id=${id}`),
  getUserLoanContractId: (availableBenefitId: number) =>
    requests.get(`/Loan/getUserLoanContractId?availableBenefitId=${availableBenefitId}`),
  hasInstitutionalLoanBenefit: () => requests.get('/Loan/hasInstitutionalLoanBenefit'),
  hasAcceptedContract: (availableBenefitId: number | null) =>
    requests.get(`/Loan/hasAcceptedContract?availableBenefitId=${availableBenefitId}`),
  hasWaitingContract: (availableBenefitId: number | null) =>
    requests.get(`/Loan/hasWaitingContract?availableBenefitId=${availableBenefitId}`),
  getUserLoanContractStatus: (availableBenefitId: number | null) =>
    requests.get<number>(`/Loan/GetLoanContractStatus?availableBenefitId=${availableBenefitId}`),
  checkHasLoanApprovals: () => requests.get('/Loan/hasApprovals'),
  saveLoanContractUserData: (
    id: number | undefined,
    availableBenefitId: number | null,
    firstName: string,
    lastName: string,
    pesel: string,
    phone: string,
    email: string,
    documentNumber: string,
    documentExpirationDate: string,
    bankAccountNumber: string,
  ) =>
    requests.post(`/Loan/SaveLoanContractUserData`, {
      id,
      availableBenefitId,
      firstName,
      lastName,
      pesel,
      phone,
      email,
      documentNumber,
      documentExpirationDate,
      bankAccountNumber,
    }),
  saveLoanContractAddressData: (
    id: number,
    street: string,
    houseNo: string,
    flatNo: string,
    place: string,
    postCode: string,
    correspondenceStreet: string,
    correspondenceHouseNo: string,
    correspondenceFlatNo: string,
    correspondencePlace: string,
    correspondencePostCode: string,
    differentCorrespondece: boolean,
  ) =>
    requests.post(`/Loan/SaveLoanContractAddressData`, {
      id,
      street,
      houseNo,
      flatNo,
      place,
      postCode,
      correspondenceStreet,
      correspondenceHouseNo,
      correspondenceFlatNo,
      correspondencePlace,
      correspondencePostCode,
      differentCorrespondece,
    }),
  saveLoanContractEmploymentData: (
    id: number,
    employmentType: number,
    employmentDate: string,
    contractStartDate: string,
    contractEndDate: string,
    monthlyNetIncome: string,
    companyName: string,
    profession: string,
    isInstitutional: boolean,
  ) =>
    requests.post(`/Loan/SaveLoanContractEmploymentData`, {
      id,
      employmentType,
      employmentDate,
      contractStartDate,
      contractEndDate,
      monthlyNetIncome,
      companyName,
      profession,
      isInstitutional,
    }),
  saveLoanContractAdditionalData: (
    id: number,
    monthlyLoanInstallments: string,
    creditLimitsGranted: string,
    numberOfDependents: string,
    monthlyExpenses: string,
  ) =>
    requests.post(`/Loan/SaveLoanContractAdditionalData`, {
      id,
      monthlyLoanInstallments,
      creditLimitsGranted,
      numberOfDependents,
      monthlyExpenses,
    }),
  createLoanApplication: (availableBenefitId: number, amount: number) =>
    requests.post(`/Loan/CreateLoanApplication`, {
      availableBenefitId,
      amount,
    }),
  verifyLoanApplicationToken: (loanApplicationId: number, token: string) =>
    requests.post(`/Loan/verifyLoanApplicationToken`, {
      loanApplicationId,
      token,
    }),
  requestLoanAuthorizationToken: (loanApplicationId: number) =>
    requests.post(`/Loan/requestLoanAuthorizationToken`, { loanApplicationId }),
  saveLoanApprovals: (
    regulationsAccepted?: boolean,
    privacyPolicyAccepted?: boolean,
    analyticsApproval?: boolean,
    marketingApproval?: boolean,
    eMessageApproval?: boolean,
    phoneContactApproval?: boolean,
    loanEmploymentVerificationApproval?: boolean,
    loanBIGApproval?: boolean,
    loanKRDApproval?: boolean,
    loanDataCorrectnessStatement?: boolean,
    loanPEPStatement?: boolean,
  ) =>
    requests.post(`/Loan/saveLoanApprovals`, {
      regulationsAccepted,
      privacyPolicyAccepted,
      analyticsApproval,
      marketingApproval,
      eMessageApproval,
      phoneContactApproval,
      loanEmploymentVerificationApproval,
      loanBIGApproval,
      loanKRDApproval,
      loanDataCorrectnessStatement,
      loanPEPStatement,
    }),
  sendContractFile: (formData: FormData) => requests.post('Loan/SaveLoanContractFile', formData),
  addLoanBenefit: (userId: number, institutionId: number, salary: number, loanPaymentDateId: number) =>
    requests.post('Loan/AddLoanBenefit', { userId, institutionId, salary, loanPaymentDateId }),
  blockLoanBenefit: (userId: number, institutionId: number, isEmployeeFired: boolean) =>
    requests.post('Loan/BlockLoanBenefit', { userId, institutionId, isEmployeeFired }),
  unlockLoanBenefit: (userId: number, institutionId: number) =>
    requests.post('Loan/UnlockLoanBenefit', { userId, institutionId }),
  changeLoanUserSalary: (userId: number, institutionId: number, salary: number) =>
    requests.post('Loan/EditLoanBenefitLimit', { userId, institutionId, salary }),
  getLoanBenefitManagmentData: (userId: number, benefitId: number, institutionId: number) =>
    requests.get<LoanBenefitManagment>(
      `/Loan/GetLoanBenefitManagmentData?userId=${userId}&benefitId=${benefitId}&institutionId=${institutionId}`,
    ),
  getLoanPaymentDates: (institutionId: number) =>
    requests.get<MySelectInputOptions[]>(`/Loan/getLoanPaymentDates?institutionId=${institutionId}`),
  getUserLoanPaymentDate: (userId: number, institutionId: number) =>
    requests.get<number | null>(`/Loan/getUserLoanPaymentDate?userId=${userId}&institutionId=${institutionId}`),
  checkBenefitIsInstitutionalByContractId: (contractId: number) =>
    requests.get<boolean>(`/Loan/CheckBenefitIsInstitutionalByContractId?contractId=${contractId}`),
  saveUserLoanPaymentDate: (id: number, userId: number, institutionId: number) =>
    requests.post<number | null>('Loan/SaveUserLoanPaymentDate', { id, userId, institutionId }),
  getAuthorizationPhones: (institutionId: number) =>
    requests.get<MySelectInputOptions[]>(
      `/Loan/GetLoanInstitutionalAuthorizationPhones?institutionId=${institutionId}`,
    ),
  addLoanPaymentDate: (institutionId: number, dayOfMonth: number) =>
    requests.post<number>('Loan/AddLoanPaymentDate', { institutionId, dayOfMonth }),
  deleteLoanPaymentDate: (institutionId: number, loanPaymentDateId: number) =>
    requests.delete<number>(
      `/Loan/DeleteLoanPaymentDate?loanPaymentDateId=${loanPaymentDateId}&institutionId=${institutionId}`,
    ),
  getLoanApplicationsForInstitution: (
    institutionId: number,
    loanApplicationMonth?: string,
    firstName?: string,
    lastName?: string,
    pesel?: string,
    sorting?: string,
    offset?: number,
    limit?: number,
  ) =>
    requests.get<TableResultM<MonthlyLoanApplicationForInstitutionM>>(
      `/Loan/getLoanApplicationsForInstitution?institutionId=${institutionId}&loanApplicationMonth=${loanApplicationMonth}&firstName=${firstName}&lastName=${lastName}&pesel=${pesel}&sorting=${sorting}&offset=${offset}&limit=${limit}`,
    ),
  getLoanApplicationsSumForInstitution: (institutionId: number, loanApplicationMonth?: string) =>
    requests.get<string>(
      `/Loan/getLoanApplicationsSumForInstitution?institutionId=${institutionId}&loanApplicationMonth=${loanApplicationMonth}`,
    ),
  getLoanApplicationsMonthSelect: (institutionId: number) =>
    requests.get<MySelectInputOptions[]>(`/Loan/getLoanApplicationsMonthSelect?institutionId=${institutionId}`),
  getUserLoanApplicationsPerMonthForAdmin: (institutionId: number, userId: number, month: string) =>
    requests.get<LoanDataCashmir[]>(
      `/Loan/getUserLoanApplicationsPerMonthForAdmin?institutionId=${institutionId}&userId=${userId}&month=${month}`,
    ),
  downloadLoanApplicationsForInstitution: (
    institutionId: number,
    loanApplicationMonth?: string,
    firstName?: string,
    lastName?: string,
    pesel?: string,
    sorting?: string,
    offset?: number,
    limit?: number,
  ) =>
    requests.getFile<Blob>(
      `/Loan/downloadLoanApplicationsForInstitution?institutionId=${institutionId}&loanApplicationMonth=${loanApplicationMonth}&firstName=${firstName}&lastName=${lastName}&pesel=${pesel}&sorting=${sorting}&offset=${offset}&limit=${limit}`,
    ),
};
const DiscountCodes = {
  getDiscountCodeContractUserData: () =>
    requests.get<DiscountCodeContractData>(`/DiscountCode/GetDiscountCodeContractUserData`),
  getDiscountCodeContractAddressData: () =>
    requests.get<DiscountCodeContractAddressData>(`/DiscountCode/GetDiscountCodeContractAddressData`),
  getUserDiscountCodeContractId: () => requests.get(`/DiscountCode/getUserDiscountCodeContractId`),
  hasInstitutionalDiscountCodeBenefit: () => requests.get('/DiscountCode/hasInstitutionalDiscountCodeBenefit'),
  hasAcceptedContract: (availableBenefitId: number | null) =>
    requests.get(`/DiscountCode/hasAcceptedContract?availableBenefitId=${availableBenefitId}`),
  hasWaitingContract: (availableBenefitId: number | null) =>
    requests.get(`/DiscountCode/hasWaitingContract?availableBenefitId=${availableBenefitId}`),
  getUserDiscountCodeContractStatus: () =>
    requests.get<number>(getUrls.DiscountCodes.getUserDiscountCodeContractStatus),
  saveDiscountCodeContractUserData: (discountCodeContractData: DiscountCodeContractData) =>
    requests.post(`/DiscountCode/SaveDiscountCodeContractUserData`, discountCodeContractData),
  saveDiscountCodeContractAddressData: (
    street: string,
    houseNo: string,
    flatNo: string,
    place: string,
    postCode: string,
    correspondenceStreet: string,
    correspondenceHouseNo: string,
    correspondenceFlatNo: string,
    correspondencePlace: string,
    correspondencePostCode: string,
    differentCorrespondece: boolean,
  ) =>
    requests.post(`/DiscountCode/SaveDiscountCodeContractAddressData`, {
      street,
      houseNo,
      flatNo,
      place,
      postCode,
      correspondenceStreet,
      correspondenceHouseNo,
      correspondenceFlatNo,
      correspondencePlace,
      correspondencePostCode,
      differentCorrespondece,
    }),
  sendContractFile: (formData: FormData) => requests.post('/DiscountCode/SaveDiscountCodeContractFile', formData),
  generateTokenToDiscountCodeContract: () => requests.get(`/DiscountCode/GenerateTokenToDiscountCodeContract`),
  signDiscountCodeContract: (token: string) => requests.post(`/DiscountCode/SignDiscountCodeContract`, { token }),
  getAvailableDiscountCodeTypes: () =>
    requests.get<DiscountCodeCampaignM[]>(`/DiscountCode/GetAvailableDiscountCodeTypes`),
  orderDiscountCodes: (request: DiscountCodeCampaignM[]) =>
    requests.post<DiscountCodeCampaignM[] | string>(`/DiscountCode/OrderDiscountCodes`, { campaigns: request }),
  getDiscountCodeOrderData: (id: string) =>
    requests.get<DiscountCodeOrderDataM>(`/DiscountCode/GetDiscountCodeOrderData?orderid=${id}`),
  getUserDiscountCodeOrders: () => requests.get<DiscountCodeOrderDataM[]>(`/DiscountCode/GetUserDiscountCodeOrders`),
  requestDiscountCodeOrderAuthorizationToken: (id: string) =>
    requests.get<string>(`/DiscountCode/RequestDiscountCodeOrderAuthorizationToken?orderid=${id}`),
  verifyDiscountCodeOrderToken: (id: string, token: string) =>
    requests.post<string>(`/DiscountCode/VerifyDiscountCodeOrderToken`, { orderId: id, token: token }),
  getUserDiscountCodes: () => requests.get<DiscountCodeM[]>(`/DiscountCode/GetUserDiscountCodes`),
  getInstitutionalDiscountCodesByUser: () =>
    requests.get<DiscountCodeM[]>(`/DiscountCode/GetInstitutionalDiscountCodesByUser`),
  checkHasInstitutionalDiscountCodesByUser: () =>
    requests.get<boolean>(`/DiscountCode/CheckHasInstitutionalDiscountCodesByUser`),
  changeDiscountCodeUsed: (id: number, used: boolean) =>
    requests.post<string>(`/DiscountCode/ChangeDiscountCodeUsed`, { id: id, used: used }),
};
const BirthdayCodes = {
  getUserBirthdayCodeCampaigns: () =>
    requests.get<BirthdayCodeCampaignM[]>(`/BirthdayCode/GetUserBirthdayCodeCampaigns`),
  orderBirthdayCode: (birthdayCodeCampaignOrder: BirthdayCodeCampaignOrderM) =>
    requests.post<string>(`/BirthdayCode/OrderBirthdayCode`, birthdayCodeCampaignOrder),
  getUserBirthdayCodes: () => requests.get<DiscountCodeM[]>(`/BirthdayCode/GetUserBirthdayCodes`),
  changeBirthdayCodeUsed: (id: number, used: boolean) =>
    requests.post<string>(`/BirthdayCode/ChangeBirthdayCodeUsed`, { id: id, used: used }),
  sendBirthdayCodeLead: (discountCodeLead: DiscountCodeLeadM) =>
    requests.post<string>(`/BirthdayCode/SendBirthdayCodeLead`, discountCodeLead),
};
const Article = {
  getArticle: () => articleAxios.get<ArticleModel[]>('/v1/articles/articles'),
};
const WalletNau = {
  createWalletApplication: (amount: number) => requests.post(`/Wallet/CreateWalletApplication`, { amount }),
  getLoggedUserLimit: () => requests.get<UserWalletLimit>(`/Wallet/GetLoggedUserLimit`),
  getWalletApplicationUserData: (walletApplicationId: number) =>
    requests.get<WalletApplicationUserData>(
      `/Wallet/GetWalletApplicationUserData?walletApplicationId=${walletApplicationId}`,
    ),
  getWalletApplicationsFromCurrentMonth: () => requests.get(`/Wallet/GetWalletApplicationsFromCurrentMonth`),
  getWalletContractStatus: () => requests.get<number>(`/Wallet/GetWalletContractStatus`),
  getWalletContractUserData: () => requests.get<WalletNauContractData>(`/Wallet/GetWalletContractUserData`),
  requestWalletAuthorizationToken: (walletApplicationId: number) =>
    requests.post(`/Wallet/RequestWalletAuthorizationToken`, { walletApplicationId }),
  saveWalletContractFile: (formData: FormData) => requests.post('/Wallet/SaveWalletContractFile', formData),
  saveWalletNauContractUserData: (walletNauContractData: WalletNauContractData) =>
    requests.post(`/Wallet/SaveWalletContractUserData`, walletNauContractData),
  verifyWalletApplicationToken: (walletApplicationId: number, token: string) =>
    requests.post(`/Wallet/verifyWalletApplicationToken`, {
      walletApplicationId,
      token,
    }),
};

const Payments = {
  getUserPayments: () => requests.get<UserPayment[]>(`/Payment/GetUserPayments`),
  checkOrderCategory: (orderId: number) => requests.get<number>(`/Payment/CheckOrderCategory?orderId=${orderId}`),
};

const Cashback = {
  getCashbackPoliciesByCashbackHash: (regon: string) =>
    requests.get<ICashbackSopPolicyGroupDto[]>(`/Cashback/GetCashbackPoliciesByCashbackHash?cashbackHash=${regon}`),
  activateCashbackBenefit: () => requests.post<string>(`/Cashback/ActivateCashbackBenefit`, {}),
  getCustomersWithCashbackAvailableCities: (zipCode: string, place: string) =>
    requests.get<string[]>(`/Cashback/GetCustomersWithCashbackAvailableCities?place=${place}&zipCode=${zipCode}`),
  getCashbackCustomersAvailableAddresses: (zipCode: string, place: string) =>
    requests.get<CustomerWithCashbackDataDTO[]>(
      `/Cashback/GetCashbackCustomersAvailableAddresses?place=${place}&zipCode=${zipCode}`,
    ),
  assignCashbackToUser: (values: ICashbackSopPolicyGroupDto) =>
    requests.post<string>(`/Cashback/AssignCashbackToUser`, values),
};

const CinemaVouchers = {
  getAvailableCinemaVoucherTypes: () =>
    requests.get<CinemaVoucherCompaniesM[]>(`/CinemaVoucher/GetAvailableCinemaVoucherTypes`),
  addCinemaVouchersToOrder: (order: AddCinemaVoucherToOrderM) =>
    requests.post<number>(`/CinemaVoucher/AddCinemaVouchersToOrder`, order),
  orderCinemaVouchers: (request: number) =>
    requests.post<string>(`/CinemaVoucher/OrderCinemaVouchers`, { orderId: request }),
  orderDirectorPromoCinemaVouchers: (request: number) =>
    requests.post<string>(`/CinemaVoucher/OrderDirectorPromoCinemaVouchers`, { orderId: request }),
  orderTeachersDayDirectorPromoCinemaVouchers: (request: number) =>
    requests.post<string>(`/CinemaVoucher/OrderTeachersDayDirectorPromoCinemaVouchers`, { orderId: request }),
  getCinemaVoucherOrderData: (id: string) =>
    requests.get<CinemaVoucherOrderDataM>(`/CinemaVoucher/GetCinemaVoucherOrderData?orderid=${id}`),
  getUserCinemaVoucherOrders: () =>
    requests.get<CinemaVoucherOrderDataM[]>(`/CinemaVoucher/GetUserCinemaVoucherOrders`),
  requestCinemaVoucherOrderAuthorizationToken: (id: string) =>
    requests.get<string>(`/CinemaVoucher/RequestCinemaVoucherOrderAuthorizationToken?orderid=${id}`),
  verifyCinemaVoucherOrder: (id: string) =>
    requests.post<string>(`/CinemaVoucher/VerifyCinemaVoucherOrder`, { orderId: id }),
  getUserCinemaVouchers: () => requests.get<CinemaVoucherM[]>(`/CinemaVoucher/GetUserCinemaVouchers`),
  getInstitutionalCinemaVouchersByUser: () =>
    requests.get<CinemaVoucherM[]>(`/CinemaVoucher/GetInstitutionalCinemaVouchersByUser`),
  checkCinemaVoucherIsAvailableForUser: () =>
    requests.get<CinemaVoucherIsAvailableM>(`/CinemaVoucher/CheckCinemaVoucherIsAvailableForUser`),
  validateDirectorCinemaPromoWithinInstitution: (
    firstName: string,
    lastName: string,
    regon: string,
    promoId?: number,
  ) =>
    requests.get<IDirectorCinemaPromoWithinInstitutionValidationResult>(
      `/CinemaVoucher/ValidateDirectorCinemaPromoWithinInstitution?firstName=${firstName}&lastName=${lastName}&regon=${regon}&promoId=${promoId}`,
    ),
  changeCinemaVoucherUsed: (id: number, used: boolean) =>
    requests.post<string>(`/CinemaVoucher/ChangeCinemaVoucherUsed`, { id: id, used: used }),
  rateCinemaVouchersOrder: (rateCinemaVouchersOrderM: RateCinemaVouchersOrderM) =>
    requests.post<string>(`/CinemaVoucher/RateCinemaVouchersOrder`, rateCinemaVouchersOrderM),
  saveCinemaVouchersOrderClientData: (cinemaVoucherOrderClientDataM: CinemaVoucherOrderClientDataM) =>
    requests.post<string>(`/CinemaVoucher/SaveCinemaVouchersOrderClientData`, cinemaVoucherOrderClientDataM),
  getUserCinemaVoucherPersonalData: () =>
    requests.get<CinemaVoucherOrderClientDataM>(`/CinemaVoucher/GetUserCinemaVoucherPersonalData`),
  checkUserHasCinemaVoucherPersonalData: () =>
    requests.get<boolean>(`/CinemaVoucher/CheckUserHasCinemaVoucherPersonalData`),
  downloadVoucherFile: (id: number) => requests.getFile<Blob>(`/CinemaVoucher/DownloadVoucherFile?id=${id}`),
  addCinemaVoucherAvailabilityNotification: (cinemaCompanyId: number) =>
    axios.post(`/CinemaVoucher/AddCinemaVoucherAvailabilityNotification`, { cinemaCompanyId }),
  checkCinemaVoucherOrderStatus: (id: number) =>
    requests.get<boolean>(`/CinemaVoucher/checkCinemaVoucherOrderStatus?paymentOrderId=${id}`),
};

const Lead = {
  sendCallBookLead: (values: CallBookLeadFormValues) => requests.post<string>(`/Lead/SendCallBookLead`, values),
  uploadFile: (formData: FormData) => requests.post<number>('/Lead/UploadLeadFile', formData),
  sendPropertyInsuranceLead: (values: PropertyInsuranceFormValues) =>
    requests.post<string>(`/Lead/SendPropertyInsuranceLead`, values),
  sendPropertyInsuranceExternalLead: (values: PropertyInsuranceFormValues) =>
    requests.post<string>(`/Lead/SendPropertyInsuranceLeadExternal`, values),
  sendCarInsuranceLead: (values: CarInsuranceFormValues) => requests.post<string>(`/Lead/SendCarInsuranceLead`, values),
  sendTripInsuranceLead: (values: TripInsuranceFormValues) =>
    requests.post<string>(`/Lead/SendTripInsuranceLead`, values),
};

const Kl = {
  getInsuredPersonData: (
    pesel: string,
    startDate: string,
    endDate: string,
    country: string,
    email: string,
    mainInsuredId: number | null,
  ) =>
    requests.get<KlInsuredPersonBRM>(
      `/kl/getInsuredPersonData?pesel=${pesel}&startDate=${startDate}&endDate=${endDate}&country=${country}&email=${email}&mainInsuredId=${mainInsuredId ?? ''}`,
    ),
  checkInsuredPersonByPesel: (pesel: string, email: string, sendEmail: boolean) =>
    requests.get<CheckInsuredPersonByPeselResponseDTO>(
      `/kl/checkInsuredPersonByPesel?pesel=${pesel}&email=${email}&sendEmail=${sendEmail}`,
    ),
  saveOrder: (order: KlOrderFormM) => requests.post<KlSaveOrderResponse>(`/kl/saveOrder`, order),
};

const Institution = {
  getInstitutionsByAddressFromSop: (zipCode: string, place: string) =>
    requests.get<InstitutionWithAddressFromSopDTO[]>(
      `/Institution/GetInstitutionsByAddressFromSop?zipCode=${zipCode}&place=${place}`,
    ),
  addDirectorToInstitution: (body: ICinemaVoucherDirectorFormValues) =>
    requests.post<string>(`/Institution/AddDirectorToInstitution`, body),
  getInstitutionFromBir: (regon: string) =>
    requests.get<InstitutionFromBirFromSopDto>(`/Institution/GetInstitutionFromBir?regon=${regon}`),
  addInstitutionToSop: (body: InstitutionSopCreateModel) =>
    requests.post<number>(`/Institution/AddInstitutionToSop`, body),
};

const Address = {
  getCities: (params: IGetCitiesFormValues) =>
    requests.get<string[]>(`/Address/GetCities?` + extractQueryParams(params)),
  getStreets: (params: IGetStreetsFormValues) =>
    requests.get<string[]>(`/Address/GetStreets?` + extractQueryParams(params)),
};

const SportCard = {
  createSportCard: (values: ISportCardApplicationFormValues) =>
    requests.post<string>(`/SportCard/CreateSportCard`, values),
  createSportCardRequest: (values: IRequestForSportCardFormValues) =>
    requests.post<string>(`/SportCard/CreateSportCardRequest`, values),
  deactivateCard: (id: number) => requests.post<string>(`/SportCard/DeactivateCard`, { id: id }),
  getSportCardsForUser: (sportCardCompany: SportCardCompany) =>
    requests.get<ISportCardDto[]>(`${getUrls.SportCards.getSportCardsForUser}?sportCardCompany=${sportCardCompany}`),
  checkSportCardIsAvailableInInstitution: (sportCardCompany: SportCardCompany, sopInstitutionId: number) =>
    requests.get<boolean>(
      `${getUrls.SportCards.checkSportCardIsAvailableInInstitution}?sportCardCompany=${sportCardCompany}&sopInstitutionId=${sopInstitutionId}`,
    ),
};

const InstitutionAdmin = {
  getFundDetails: (institutionId: number) =>
    requests.get<any>(`/InstitutionAdmin/getFundDetails?institutionId=${institutionId}`),
  getEmployees: (institutionId: number) =>
    requests.get<any>(`/InstitutionAdmin/getEmployees?institutionId=${institutionId}`),
  getSportCards: (institutionId: number) =>
    requests.get<any>(`/InstitutionAdmin/getSportCards?institutionId=${institutionId}`),
  getLoans: (institutionId: number) => requests.get<any>(`/InstitutionAdmin/getLoans?institutionId=${institutionId}`),
};

const NauMobile = {
  getLeadFormModel: () => requests.get<PhoneSubscriptionLead>(`/NauMobile/GetLeadFormModel`),
  updateLeadFormModel: (values: PhoneSubscriptionLead) =>
    requests.put<string>(`/NauMobile/UpdateLeadFormModel`, values),
  checkIfIsDirector: (values: IPhoneSubscriptionPromotionValidationFormValues) =>
    requests.post<boolean>('/NauMobile/CheckIfIsDirector', values),
  interestedInAnotherPhone: (values: IInterestedInAnotherPhoneFormValues) =>
    requests.post<string>('/NauMobile/InterestedInAnotherPhone', values),
  getAvailableProviderContracts: (providerTypeId: number) =>
    requests.get<ContractModel[]>(`/NauMobile/GetAvailableProviderContractsModel?providerTypeId=${providerTypeId}`),
};

const agent = {
  Account,
  Article,
  BirthdayCodes,
  CircleKCard,
  CircleKSettlements,
  CinemaVouchers,
  DiscountCodes,
  Email,
  Files,
  Institution,
  Loans,
  Notification,
  Payments,
  UserProfile,
  Users,
  WalletNau,
  token,
  Cashback,
  Lead,
  Kl,
  SportCard,
  Address,
  NauMobile,
  InstitutionAdmin,
};

export default agent;
