import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import {
  ContractPhoneSubscriptionDurationOptions,
  CurrentPhoneSubscriptionContractTypeOptions,
  PhoneSubscriptionLead,
  PhoneSubscriptionLeadType,
  StepType,
} from '../../../types/phoneSubscription';
import { usePhoneSubscriptionLeadStore } from '../../../store/PhoneSubscription/phoneSubscriptionStore';
import agent, { requests } from '../../../api/agent';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import MyCheckbox from '../../../components/FormControls/MyCheckbox/MyCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import StepIndicator from '../../../components/Steps/StepIndicator';
import * as Yup from 'yup';
import MyDateInput from '../../../components/FormControls/MyDateInput/MyDateInput';
import MyTextMaskedInput from '../../../components/FormControls/MyTextMaskedInput/MyTextMaskedInput';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { toast } from 'react-toastify';
import MySelectInput from '../../../components/FormControls/MySelectInput/MySelectInput';
import FileInput from '../FileInput';

interface IInstallmentsFormOneProps {
  afterSave?: () => void;
}

const validationSchema = Yup.object().shape({
  identityDocumentNumber: Yup.string().nullable().required('Pole jest wymagane'),
  identityDocumentExpiryDate: Yup.date()
    .nullable()
    .required('Pole jest wymagane')
    .min(new Date(new Date().setDate(new Date().getDate() - 1)), 'Nieprawidłowa data ważności dokumentu'),
  maritalStatus: Yup.string().nullable().required('Pole jest wymagane'),
  education: Yup.string().nullable().required('Pole jest wymagane'),
  bankAccountNumber: Yup.string().nullable().required('Pole jest wymagane'),
  income: Yup.string().nullable().required('Pole jest wymagane'),
  loanRepaymentsSum: Yup.string().nullable().required('Pole jest wymagane'),
  dependentsCount: Yup.string().nullable().required('Pole jest wymagane'),
  monthlyHouseholdExpenses: Yup.string().nullable().required('Pole jest wymagane'),
  numberToTransfer: Yup.string().when('applicationTypeId', {
    is: PhoneSubscriptionLeadType.TransferNumber,
    then: Yup.string()
      .nullable()
      .required('Należy podać numer do przeniesienia')
      .matches(/^\d{9}$/, 'Pole "Numer do przeniesienia" musi zawierać dokładnie 9 cyfr.'),

    otherwise: Yup.string().nullable(),
  }),
  currentOperator: Yup.string().when('applicationTypeId', {
    is: PhoneSubscriptionLeadType.TransferNumber,
    then: Yup.string().nullable().required('Należy podać nazwę operatora'),
    otherwise: Yup.string().nullable(),
  }),
  currentOperatorContractType: Yup.number().when('applicationTypeId', {
    is: PhoneSubscriptionLeadType.TransferNumber,
    then: Yup.number()
      .nullable()
      .required('Należy wskazać wartość aktualnej formy umowy')
      .min(1, 'Należy wskazać wartość aktualnej formy umowy'),
    otherwise: Yup.number().nullable(),
  }),
  currentOperatorContractDuration: Yup.number().when('applicationTypeId', {
    is: PhoneSubscriptionLeadType.TransferNumber,
    then: Yup.number()
      .nullable()
      .required('Należy wskazać wartość czasu trwania aktualnej umowy')
      .min(1, 'Należy wskazać wartość czasu trwania aktualnej umowy'),
    otherwise: Yup.number().nullable(),
  }),
  identityDocumentScanFileId: Yup.number().when('applicationTypeId', {
    is: PhoneSubscriptionLeadType.TransferNumber,
    then: Yup.number().nullable().required('Należy wgrać plik').min(1, 'Należy wgrać plik'),
    otherwise: Yup.number().nullable(),
  }),
});

export const InstallmentsFormTwo = (props: IInstallmentsFormOneProps) => {
  const { lead, setStep, updateLead } = usePhoneSubscriptionLeadStore();
  const transferForm = (applicationTypeId: number) => {
    if (applicationTypeId === PhoneSubscriptionLeadType.BuyNewNumber) {
      return <></>;
    }
    return (
      <>
        <div className='tw-mb-4 tw-text-lg tw-font-semibold tw-text-nau-sea-green'>
          <div className='tw-mb-4 tw-text-lg tw-font-semibold tw-text-nau-sea-green'>
            Uzupełnij dane do przeniesienia numeru
          </div>
        </div>
        <MyTextInput
          className={'tw-mb-1 tw-rounded-3xl'}
          placeholder='Numer do przeniesienia'
          name='numberToTransfer'
          type='text'
          value={lead.numberToTransfer}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            updateLead({ numberToTransfer: event.target.value })
          }
        />
        <MyTextInput
          className={'tw-mb-1 tw-rounded-3xl'}
          placeholder='Aktualny operator'
          name='currentOperator'
          type='text'
          value={lead.currentOperator}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateLead({ currentOperator: event.target.value })}
        />
        <div className='tw-mb-4 tw-mt-4 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
          Aktualna forma umowy:
        </div>
        <MySelectInput
          className={'tw-rounded-3xl'}
          name='currentOperatorContractType'
          onChange={(e: number) => updateLead({ currentOperatorContractType: e })}
          value={lead.currentOperatorContractType}
          options={CurrentPhoneSubscriptionContractTypeOptions}
        />
        <div className='tw-mb-4 tw-mt-4 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
          Czas trwania obecnej Umowy:
        </div>
        <MySelectInput
          className={'tw-rounded-3xl'}
          placeholder='Typ umowy'
          name='currentOperatorContractDuration'
          value={lead.currentOperatorContractDuration}
          options={ContractPhoneSubscriptionDurationOptions}
          onChange={(e: number) => updateLead({ currentOperatorContractDuration: e })}
        />
        <div className='tw-mb-4 tw-mt-4 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>Załącz:</div>
        <FileInput
          label='Skan dokumentu tożsamości'
          onFileChange={async (file: File | null) => {
            if (!file) return;

            const formData = new FormData();
            formData.append('file', file);

            return requests.post<number>('/NauMobile/SaveNauMobileLeadFile', formData).then((response) => {
              toast.success('Plik został zapisany w formularzu');
              updateLead({ identityDocumentScanFileId: response });
            });
          }}
        />
      </>
    );
  };
  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-px-2 tw-py-6 tw-shadow-md sm:tw-px-6'>
      <StepIndicator currentStep={2} totalSteps={3} />
      <div className={'tw-my-4 tw-w-fit tw-cursor-pointer'} onClick={() => setStep(StepType.LoanYourOfferOne)}>
        <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={lead}
        onSubmit={async (values, { setSubmitting }: FormikHelpers<PhoneSubscriptionLead>) => {
          return agent.NauMobile.updateLeadFormModel({
            ...values,
            currentStepId: StepType.LoanYourOfferTwo,
          })
            .then(() => {
              props.afterSave?.();
            })
            .finally(() => setSubmitting(false));
        }}
        validationSchema={validationSchema}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className='tw-w-full '>
            <div className='tw-mb-4  tw-text-lg tw-font-semibold tw-text-nau-sea-green'>Dane osobowe</div>
            <div className='tw-mb-6 tw-flex tw-flex-col tw-gap-4 '>
              <MyTextInput
                name='identityDocumentNumber'
                placeholder='Seria i nr dowodu osobistego'
                value={lead.identityDocumentNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateLead({ identityDocumentNumber: event.target.value })
                }
              />
              <MyDateInput
                placeholder='Data ważności dokumentu'
                name='identityDocumentExpiryDate'
                value={lead.identityDocumentExpiryDate}
                onChange={(date) => updateLead({ identityDocumentExpiryDate: date?.toDateString() })}
              />
              <MyTextInput
                name='maritalStatus'
                placeholder='Stan cywilny'
                value={lead.maritalStatus}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateLead({ maritalStatus: event.target.value })
                }
              />
              <MyTextInput
                name='education'
                placeholder='Wykształcenie'
                value={lead.education}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateLead({ education: event.target.value })}
              />
              <MyTextMaskedInput
                placeholder='Nr rachunku bankowego'
                mask={MaskHelpers.bankAccountNumber}
                hiddenLabel={true}
                name={`bankAccountNumber`}
                value={lead.bankAccountNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateLead({ bankAccountNumber: event.target.value })
                }
              />
            </div>
            <div className='tw-mb-4 tw-text-lg tw-font-semibold tw-text-nau-sea-green'>Dane finansowe</div>
            <div className='tw-flex tw-flex-col tw-gap-4'>
              <MyTextInput
                name='income'
                placeholder='Miesięczny dochód netto'
                value={lead.income}
                type='number'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateLead({ income: parseFloat(event.target.value) })
                }
              />
              <MyTextInput
                name='loanRepaymentsSum'
                placeholder='Suma miesięcznych rat kredytowych'
                value={lead.loanRepaymentsSum}
                type='number'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateLead({ loanRepaymentsSum: parseFloat(event.target.value) })
                }
              />
              <MyTextInput
                name='dependentsCount'
                placeholder='Liczba osób na utrzymaniu'
                value={lead.dependentsCount}
                type='number'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateLead({ dependentsCount: parseInt(event.target.value) })
                }
              />
              <MyTextInput
                name='monthlyHouseholdExpenses'
                placeholder='Miesięczne wydatki gospodarstwa domowego'
                value={lead.monthlyHouseholdExpenses}
                type='number'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateLead({ monthlyHouseholdExpenses: parseFloat(event.target.value) })
                }
              />
            </div>
            <div className='tw-w-full'>{lead.applicationTypeId && transferForm(lead.applicationTypeId)}</div>

            <div className='tw-mt-8 '>
              <MyButton
                className='tw-w-full'
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type='submit'
                variant={'greenDark'}>
                Dalej
              </MyButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
