import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/store';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import ApprovalsForm from '../../components/ApprovalsForm/ApprovalsForm';

export default observer(() => {
  const { userStore } = useStore();
  return (
    <div>
      {userStore.isLoggedInstSuperAdmin ? (
        <AdminDashboard>
          <div className='col-md-6 offset-md-3'>
            <ApprovalsForm />
          </div>
        </AdminDashboard>
      ) : (
        <CustomerDashboard>
          <div className='col-md-6 offset-md-3'>
            <div className={'tw-rounded-lg tw-bg-white tw-p-2'}>
              <ApprovalsForm />
            </div>
          </div>
        </CustomerDashboard>
      )}
    </div>
  );
});

// <Form className="ui form error" clasa error jest wymagana
