import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import {
  ContractPhoneSubscriptionDurationOptions,
  CurrentPhoneSubscriptionContractTypeOptions,
  PhoneSubscriptionLead,
  PhoneSubscriptionLeadType,
  StepType,
} from '../../../types/phoneSubscription';
import { usePhoneSubscriptionLeadStore } from '../../../store/PhoneSubscription/phoneSubscriptionStore';
import agent, { requests } from '../../../api/agent';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import * as Yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import MyCheckbox from '../../../components/FormControls/MyCheckbox/MyCheckbox';
import StepIndicator from '../../../components/Steps/StepIndicator';
import MyDateInput from '../../../components/FormControls/MyDateInput/MyDateInput';
import { toast } from 'react-toastify';
import MySelectInput from '../../../components/FormControls/MySelectInput/MySelectInput';
import FileInput from '../FileInput';

const validationSchema = Yup.object().shape({
  identityDocumentType: Yup.string().nullable().required('Pole jest wymagane'),
  identityDocumentNumber: Yup.string().nullable().required('Pole jest wymagane'),
  identityDocumentExpiryDate: Yup.string().nullable().required('Pole jest wymagane'),
  residentialAddress: Yup.string().nullable().required('Pole jest wymagane'),
  residentialPostalCode: Yup.string().nullable().required('Pole jest wymagane'),
  residentialCity: Yup.string().nullable().required('Pole jest wymagane'),
  correspondenceAddress: Yup.string().when('sameAsResidential', {
    is: false,
    then: Yup.string().nullable().required('Pole jest wymagane'),
    otherwise: Yup.string().nullable(),
  }),
  correspondencePostalCode: Yup.string().when('sameAsResidential', {
    is: false,
    then: Yup.string().nullable().required('Pole jest wymagane'),
    otherwise: Yup.string().nullable(),
  }),
  correspondenceCity: Yup.string().when('sameAsResidential', {
    is: false,
    then: Yup.string().nullable().required('Pole jest wymagane'),
    otherwise: Yup.string().nullable(),
  }),
  contactPhoneNumber: Yup.string()
    .nullable()
    .matches(/^\d{9}$/, 'Pole "Telefon na umowie" musi zawierać dokładnie 9 cyfr.'),
  contactEmail: Yup.string().nullable().email('Pole "Email na umowie" musi być poprawnym adresem email.'),
  numberToTransfer: Yup.string().when('applicationTypeId', {
    is: PhoneSubscriptionLeadType.TransferNumber,
    then: Yup.string()
      .nullable()
      .required('Należy podać numer do przeniesienia')
      .matches(/^\d{9}$/, 'Pole "Numer do przeniesienia" musi zawierać dokładnie 9 cyfr.'),

    otherwise: Yup.string().nullable(),
  }),
  currentOperator: Yup.string().when('applicationTypeId', {
    is: PhoneSubscriptionLeadType.TransferNumber,
    then: Yup.string().nullable().required('Należy podać nazwę operatora'),
    otherwise: Yup.string().nullable(),
  }),
  currentOperatorContractType: Yup.number().when('applicationTypeId', {
    is: PhoneSubscriptionLeadType.TransferNumber,
    then: Yup.number()
      .nullable()
      .required('Należy wskazać wartość aktualnej formy umowy')
      .min(1, 'Należy wskazać wartość aktualnej formy umowy'),
    otherwise: Yup.number().nullable(),
  }),
  currentOperatorContractDuration: Yup.number().when('applicationTypeId', {
    is: PhoneSubscriptionLeadType.TransferNumber,
    then: Yup.number()
      .nullable()
      .required('Należy wskazać wartość czasu trwania aktualnej umowy')
      .min(1, 'Należy wskazać wartość czasu trwania aktualnej umowy'),
    otherwise: Yup.number().nullable(),
  }),
  identityDocumentScanFileId: Yup.number().when('applicationTypeId', {
    is: PhoneSubscriptionLeadType.TransferNumber,
    then: Yup.number().nullable().required('Należy wgrać plik').min(1, 'Należy wgrać plik'),
    otherwise: Yup.number().nullable(),
  }),
});

interface IInstallmentsFormOneProps {
  afterSave?: () => void;
}

export const CashFormTwo = (props: IInstallmentsFormOneProps) => {
  const { lead, setStep, updateLead } = usePhoneSubscriptionLeadStore();
  const transferForm = (applicationTypeId: number) => {
    if (applicationTypeId === PhoneSubscriptionLeadType.BuyNewNumber) {
      return <></>;
    }
    return (
      <>
        <div className='tw-mb-4 tw-text-lg tw-font-semibold tw-text-nau-sea-green'>
          <div className='tw-mb-4 tw-text-lg tw-font-semibold tw-text-nau-sea-green'>
            Uzupełnij dane do przeniesienia numeru
          </div>
        </div>
        <MyTextInput
          className={'tw-mb-1 tw-rounded-3xl'}
          placeholder='Numer do przeniesienia'
          name='numberToTransfer'
          type='text'
          value={lead.numberToTransfer}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            updateLead({ numberToTransfer: event.target.value })
          }
        />
        <MyTextInput
          className={'tw-mb-1 tw-rounded-3xl'}
          placeholder='Aktualny operator'
          name='currentOperator'
          type='text'
          value={lead.currentOperator}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateLead({ currentOperator: event.target.value })}
        />
        <div className='tw-mb-4 tw-mt-4 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
          Aktualna forma umowy:
        </div>
        <MySelectInput
          className={'tw-rounded-3xl'}
          name='currentOperatorContractType'
          onChange={(e: number) => updateLead({ currentOperatorContractType: e })}
          value={lead.currentOperatorContractType}
          options={CurrentPhoneSubscriptionContractTypeOptions}
        />
        <div className='tw-mb-4 tw-mt-4 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
          Czas trwania obecnej Umowy:
        </div>
        <MySelectInput
          className={'tw-rounded-3xl'}
          placeholder='Typ umowy'
          name='currentOperatorContractDuration'
          value={lead.currentOperatorContractDuration}
          options={ContractPhoneSubscriptionDurationOptions}
          onChange={(e: number) => updateLead({ currentOperatorContractDuration: e })}
        />
        <div className='tw-mb-4 tw-mt-4 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>Załącz:</div>
        <FileInput
          label='Skan dokumentu tożsamości'
          onFileChange={async (file: File | null) => {
            if (!file) return;

            const formData = new FormData();
            formData.append('file', file);

            return requests.post<number>('/NauMobile/SaveNauMobileLeadFile', formData).then((response) => {
              toast.success('Plik został zapisany w formularzu');
              updateLead({ identityDocumentScanFileId: response });
            });
          }}
        />
      </>
    );
  };

  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-px-2 tw-py-6 tw-shadow-md sm:tw-px-6'>
      <StepIndicator currentStep={2} totalSteps={2} />
      <div className={'tw-my-4 tw-w-fit tw-cursor-pointer'} onClick={() => setStep(StepType.CashYourOfferOne)}>
        <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={lead}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<PhoneSubscriptionLead>) => {
          return agent.NauMobile.updateLeadFormModel({
            ...values,
            currentStepId: StepType.CashYourOfferTwo,
            correspondenceAddress: values.sameAsResidential ? values.residentialAddress : values.correspondenceAddress,
            correspondenceCity: values.sameAsResidential ? values.residentialCity : values.correspondenceCity,
            correspondencePostalCode: values.sameAsResidential
              ? values.residentialPostalCode
              : values.correspondencePostalCode,
          })
            .then(() => {
              props.afterSave?.();
            })
            .finally(() => setSubmitting(false));
        }}
        validationSchema={validationSchema}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className='tw-w-full'>
            <div className='tw-mb-4 tw-mt-6  tw-text-lg tw-font-semibold tw-text-nau-sea-green'>Dane osobowe</div>
            <div className='tw-flex tw-flex-col tw-gap-4'>
              <MyTextInput name='identityDocumentType' placeholder='Typ dokumentu tożsamości' />
              <MyTextInput name='identityDocumentNumber' placeholder='Numer dok. tożsamości' />
              <MyDateInput
                label='Data ważności dokumentu'
                name='identityDocumentExpiryDate'
                value={values.identityDocumentExpiryDate}
              />{' '}
            </div>

            <div className='tw-mb-4 tw-mt-6  tw-text-lg tw-font-semibold tw-text-nau-sea-green'>Adres zamieszkania</div>
            <div className='tw-flex tw-flex-col tw-gap-4'>
              <MyTextInput name='residentialAddress' placeholder='Ulica nr domu/nr mieszkania' />
              <div className={'tw-flex tw-gap-4'}>
                <MyTextInput
                  mask={'99-999'}
                  name='residentialPostalCode'
                  placeholder='Kod pocztowy'
                  wrapperClassName={'tw-w-[140px]'}
                />
                <MyTextInput name='residentialCity' placeholder='Miasto' wrapperClassName={'tw-flex-1'} />
              </div>
            </div>
            <div className='tw-mb-4 tw-mt-6  tw-text-lg tw-font-semibold tw-text-nau-sea-green'>Dane kontaktowe</div>
            <div className='tw-flex tw-flex-col tw-gap-4'>
              <MyTextInput name='contactPhoneNumber' placeholder='Nr telefonu' />
              <MyTextInput name='contactEmail' placeholder='Adres mailowy' />
            </div>
            <div className='tw-mb-4 tw-mt-6  tw-text-lg tw-font-semibold tw-text-nau-sea-green'>
              Adres korespodencyjny
            </div>
            <div className='tw-mb-4'>
              <MyCheckbox
                label='Taki jak adres zamieszkania'
                name='sameAsResidential'
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setFieldValue('sameAsResidential', isChecked);

                  if (isChecked) {
                    setFieldValue('correspondenceAddress', values.residentialAddress);
                    setFieldValue('correspondencePostalCode', values.residentialPostalCode);
                    setFieldValue('correspondenceCity', values.residentialCity);
                  }
                }}
              />
            </div>
            {!values.sameAsResidential && (
              <div className='tw-flex tw-flex-col tw-gap-4'>
                <MyTextInput name='correspondenceAddress' placeholder='Ulica nr domu/nr mieszkania' />
                <div className={'tw-flex tw-gap-4'}>
                  <MyTextInput
                    mask={'99-999'}
                    name='correspondencePostalCode'
                    placeholder='Kod pocztowy'
                    wrapperClassName={'tw-w-[140px]'}
                  />
                  <MyTextInput name='correspondenceCity' placeholder='Miasto' wrapperClassName={'tw-flex-1'} />
                </div>
              </div>
            )}
            <div className='tw-w-full'>{lead.applicationTypeId && transferForm(lead.applicationTypeId)}</div>

            <div className='tw-mt-8'>
              <MyButton
                className='tw-w-full'
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type='submit'
                variant={'greenDark'}>
                Dalej
              </MyButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
