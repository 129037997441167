import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useSwrAgent } from '../../api/useSwrAgent';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import {
  IProfileAndInstitutionDirectorDataFormValues,
  IPhoneSubscriptionPromotionValidationFormValues,
  PhoneSubscriptionPromotionVerificationPosition,
  PhoneSubscriptionPromotionVerificationPositionOptions,
  StepType,
} from '../../types/phoneSubscription';
import { InstitutionSearchByAddressForm } from '../../components/Institution/InstitutionSearchByAddressForm';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import MySelectInput from '../../components/FormControls/MySelectInput/MySelectInput';
import agent, { requests } from '../../api/agent';
import { useStore } from '../../store/store';
import { PromoOnlyForDirectorModal } from './PromoOnlyForDirectorModal';
import { AdditionalDirectorVerificationFormModal } from './AdditionalDirectorVerificationFormModal';
import { usePhoneSubscriptionLeadStore } from '../../store/PhoneSubscription/phoneSubscriptionStore';
import { UserAddress } from '../../types/user';
import * as Yup from 'yup';
import ValidationHelper from '../../helpers/ValidationHelper';
import RegexHelpers from '../../helpers/RegexHelpers';
import { AddressPromptingForm } from '../../components/Address/AddressPromptingForm';
import MyTextInput from '../../components/FormControls/MyTextInput/MyTextInput';
import _ from 'lodash';

interface IPhoneSubscriptionPromotionValidationFormProps {
  afterSave?: () => void;
  hasPersonalData: boolean;
}

export const PhoneSubscriptionPromotionValidationForm = (props: IPhoneSubscriptionPromotionValidationFormProps) => {
  const { modalStore } = useStore();
  const { lead } = usePhoneSubscriptionLeadStore();

  const { data: userProfile, isLoading: isLoadingUserProfile } = useSwrAgent().Users.GetUserProfile();

  const personalDataValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('Pole jest wymagane'),
    lastName: Yup.string().required('Pole jest wymagane'),
    pesel: Yup.string()
      .required('Pole jest wymagane')
      .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
      .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
    userMainAddress: Yup.object().shape({
      street: Yup.string().required('Pole jest wymagane'),
      houseNumber: Yup.string().required('Pole jest wymagane'),
      postCode: Yup.string().required('Pole jest wymagane'),
      place: Yup.string().required('Pole jest wymagane'),
    }),
  });

  const defaultAddress: UserAddress = {
    id: 0,
    postCode: '',
    place: '',
    commune: '',
    post: '',
    street: '',
    houseNumber: '',
    flatNumber: '',
    district: '',
    country: 0,
    isCorrespondence: false,
  };
  const sendDirectorData = async (values: IPhoneSubscriptionPromotionValidationFormValues) => {
    return agent.NauMobile.checkIfIsDirector(values).then((response) => {
      if (response) {
        toast.success('Weryfikacja przebiegła pomyślnie');
        sendStepValue().then(() => {
          props.afterSave?.();
        });
      } else {
        toast.success('Zapisano dane profilowe');
        setTimeout(() => {
          modalStore.openModal(<AdditionalDirectorVerificationFormModal />, 'lg', null);
        }, 50);
      }
    });
  };
  const sendStepValue = async () => {
    const valuesCopy = _.cloneDeep(lead);
    return agent.NauMobile.updateLeadFormModel({ ...valuesCopy, currentStepId: StepType.SendDirectorData });
  };
  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white  tw-px-2 tw-py-6 tw-shadow-md sm:tw-px-6 '>
      {isLoadingUserProfile && <LoadingComponent />}
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: userProfile?.firstName ?? '',
          lastName: userProfile?.lastName ?? '',
          email: userProfile?.email ?? '',
          pesel: userProfile?.pesel ?? '',
          userMainAddress: userProfile?.userMainAddress ?? defaultAddress,
          userCorrespondenceAddress: userProfile?.userCorrespondenceAddress ?? defaultAddress,
          canEditPesel: userProfile?.canEditPesel,
          institutionSopId: undefined,
          institutionFullName: '',
          institutionRegon: '',
          city: '',
          zipCode: '',
          street: '',
          userPosition: PhoneSubscriptionPromotionVerificationPosition.Director,
        }}
        validationSchema={!props.hasPersonalData ? personalDataValidationSchema : undefined}
        onSubmit={async (
          values,
          { setSubmitting, setFieldValue }: FormikHelpers<IProfileAndInstitutionDirectorDataFormValues>,
        ) => {
          if (!values.institutionSopId) {
            toast.error('Wybierz placówkę');
            return;
          }

          if (values.userPosition !== PhoneSubscriptionPromotionVerificationPosition.Director) {
            modalStore.closeModal();
            setTimeout(() => {
              modalStore.openModal(<PromoOnlyForDirectorModal />, 'lg', null);
            }, 50);
            return;
          }
          const model = {
            directorFirstName: values.firstName,
            directorLastName: values.lastName,
            institutionSopId: values.institutionSopId,
            institutionFullName: values.institutionFullName,
            institutionRegon: values.institutionRegon,
            userPosition: values.userPosition,
          } as IPhoneSubscriptionPromotionValidationFormValues;
          if (!props.hasPersonalData) {
            const valuesCopy = _.cloneDeep(values);
            valuesCopy.userCorrespondenceAddress = _.cloneDeep(valuesCopy.userMainAddress);
            if (valuesCopy.userCorrespondenceAddress) {
              valuesCopy.userCorrespondenceAddress.isCorrespondence = true;
            }
            agent.UserProfile.editUserProfileNew(valuesCopy)
              .then((resp) => {
                sendDirectorData(model).finally(() => setSubmitting(false));
              })
              .finally(() => setSubmitting(false));
          } else {
            sendDirectorData(model).finally(() => setSubmitting(false));
          }
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className='tw-w-full sm:tw-px-4'>
            {!props.hasPersonalData && (
              <>
                <div
                  className={
                    'tw-mx-auto  tw-mt-6 tw-max-w-sm tw-pb-8 tw-text-center tw-text-base tw-text-nau-green-dark sm:tw-max-w-lg'
                  }>
                  Prosimy o wypełnienie swoich danych osobowych, które pomogą nam zweryfikować, czy jesteś naszym
                  klientem. Dla nowych klientów mamy również przygotowane specjalne korzyści.
                </div>
                <div className={'tw-flex tw-justify-center tw-px-2'}>
                  <div
                    className={
                      'tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-font-normal'
                    }>
                    <MyTextInput name='firstName' placeholder='Imię' wrapperClassName={'tw-w-full'} />
                    <MyTextInput name='lastName' placeholder='Nazwisko' wrapperClassName={'tw-w-full'} />
                    <MyTextInput
                      disabled={!!userProfile?.pesel}
                      name='pesel'
                      placeholder='PESEL'
                      wrapperClassName={'tw-w-full'}
                    />
                    <AddressPromptingForm
                      cityFormikName={'userMainAddress.place'}
                      flatNoFormikName={'userMainAddress.flatNumber'}
                      houseNoFormikName={'userMainAddress.houseNumber'}
                      placeholderInsteadOfLabel={true}
                      postCodeFormikName={'userMainAddress.postCode'}
                      streetFormikName={'userMainAddress.street'}
                    />
                  </div>
                </div>
                <div className='tw-mx-auto tw-mt-6 tw-max-w-sm tw-pb-2 tw-text-center tw-text-base tw-text-nau-green-dark sm:tw-max-w-lg'>
                  Uzupełnij dane dotyczące stanowiska i placówki:
                </div>
              </>
            )}
            {props.hasPersonalData && (
              <div className='tw-mb-4 tw-mt-6 tw-text-center tw-text-xl  tw-text-nau-sea-green'>
                Uzupełnij swoje dane:
              </div>
            )}
            <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-4'>
              <InstitutionSearchByAddressForm
                cityFormikName={'city'}
                institutionFullNameFormikName={'institutionFullName'}
                institutionSopIdFormikName={'institutionSopId'}
                postCodeFormikName={'zipCode'}
                regonFormikName={'institutionRegon'}
                streetFormikName={'street'}
              />
            </div>
            <div className=' tw-mb-8 tw-flex tw-flex-col tw-gap-4'>
              <MySelectInput
                bodyPortal={true}
                className='inputNumberHideArrow'
                name={`userPosition`}
                options={PhoneSubscriptionPromotionVerificationPositionOptions}
                placeholder={'limit'}
                value={values.userPosition}
              />
            </div>

            <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
              <MyButton
                className='tw-w-full'
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type='submit'
                variant={'greenDark'}>
                Dalej
              </MyButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
