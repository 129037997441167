import * as React from 'react';
import { Fragment } from 'react';
import { Image, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react-lite';
import { User } from '../../types/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  container,
  firstMenuItem,
  imageContainer,
  imageDiv,
  logoutButton,
  mobileContainer,
  navLink,
  upperMenu,
  userEmail,
  userName,
} from './styles';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { mutate } from 'swr';
import { ArrowRightCircle } from 'react-bootstrap-icons';

export default observer(() => {
  const { userStore, menuStore } = useStore();
  const user: User | undefined = userStore.getUserData();
  const handleClose = () => {
    menuStore.setClosedProfile();
  };
  const clearCache = () => mutate(() => true, undefined, { revalidate: false });
  const logout = () => {
    handleClose();
    clearCache().then(() => userStore.logout());
  };
  const logoutFromInstitution = () => {
    handleClose();
    userStore.logoutFromInstitution();
  };
  return (
    <Navbar className={'navbar-vertical'} expand={false} id='main-nav' variant='light'>
      <Offcanvas
        onHide={handleClose}
        placement='end'
        show={menuStore.checkIsProfileToggled}
        style={menuStore.isMobileView ? _.merge({}, container, mobileContainer) : container}>
        <div style={imageContainer}>
          <Image alt='logo' className='logo' src='/assets/logo.svg' style={imageDiv} />
        </div>
        <div className='d-flex justify-content-center'>
          <div style={userName}>{user ? user.firstName + ' ' + user.lastName : ''}</div>
        </div>
        <div className='d-flex justify-content-center'>
          <div style={userEmail}>{user ? user.username : ''}</div>
        </div>
        <div className='navbar-vertical-content scrollbar '>
          <Nav as='ul' className='flex-column' style={upperMenu}>
            <Fragment>
              <Nav.Item as='li' style={firstMenuItem}>
                <div onClick={logoutFromInstitution} style={navLink}>
                  <span
                    className='nav-link-text tw-flex tw-items-center tw-gap-2'
                    style={{ cursor: 'pointer' } as React.CSSProperties}>
                    Klub NAU <ArrowRightCircle className='tw-inline-flex tw-h-6 tw-w-6 tw-text-nau-green-light' />
                  </span>
                </div>
              </Nav.Item>
            </Fragment>
          </Nav>
        </div>
        <button onClick={logout} style={logoutButton}>
          <FontAwesomeIcon icon={faRightFromBracket} />
          <span> Wyloguj się</span>
        </button>
      </Offcanvas>
    </Navbar>
  );
});
