import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/store';
import { InstitutionRole } from '../../enums/roles.enums';
import { InstitutionSelectItemProps } from './types';
import React from 'react';
import MyButton from '../Buttons/MyButton/MyButton';
import SackDollarIcon from '../Icons/SackDollarIcon';

export default observer(({ item }: InstitutionSelectItemProps) => {
  const { userStore } = useStore();
  return (
    <div
      className=' tw-flex tw-w-full tw-cursor-pointer tw-rounded-xl  tw-border tw-border-gray-300 tw-p-6 tw-shadow tw-transition-all hover:tw-scale-105'
      onClick={() => userStore.setUserInstitution(item)}>
      <div className={'tw-flex tw-w-full tw-flex-col tw-gap-2 tw-text-left'}>
        <div className={'tw-flex tw-justify-between tw-text-2xl tw-font-bold'}>
          <div>
            {item.roleId === InstitutionRole.SuperAdmin && 'Administrator'}
            {item.roleId === InstitutionRole.InstitutionAdmin && 'Fundusz'}
            {item.roleId === InstitutionRole.Customer && 'Klub NAU'}
          </div>
        </div>
        {item.roleId != InstitutionRole.Customer && <span className=''>{`${item.userInstitutionName}`}</span>}

        <div className={'tw-text-base tw-font-bold tw-text-nau-green-light'}>
          {item.roleId === InstitutionRole.SuperAdmin && 'PEŁNA KONTROLA NAD PLACÓWKĄ'}
          {item.roleId === InstitutionRole.InstitutionAdmin && 'PEŁNA KONTROLA NAD TWOIM FUNDUSZEM'}
          {item.roleId === InstitutionRole.Customer && 'PROGRAM LOJALNOŚCIOWY'}
        </div>

        <div className={'tw-flex tw-items-end tw-justify-end tw-gap-6 sm:tw-justify-end'}>
          {item.roleId === InstitutionRole.SuperAdmin && (
            <svg className={'tw-h-10 tw-text-stone-600'} viewBox='0 0 640 512' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M335.5 4l288 160c15.4 8.6 21 28.1 12.4 43.5s-28.1 21-43.5 12.4L320 68.6 47.5 220c-15.4 8.6-34.9 3-43.5-12.4s-3-34.9 12.4-43.5L304.5 4c9.7-5.4 21.4-5.4 31.1 0zM320 160a40 40 0 1 1 0 80 40 40 0 1 1 0-80zM144 256a40 40 0 1 1 0 80 40 40 0 1 1 0-80zm312 40a40 40 0 1 1 80 0 40 40 0 1 1 -80 0zM226.9 491.4L200 441.5l0 38.5c0 17.7-14.3 32-32 32l-48 0c-17.7 0-32-14.3-32-32l0-38.5L61.1 491.4c-6.3 11.7-20.8 16-32.5 9.8s-16-20.8-9.8-32.5l37.9-70.3c15.3-28.5 45.1-46.3 77.5-46.3l19.5 0c16.3 0 31.9 4.5 45.4 12.6l33.6-62.3c15.3-28.5 45.1-46.3 77.5-46.3l19.5 0c32.4 0 62.1 17.8 77.5 46.3l33.6 62.3c13.5-8.1 29.1-12.6 45.4-12.6l19.5 0c32.4 0 62.1 17.8 77.5 46.3l37.9 70.3c6.3 11.7 1.9 26.2-9.8 32.5s-26.2 1.9-32.5-9.8L552 441.5l0 38.5c0 17.7-14.3 32-32 32l-48 0c-17.7 0-32-14.3-32-32l0-38.5-26.9 49.9c-6.3 11.7-20.8 16-32.5 9.8s-16-20.8-9.8-32.5l36.3-67.5c-1.7-1.7-3.2-3.6-4.3-5.8L376 345.5l0 54.5c0 17.7-14.3 32-32 32l-48 0c-17.7 0-32-14.3-32-32l0-54.5-26.9 49.9c-1.2 2.2-2.6 4.1-4.3 5.8l36.3 67.5c6.3 11.7 1.9 26.2-9.8 32.5s-26.2 1.9-32.5-9.8z'
                fill={'currentColor'}
              />
            </svg>
          )}
          {item.roleId === InstitutionRole.InstitutionAdmin && (
            <SackDollarIcon className={'tw-h-10 tw-text-stone-600'} />
          )}
          {item.roleId === InstitutionRole.Customer && (
            <svg
              className={'tw-text-stone-600'}
              height='67.9'
              viewBox='0 0 62.8 67.9'
              width='62.8'
              xmlns='http://www.w3.org/2000/svg'>
              <g data-name='Group 11324' id='Group_11324' transform='translate(-7.4 -1.9)'>
                <path
                  d='M16.7,59.7v-11s.5-7.2,8.1-7.3c7.5-.1,8.4,6.2,8.3,8.1,0,0-4.7,9-5.5,10.5,0,.1-.1,0-.1,0V49.4a2.606,2.606,0,0,0-2.6-2.3,2.721,2.721,0,0,0-2.7,2.3V59.9a.472.472,0,0,1-.5.5H17.4A.684.684,0,0,1,16.7,59.7Zm12.6.7h5.5c.1,0,.2,0,.2-.1L38.8,53c.1-.1.3-.1.3,0l3.8,7.3c0,.1.1.1.2.1h5.5c.2,0,.3-.2.2-.3L39.4,41.7c-.1-.3-.5-.3-.7,0L29.2,60C29.1,60.2,29.2,60.4,29.3,60.4ZM44.9,42v7.7L50.2,60a.1.1,0,0,0,.1.1,11.416,11.416,0,0,0,2.8.3c7.2,0,7.7-7.5,7.7-7.5V42a.472.472,0,0,0-.5-.5H55.9a.472.472,0,0,0-.5.5V52.4a2.608,2.608,0,0,1-2.6,2.2,2.533,2.533,0,0,1-2.6-2.2V42a.472.472,0,0,0-.5-.5H45.6C45.1,41.4,44.9,41.7,44.9,42Z'
                  data-name='Path 74686'
                  fill='currentColor'
                  id='Path_74686'
                />
                <path
                  d='M24,37.5l-3.9-5.4v5.4H16.7V25.1h3.4v5.3l3.8-5.3h4l-4.5,6,4.8,6.4Zm8.9-2.6h3.8v2.6H29.4V25.1h3.4v9.8Zm8.5-9.8v7.2a2.07,2.07,0,0,0,.5,1.5,1.624,1.624,0,0,0,1.4.5,1.678,1.678,0,0,0,1.4-.6,2.456,2.456,0,0,0,.5-1.5V25h3.4v7.2a5.426,5.426,0,0,1-.7,2.9,4.378,4.378,0,0,1-2,1.8,6.375,6.375,0,0,1-5.4,0,4.689,4.689,0,0,1-1.9-1.8,5.426,5.426,0,0,1-.7-2.9V25h3.5Zm18.8,7.1a3.292,3.292,0,0,1,.6,1.9,3.1,3.1,0,0,1-1.1,2.5,4.589,4.589,0,0,1-3.1.9H50.5V25.1h5.9a5.1,5.1,0,0,1,3,.8,3.043,3.043,0,0,1,1.1,2.4,2.792,2.792,0,0,1-.6,1.8,3.072,3.072,0,0,1-1.5,1A3.267,3.267,0,0,1,60.2,32.2ZM54,30.1h1.7c.9,0,1.3-.4,1.3-1.1s-.4-1.1-1.3-1.1H54Zm3.3,3.5a1.222,1.222,0,0,0-.3-.9,1.493,1.493,0,0,0-1-.3H54v2.2h2C56.9,34.7,57.3,34.4,57.3,33.6Z'
                  data-name='Path 74687'
                  fill='currentColor'
                  id='Path_74687'
                />
                <path
                  d='M60.9,13.2v3.4h5.9V66.3H10.9V16.6h5.9V13.2H7.4V69.8H70.2V13.2Z'
                  data-name='Path 74688'
                  fill='currentColor'
                  id='Path_74688'
                />
                <path
                  d='M56.7,12.1c-2.2,2.5-6.1,2.9-9.3,2.7a10.8,10.8,0,0,0,4.9-4.2,6.185,6.185,0,0,0-.1-6.3,5.074,5.074,0,0,0-4.3-2.4c-3.9,0-7.2,3.4-9.1,7.7-1.9-4.3-5.2-7.7-9.1-7.7a4.858,4.858,0,0,0-4.3,2.4,5.961,5.961,0,0,0-.1,6.3,10.8,10.8,0,0,0,4.9,4.2c-3.6.2-7.3-.3-9.3-2.7L18.7,14c2.5,2.8,6.4,3.7,10.1,3.7a37.708,37.708,0,0,0,10-1.6,37.708,37.708,0,0,0,10,1.6c3.7,0,7.6-.8,10.1-3.7ZM27.8,9.2a3.211,3.211,0,0,1,.1-3.4,1.96,1.96,0,0,1,1.9-1c2.2,0,5.9,2.7,7.4,8.6C33.2,13.5,29.2,11.7,27.8,9.2Zm12.7,4.1c1.4-5.8,5.1-8.6,7.4-8.6a2.166,2.166,0,0,1,1.9,1,3.2,3.2,0,0,1,.1,3.4C48.4,11.7,44.4,13.5,40.5,13.3Z'
                  data-name='Path 74689'
                  fill='currentColor'
                  id='Path_74689'
                />
              </g>
            </svg>
          )}

          <MyButton className={' sm:tw-w-fit'} variant={'primary'}>
            Otwórz
          </MyButton>
        </div>
      </div>
    </div>
  );
});
