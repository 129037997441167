import * as React from 'react';
import { Button, Image, Spinner } from 'react-bootstrap';
import * as style from './styles';
import { toast } from 'react-toastify';
import { useStore } from '../../../store/store';
import { InstitutionRole } from '../../../enums/roles.enums';

interface IUsersImportProps {
  withLoanBenefit?: boolean;
}
const UsersImport = (props: IUsersImportProps) => {
  const { userStore, commonStore } = useStore();

  const [file, setFile] = React.useState<string | Blob>('');
  const [fileName, setFileName] = React.useState<string | Blob>('');
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isBtnVisible, setIsBtnVisible] = React.useState<boolean>(true);
  const [response, setResponse] = React.useState<string>('');

  const saveFile = (e: any) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const uploadFile = async () => {
    const institution = userStore.getUserInstitution();
    if (institution == null || institution.institutionId <= 0 || institution.roleId != InstitutionRole.SuperAdmin) {
      toast.error('Proszę zalogować się do placówki jako admin.');
      return;
    }

    const formData = new FormData();
    await formData.append('formFile', file);
    await formData.append('guid', commonStore.authGuid ?? '');
    await formData.append('institutionId', institution.institutionId.toString());

    setIsSubmitting(true);

    const importFunction = props.withLoanBenefit ? userStore.usersImportWithLoanBenefit : userStore.usersImport;
    await importFunction(formData)
      .then((resp: Array<string>) => {
        setResponse(resp.join('<br />'));
        setIsSubmitting(false);
        setIsBtnVisible(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
        toast.error(error.message);
      });
  };

  return (
    <>
      <div style={style.mainContainer as React.CSSProperties}>
        <div style={style.flexContainer as React.CSSProperties}>
          <div style={style.centerFlexContainer as React.CSSProperties}>
            <label style={style.colorCenter as React.CSSProperties}>Import użytkowników</label>
          </div>
        </div>
        <div></div>
        <div style={style.positionRelative as React.CSSProperties}>
          <input
            accept='multipart/form-data'
            capture
            onChange={saveFile}
            style={style.inputHidden as React.CSSProperties}
            type='file'
          />
          <Image
            src={!file ? '/assets/greenDocument.svg' : '/assets/darkDocument.svg'}
            style={style.heightSecondPhoto as React.CSSProperties}
          />
        </div>

        <div style={style.uploadFile as React.CSSProperties}>
          {fileName == '' || fileName == undefined
            ? 'Załącz plik excel pobrany wcześniej'
            : 'Załączono plik: ' + fileName}
        </div>

        <div dangerouslySetInnerHTML={{ __html: response }}></div>
        {isBtnVisible && (
          <>
            {isSubmitting ? (
              <Button style={{ marginTop: '2rem' }} type='submit' variant='primary'>
                <span className='m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Trwa zapisywanie pliku...
              </Button>
            ) : (
              <Button
                disabled={!file}
                onClick={uploadFile}
                style={{ marginTop: '2rem' }}
                type='submit'
                variant='primary'>
                Zapisz
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UsersImport;
