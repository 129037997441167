import { create } from 'zustand/react';
import { PhoneSubscriptionLead, StepType } from '../../types/phoneSubscription';

interface PhoneSubscriptionLeadState {
  lead: PhoneSubscriptionLead;
  updateLead: (updatedLead: Partial<PhoneSubscriptionLead>) => void;
  resetLead: () => void;
  step: StepType;
  setStep: (step: StepType) => void;
}

export const usePhoneSubscriptionLeadStore = create<PhoneSubscriptionLeadState>((set) => ({
  lead: {
    id: 0,
    userId: 0,
    providerTypeId: 0,
    applicationTypeId: undefined,
    offerTypeId: 0,
    statusId: 0,
    currentStepId: StepType.Begin,
    selectedPhoneId: 0,
    selectedSubscriptionId: 0,
    phonePurchaseTypeId: undefined,
    firstName: '',
    lastName: '',
    pesel: '',
    contractPhoneNumber: '',
    contractEmail: '',
    maritalStatus: undefined,
    education: undefined,
    bankAccountNumber: undefined,
    residentialAddress: undefined,
    residentialPostalCode: undefined,
    residentialCity: undefined,
    correspondenceAddress: undefined,
    correspondencePostalCode: undefined,
    correspondenceCity: undefined,
    contactPhoneNumber: undefined,
    contactEmail: undefined,
    income: 0,
    loanRepaymentsSum: 0,
    dependentsCount: 0,
    monthlyHouseholdExpenses: 0,
    eInvoiceConsent: false,
    marketingConsent: false,
    identityDocumentNumber: undefined,
    identityDocumentExpiryDate: undefined,
    documentType: undefined,
    installmentMonths: 0,
    initialPhonePayment: 0,
    lifeInsuranceOwnership: false,
    policyLifeScanFileId: 0,
    fLastPaymentPolicyLifeFileId: 0,
    hasBrOrRrPolicy: false,
    hasContractNauMobile: false,
    numberToTransfer: undefined,
    currentOperator: undefined,
    currentOperatorContractType: 0,
    currentOperatorContractDuration: 0,
    identityDocumentScanFileId: 0,
    approvalForContract1: undefined,
    approvalForContract2: undefined,
    approvalForContract3: undefined,
    approvalForContract4: undefined,
    approvalForContract5: undefined,
    approvalForContract6: undefined,
    approvalForContract7: undefined,
    approvalForContract8: undefined,
    sameAsResidential: false,
  },
  updateLead: (updatedLead) =>
    set((state) => ({
      lead: {
        ...state.lead,
        ...updatedLead,
      },
    })),
  resetLead: () =>
    set(() => ({
      lead: {
        id: 0,
        userId: 0,
        providerTypeId: 0,
        applicationTypeId: undefined,
        offerTypeId: 0,
        statusId: 0,
        currentStepId: 0,
        selectedPhoneId: 0,
        selectedSubscriptionId: 0,
        phonePurchaseTypeId: 0,
        firstName: undefined,
        lastName: undefined,
        pesel: undefined,
        contractPhoneNumber: undefined,
        contractEmail: undefined,
        maritalStatus: undefined,
        education: undefined,
        bankAccountNumber: undefined,
        residentialAddress: undefined,
        residentialPostalCode: undefined,
        residentialCity: undefined,
        correspondenceAddress: undefined,
        correspondencePostalCode: undefined,
        correspondenceCity: undefined,
        contactPhoneNumber: undefined,
        contactEmail: undefined,
        income: 0,
        loanRepaymentsSum: 0,
        dependentsCount: 0,
        monthlyHouseholdExpenses: 0,
        eInvoiceConsent: false,
        marketingConsent: false,
        identityDocumentNumber: undefined,
        identityDocumentExpiryDate: undefined,
        documentType: undefined,
        installmentMonths: 0,
        initialPhonePayment: 0,
        lifeInsuranceOwnership: false,
        policyLifeScanFileId: 0,
        fLastPaymentPolicyLifeFileId: 0,
        hasBrOrRrPolicy: false,
        hasContractNauMobile: false,
        numberToTransfer: undefined,
        currentOperator: undefined,
        currentOperatorContractType: 0,
        currentOperatorContractDuration: 0,
        identityDocumentScanFileId: 0,
      },
    })),
  step: StepType.SendDirectorData,
  setStep: (step: StepType) =>
    set(() => ({
      step: step,
    })),
}));
