import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { LeadCampaignType } from '../../types/leads';
import agent from '../../api/agent';
import { PathRoute } from '../../constants/pathRoute/Route';
import { Link } from 'react-router-dom';
import RegexHelpers from '../../helpers/RegexHelpers';
import { useStore } from '../../store/store';
import MyTextInput from '../../components/FormControls/MyTextInput/MyTextInput';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import { useSwrAgent } from '../../api/useSwrAgent';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';

const nauMobilePromoSchema = Yup.object().shape({
  phone: Yup.string().matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu').required('Pole jest wymagane'),
  email: Yup.string().required('Pole jest wymagane'),
  firstName: Yup.string().required('Pole jest wymagane'),
  lastName: Yup.string().required('Pole jest wymagane'),
  contactApproval: Yup.bool().oneOf([true], 'Pole jest wymagane'),
});

export const AdditionalDirectorVerificationFormModal = () => {
  const { modalStore } = useStore();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { data: userProfile, isLoading: isLoadingUserProfile } = useSwrAgent().Users.GetUserProfile();

  return (
    <div>
      <LoadingComponent content='Ładowanie...' visible={isLoadingUserProfile} />
      {formSubmitted ? (
        <div className={'tw-py-6'}>
          <div className='tw-mb-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
            Dziękujemy za wypełnienie formularza.
          </div>

          <div className='tw-my-8 tw-text-center tw-text-base tw-text-nau-green-dark'>Proszę oczekiwać na kontakt.</div>

          <div className={'tw-flex '}>
            <Link className={'tw-w-full'} to={PathRoute.PUSTY}>
              <Button
                className={'tw-w-full'}
                onClick={() => {
                  modalStore.closeModal();
                }}
                variant='primary'>
                Wróć do strony głównej
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{
            campaignType: LeadCampaignType.NauMobileNotDirectorContact,
            campaignTypeHumanName: LeadCampaignType.NauMobileNotDirectorContact,
            phone: '',
            firstName: userProfile?.firstName || '',
            lastName: userProfile?.lastName || '',
            email: userProfile?.email || '',
            contactApproval: true,
          }}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              await agent.Lead.sendCallBookLead(values);
              setFormSubmitted(true);
            } catch (error) {
              console.error('Error sending request', error);
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={nauMobilePromoSchema}>
          {({ isSubmitting, errors }) => (
            <Form className='tw-w-full '>
              <div className={'tw-mb-8 tw-text-center tw-text-2xl tw-font-semibold tw-text-nau-sea-green'}>
                Aby dokończyć proces weryfikacji Promocji dla Dyrektorów, pozostaw dane, a nasz pracownik skontaktuje
                się z Tobą{' '}
              </div>
              <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-6'>
                <MyTextInput name='firstName' placeholder='Imię' />
                <MyTextInput name='lastName' placeholder='Nazwisko' />
                <MyTextInput name='phone' placeholder='Nr telefonu' />
                <MyTextInput name='email' placeholder='Email' />
              </div>

              <div className={'tw-mt-8 tw-flex tw-flex-col tw-gap-4'}>
                <MyButton className={'tw-w-full'} disabled={isSubmitting} isLoading={isSubmitting} variant='primary'>
                  Jestem zainteresowany
                </MyButton>
                <div className={'tw-text-sm tw-text-nau-gray-2'}>
                  Wyrażam zgodę na telefoniczny lub e-mailowy kontakt ze mną przez NAU Profit sp. z o. o. z siedzibą w
                  Warszawie, w celu związanym z otrzymaniem informacji dotyczących urządzenia / usługi, którym/ą jestem
                  zainteresowany/a.
                </div>

                <Link className={'tw-w-full'} to={PathRoute.PUSTY}>
                  <MyButton
                    className={'tw-w-full'}
                    onClick={() => {
                      modalStore.closeModal();
                    }}
                    variant='gray'>
                    Wróć
                  </MyButton>
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
