import { observer } from 'mobx-react-lite';
import React, { ReactNode, useEffect } from 'react';
import { useStore } from '../../store/store';
import Header from '../../components/Header/Header';
import InstitutionAdminMainSideBar from '../../components/InstitutionAdmin/InstitutionAdminMainMenu/InstitutionAdminMainSideBar';
import { Redirect } from 'react-router-dom';
import { PathRoute } from '../../constants/pathRoute/Route';
import ProfileMenuSideBar from '../../components/AdminProfileMenu/ProfileMenuSideBar';
import InstitutionAdminAuthorization from '../../components/InstitutionAdmin/IntitutionAdminAuthorization/IntitutionAdminAuthorization';

interface InstitutionAdminDashboardProps {
  children: ReactNode;
}
export default observer(({ children }: InstitutionAdminDashboardProps) => {
  const { userStore, commonStore } = useStore();
  useEffect(() => {
    if (commonStore.authGuid != null) {
      userStore.checkInstitutionAdminAuthorization();
    }
  }, [userStore]);
  return (
    <>
      {!userStore.isLoggedInstAdmin ? (
        <Redirect to={PathRoute.PUSTY} />
      ) : (
        <div style={{ backgroundColor: ' #ececec ', minHeight: 'calc(100vh - 5em)' }}>
          {userStore.isAuthorizedToInst && <InstitutionAdminMainSideBar />}
          <ProfileMenuSideBar />
          <Header />
          {!userStore.isAuthorizedToInst ? <InstitutionAdminAuthorization /> : <main>{children}</main>}
        </div>
      )}
    </>
  );
});
