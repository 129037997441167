import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from 'react';
import { Image, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { NavLink } from 'react-router-dom';
import { institutionAdminDashboardRoutes } from '../../../routes/routes';
import { Route } from '../../../types/route';
import {
  container,
  firstMenuItem,
  imageDiv,
  menuItem,
  mobileContainer,
  navLink,
  subMenuItem,
  upperMenu,
  webContainer,
} from './styles';
import _ from 'lodash';
import { User } from '../../../types/user';
import React from 'react';
import InstitutionAdminDashboard from '../../../containers/InstitutionAdminDashboard/InstitutionAdminDashboard';

export default observer(() => {
  const { userStore } = useStore();

  useEffect(() => {
    console.log('InstitutionAdminSportCards');
  }, []);
  return <InstitutionAdminDashboard>Karty sportowe</InstitutionAdminDashboard>;
});
