import React from 'react';

export const RequiredApproval = (label: string) => {
  return (
    <div className={'tw-flex tw-items-start'}>
      <div
        className={'tw-min-w-[15px] tw-pr-[5px] tw-text-lg tw-font-bold'}
        style={{ color: '#DC1C1C' } as React.CSSProperties}>
        *
      </div>
      <div>{label}</div>
    </div>
  );
};
