import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import MyFooterContact from '../../MyFooterContact/MyFooterContact';
import * as styles from './styles';
import { toast } from 'react-toastify';
import { InstitutionRole } from '../../../enums/roles.enums';
import MyTextMaskedInput from '../../FormControls/MyTextMaskedInput/MyTextMaskedInput';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { UserInstitution, UserPhoneModel } from '../../../types/user';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';

export default observer(() => {
  const { userStore, userProfileStore } = useStore();
  const [authGuid, setAuthGuid] = useState<string | null>(null);
  const [institution, setInstitution] = useState<UserInstitution | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSent, setSent] = useState<boolean>(false);
  const [isSending, setSending] = useState<boolean>(false);

  useEffect(() => {
    const userInstitution = userStore.getUserInstitution();
    if (
      userInstitution == null ||
      userInstitution.institutionId <= 0 ||
      userInstitution.roleId != InstitutionRole.InstitutionAdmin
    ) {
      toast.error('Proszę zalogować się do placówki jako admin.');
      return;
    }
    setInstitution(userInstitution);
    userProfileStore
      .getUserProfilePhones()
      .then((data: UserPhoneModel[] | null) => {
        if (data) {
          const mainPhone = data.find((x) => x.isMain);
          sendSms(userInstitution, mainPhone?.id);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(`Error in promises ${error}`);
        setLoading(false);
        toast.error('Wystąpił błąd przy pobieraniu danych');
      });
  }, []);
  const sendSms = (institution: UserInstitution | null, phoneId?: number) => {
    if (phoneId && phoneId != null && phoneId != 0 && institution != null) {
      setSending(true);
      userStore
        .requestInstitutionAdminAuthorizatonToken(institution.institutionId, phoneId)
        .then((resp) => {
          setAuthGuid(resp);
          setSent(true);
          setSending(false);
        })
        .catch(() => setSending(false));
    } else {
      setSending(false);
    }
  };
  return (
    <div>
      {(loading || isSending) && <LoadingComponent />}
      <Formik
        initialValues={{ token: '', error: null }}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          if (authGuid != null && institution != null) {
            userStore.authorizeInstitutionAdmin(values.token, institution.institutionId, authGuid).catch((error) => {
              setErrors({ error: error });
              setSubmitting(false);
            });
          } else {
            setSubmitting(false);
          }
        }}>
        {({ handleSubmit, isSubmitting, errors: { error } }) => (
          <Form
            autoComplete='off'
            className='ui form col-md-6 offset-md-3 tw-rounded-xl tw-bg-white tw-p-8'
            onSubmit={handleSubmit}>
            <h2 style={styles.titleHeader as React.CSSProperties}>Autoryzacja sms</h2>
            {isSent && (
              <div>
                <div className={'tw-mb-10 tw-text-base tw-font-bold tw-text-nau-green-light'}>
                  Ze względów bezpieczeństwa wymagana jest werfyfikacja dwuetapowa przy użyciu kodu SMS.
                </div>
                <MyTextMaskedInput mask={MaskHelpers.token} name='token' placeholder='Hasło z tokena SMS' />
                <ErrorMessage name='error' render={() => <label style={styles.labelError}>{error}</label>} />
                {isSubmitting ? (
                  <Button className='btn-full-width mt-4' type='submit'>
                    <span className='m-1'>
                      <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                    </span>
                    Wysyłam…
                  </Button>
                ) : (
                  <Button className='btn-full-width mt-4' type='submit'>
                    Wyślij
                  </Button>
                )}
              </div>
            )}
            <MyFooterContact />
          </Form>
        )}
      </Formik>
    </div>
  );
});
