export enum LeadCampaignType {
  Car = 'car',
  Property = 'property',
  Trip = 'trip',
  SimNau = 'simNau',
  SportCard = 'sportCard',
  NauMobile = 'nauMobile',
  NauMobileDirectorContact = 'nauMobileDirectorContact',
  NauMobileNotDirectorContact = 'nauMobileNotDirectorContact',
  NauMobilePromotionUsedContact = 'nauMobilePromotionUsedContact',
}

export interface CallBookLeadFormValues {
  campaignType: LeadCampaignType;
  campaignTypeHumanName: string;
  phone: string;
  firstName: string;
  contactApproval: boolean;
  fileId?: number;
}

export interface CallBookLeadFormValues {
  campaignType: LeadCampaignType;
  campaignTypeHumanName: string;
  phone: string;
  firstName: string;
  contactApproval: boolean;
  fileId?: number;
}
