import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import MyCheckbox from '../FormControls/MyCheckbox/MyCheckbox';
import { UserApprovals } from '../../types/user';
import { useStore } from '../../store/store';
import { toast } from 'react-toastify';
import { label, labelAllApprovals, labelTextAllApprovals } from './styles';
import LoadingComponent from '../Loadings/LoadingComponent/LoadingComponent';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';
import * as Yup from 'yup';
import { FormikScrollToFieldError } from '../FormikScrollToFieldError/FormikScrollToFieldError';
import { getPathOrRedirect } from '../../utils/linkUtils';

interface IApprovalsFormProps {
  benefitId?: number;
  redirectPath?: string;
  hideHeader?: boolean;
  allRequired?: boolean;
}

export default observer((props: IApprovalsFormProps) => {
  const { userStore } = useStore();
  const [userApprovals, setUserApprovals] = useState<UserApprovals[]>(userStore.userApprovals);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (props.benefitId != undefined) {
      userStore
        .getUserApprovalsForBenefit(props.benefitId)
        .then((resp) => {
          setUserApprovals(resp);
          setLoading(false);
        })
        .catch(() => {
          setUserApprovals([]);
          setLoading(false);
        });
    } else {
      userStore
        .getUserApprovalsFromAPI()
        .then((resp) => {
          setUserApprovals(resp);
          setLoading(false);
        })
        .catch(() => {
          setUserApprovals([]);
          setLoading(false);
        });
    }
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <Formik
          enableReinitialize
          initialValues={{ userApprovals }}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            const saveApprovalsFunction = props.benefitId
              ? () => userStore.saveUserApprovalsForBenefit(values.userApprovals, props.benefitId ?? 0)
              : () => userStore.saveUserApprovals(values.userApprovals);

            saveApprovalsFunction()
              .then(() => {
                toast.success('Pomyślnie zapisano zgody.');
                setSubmitting(false);

                if (props.redirectPath) {
                  history.push(props.redirectPath);
                } else if (props.benefitId) {
                  window.location.reload();
                } else {
                  const redirectPath = getPathOrRedirect(PathRoute.PUSTY);
                  history.push(redirectPath);
                }
              })
              .catch((error) => {
                if (Array.isArray(error)) {
                  error.forEach((element) => {
                    let fieldName = element.fieldName;
                    fieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
                    fieldName = fieldName.replace('Value', 'value');
                    setFieldError(fieldName, element.message);
                    setSubmitting(false);
                  });
                } else {
                  setSubmitting(false);
                }
              });
          }}
          validationSchema={Yup.object().shape({
            userApprovals: Yup.array().of(
              Yup.object().shape({
                value: Yup.bool()
                  .nullable()
                  .when('required', {
                    is: true,
                    then: Yup.bool().nullable().oneOf([true], 'Zgoda jest wymagana'),
                  })
                  .test('required', 'Zgoda jest wymagana', (value) => {
                    if (props.allRequired) {
                      return value === true;
                    }
                    return true;
                  }),
              }),
            ),
          })}>
          {({ values, handleSubmit, isSubmitting, errors, isValid, setFieldValue, setFieldTouched, setValues }) => (
            <Form autoComplete='off' className='ui form error tw-pb-2' onSubmit={handleSubmit}>
              {!props.hideHeader && (
                <h2
                  style={{
                    color: 'teal',
                    textAlign: 'center',
                  }}>
                  Witamy w świecie Klubu NAU. Jeszcze tylko kilka formalności i będziesz mógł korzystać z unikalnych
                  przywilejów.
                </h2>
              )}
              {userApprovals != null && userApprovals.length > 0 && (
                <div className='d-flex align-items-start' style={labelAllApprovals}>
                  <MyCheckbox
                    checked={values.userApprovals.every((approval) => approval.value)}
                    label={<div style={labelTextAllApprovals}>Zaznacz wszystkie zgody</div>}
                    labelClassName={'tw-text-nau-gray'}
                    name={`allApprovals`}
                    onChange={(event) => {
                      const allChecked = (event.target as HTMLInputElement).checked;
                      setValues({
                        ...values,
                        userApprovals: values.userApprovals.map((item) => {
                          item.value = allChecked;
                          return item;
                        }),
                      });
                    }}
                  />
                </div>
              )}
              {userApprovals != null &&
                userApprovals.length > 0 &&
                values.userApprovals.map((item: UserApprovals, idx: number) => (
                  <div key={idx}>
                    {item.preContent && (
                      <div
                        style={
                          {
                            display: 'flex',
                            margin: '10px',
                          } as React.CSSProperties
                        }>
                        <label
                          className='form-check-label'
                          style={
                            {
                              fontSize: '14px',
                              paddingLeft: '25px',
                              paddingTop: '4px',
                            } as React.CSSProperties
                          }>
                          <span
                            className='approval-pre-content'
                            dangerouslySetInnerHTML={{ __html: item.preContent }}
                          />
                        </label>
                      </div>
                    )}
                    <div className='d-flex align-items-start' style={label}>
                      <MyCheckbox
                        checked={item.value}
                        label={<div className='approval-content' dangerouslySetInnerHTML={{ __html: item.content }} />}
                        name={`userApprovals.${idx}.value`}
                        onChange={(event) => {
                          const checked = (event.target as HTMLInputElement).checked;
                          setFieldValue(`userApprovals.${idx}.value`, checked);
                          setTimeout(() => {
                            setFieldTouched(`userApprovals.${idx}.value`);
                          });
                        }}
                      />
                    </div>
                  </div>
                ))}
              {isSubmitting ? (
                <Button className='btn-full-width mt-3' disabled={!isValid || isSubmitting} type='submit'>
                  <span className='m-1'>
                    <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                  </span>
                  Zapisywanie…
                </Button>
              ) : (
                <Button className='btn-full-width mt-3' disabled={isSubmitting} type='submit'>
                  Zapisz moje zgody
                </Button>
              )}
              <FormikScrollToFieldError />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
});
