import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AdminRouteProps } from './types';
import { PathRoute } from '../constants/pathRoute/Route';
import { useStore } from '../store/store';

const AdminRoute: React.FC<AdminRouteProps> = (props: AdminRouteProps) => {
  const { userStore } = useStore();

  if (!userStore.isLoggedIn) {
    return <Redirect to={PathRoute.LOGIN} />;
  } else if (!userStore.isLoggedInstSuperAdmin) {
    return <Redirect to={PathRoute.PUSTY} />;
  } else return <Route component={props.component} exact={props.exact} path={props.path} />;
};
export default AdminRoute;
