import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import InstitutionAdminDashboard from '../../../containers/InstitutionAdminDashboard/InstitutionAdminDashboard';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import { ProductTile } from './ProductTile';
import MyButton from '../../Buttons/MyButton/MyButton';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from '../../../index';

type Product = {
  image: string;
  name: string;
  category: string;
  seller: string;
  availability: string;
  pricePerUnit: string;
  totalPrice: string;
  rating: number;
  reviews: number;
};

type Invoice = {
  id: number;
  date: string;
  amount: number;
  description: string;
  status: number;
};

const products = [
  {
    image: 'https://via.placeholder.com/400x300', // Replace with real product images
    name: 'Smartfon XYZ',
    category: 'Elektronika',
    seller: 'Tech Store',
    availability: 'Dostępny',
    pricePerUnit: '2 199 zł',
    totalPrice: '2 199 zł',
    rating: 4.8,
    reviews: 152,
  },
  {
    image: 'https://via.placeholder.com/400x300',
    name: 'Buty Sportowe ABC',
    category: 'Obuwie',
    seller: 'Sport Shop',
    availability: 'Ograniczona ilość',
    pricePerUnit: '349 zł',
    totalPrice: '349 zł',
    rating: 4.7,
    reviews: 78,
  },
  {
    image: 'https://via.placeholder.com/400x300',
    name: 'Laptop UltraBook',
    category: 'Komputery',
    seller: 'Laptop World',
    availability: 'Dostępny',
    pricePerUnit: '4 499 zł',
    totalPrice: '4 499 zł',
    rating: 4.9,
    reviews: 320,
  },
  {
    image: 'https://via.placeholder.com/400x300',
    name: 'Zegarek Smart',
    category: 'Akcesoria',
    seller: 'Gadget Store',
    availability: 'Brak w magazynie',
    pricePerUnit: '999 zł',
    totalPrice: '999 zł',
    rating: 4.6,
    reviews: 45,
  },
];

const mockInvoices: Invoice[] = [
  { id: 1, date: '2024-12-01', amount: 1200, description: 'Zakup sprzętu komputerowego', status: 1 },
  { id: 2, date: '2024-12-03', amount: 800, description: 'Zakup podręczników', status: 2 },
  { id: 3, date: '2024-12-05', amount: 500, description: 'Zakup artykułów biurowych', status: 3 },
];

const InstitutionFundDashboard: React.FC = observer(() => {
  const { userStore } = useStore();
  const {
    data: fundDetails,
    isLoading,
    mutate,
  } = useSwrAgent().InstitutionAdmin.GetFundDetails(userStore.currentInstId ?? 0);

  const [showInvoices, setShowInvoices] = useState<boolean>(false);

  const getInstName = (): string | undefined => {
    const instString = window.localStorage.getItem('inst');
    if (instString) {
      const inst = JSON.parse(instString);
      return inst.userInstitutionName;
    }
  };

  useEffect(() => {
    console.log(fundDetails);
  }, [fundDetails]);

  const totalUsedAmount = mockInvoices.reduce((sum, invoice) => sum + invoice.amount, 0);

  return (
    <InstitutionAdminDashboard>
      <div className={'tw-p-4 tw-pr-8'}>
        <div className={'tw-rounded tw-bg-white'}>
          <div
            className={
              'tw-shadow-nau-shadow tw-relative tw-mb-4 tw-w-full tw-rounded-t-md tw-border tw-border-gray-300 tw-bg-nau-green-light tw-p-6'
            }>
            <div className='tw-text-xl tw-font-bold'>
              <div className={'tw-text-white'}>
                <BanknotesIcon className={'tw-mr-2 tw-inline-flex tw-h-10 tw-w-10'} /> Fundusz
              </div>
              <div className={'tw-mt-8 tw-text-sm tw-font-bold tw-text-white'}>{getInstName()}</div>
            </div>
          </div>

          <div className='tw-mx-auto tw-max-w-7xl tw-px-4 tw-py-8'>
            <div className={'tw-mb-10 tw-text-2xl tw-font-bold'}>
              <div className={''}>Aktualne saldo: {fundDetails?.configurationDetails.currentBalance || 12350} zł</div>
              <div>
                {fundDetails?.institutionFundAllocations?.map((position) => (
                  <div key={position.id}>{position.amount}</div>
                ))}
              </div>
              <div className={'tw-mt-8'}>Wykorzystaj fundusz NAU na zakupy</div>
              <div className={'tw-mt-6 tw-flex tw-gap-4'}>
                <MyButton className={''} variant={'greenDark'}>
                  Generuj fakturę
                </MyButton>
                <MyButton className={''} onClick={() => setShowInvoices(!showInvoices)} variant={'gray'}>
                  {showInvoices ? 'Schowaj' : 'Pokaż'} historię faktur
                </MyButton>
              </div>
            </div>
            <AnimatePresence>
              {showInvoices && (
                <motion.div
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  initial={{ height: 0 }}
                  key={'container'}
                  style={{ overflow: 'hidden' }}
                  transition={{ duration: 0.2 }}>
                  <div className='tw-mb-10'>
                    <h3 className='tw-mb-4 tw-text-xl tw-font-bold'>
                      Dotychczasowo wykorzystano z funduszu: {totalUsedAmount} zł
                    </h3>
                    <div className='tw-grid tw-gap-4'>
                      {mockInvoices.map((invoice) => (
                        <div
                          className='tw-rounded-md tw-border tw-border-gray-300 tw-bg-gray-100 tw-p-4 tw-shadow'
                          key={invoice.id}>
                          <p className='tw-mb-1 tw-font-bold'>{invoice.description}</p>
                          <p className={'tw-mb-1 tw-flex tw-gap-2'}>
                            Status:{' '}
                            <div
                              className={twMerge(
                                'tw-w-fit tw-rounded-2xl tw-bg-nau-green-light tw-px-2 tw-py-1 tw-text-xs tw-text-white',
                                invoice.status === 1
                                  ? 'tw-bg-green-500'
                                  : invoice.status === 2
                                    ? 'tw-bg-yellow-500'
                                    : 'tw-bg-cyan-500',
                              )}>
                              {invoice.status === 1
                                ? 'Opłacona'
                                : invoice.status === 2
                                  ? 'Oczekuje na opłatę'
                                  : 'Utworzona'}
                            </div>
                          </p>
                          <p className={'tw-mb-1'}>Kwota: {invoice.amount} zł</p>
                          <p className={'tw-mb-1'}>Data: {invoice.date}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            <h2 className='tw-mb-4 tw-text-2xl tw-font-bold'>Produkty</h2>
            <p className='tw-mb-8 tw-text-gray-500'>
              Przeglądaj nasze popularne produkty i znajdź produkt idealny dla Twojej szkoły.
            </p>
            <div className='tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4'>
              {products.map((product, index) => (
                <ProductTile key={index} {...product} />
              ))}
            </div>
            <button className='tw-hover:bg-gray-200 tw-mt-6 tw-rounded-md tw-bg-gray-100 tw-px-4 tw-py-2 tw-text-gray-800 tw-shadow-sm'>
              Dowiedz się więcej
            </button>
          </div>
        </div>
      </div>
    </InstitutionAdminDashboard>
  );
});

export default InstitutionFundDashboard;
