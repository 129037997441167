import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { useStore } from '../../../store/store';
import InstitutionAdminDashboard from '../../../containers/InstitutionAdminDashboard/InstitutionAdminDashboard';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { MyTanstackTable } from '../../Table/MyTanstackTable';
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { InstitutionEmployeeDTO } from '../../../types/institutionAdmin';
import BuildingIcon from '../../Icons/BuildingIcon';
import MyButton from '../../Buttons/MyButton/MyButton';

export default observer(() => {
  const { userStore } = useStore();
  const {
    data: employees,
    isLoading,
    mutate,
  } = useSwrAgent().InstitutionAdmin.GetEmployees(userStore.currentInstId ?? 0);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const getInstName = () => {
    const instString = window.localStorage.getItem('inst');
    if (instString != null) {
      const inst = JSON.parse(instString.toString());
      return inst.userInstitutionName;
    }
  };

  const columns = useMemo<ColumnDef<InstitutionEmployeeDTO>[]>(
    () => [
      {
        header: 'L.p.',
        cell: (row) => {
          return <div>{row.row.index + 1}</div>;
        },
        meta: { filterVariant: 'text' },
        size: 30,
      },
      {
        accessorKey: 'firstName',
        header: 'Imię',
        meta: { filterVariant: 'text' },
      },
      {
        accessorKey: 'lastName',
        header: 'Nazwisko',
        sortingFn: 'text',
        meta: { filterVariant: 'text' },
      },
      {
        accessorKey: 'email',
        meta: { filterVariant: 'text' },
        header: 'Email',
      },
      {
        accessorKey: 'phoneNumber',
        meta: { filterVariant: 'text' },
        header: 'Telefon',
        enableSorting: false,
      },
      {
        accessorKey: 'pesel',
        meta: { filterVariant: 'text' },
        header: 'PESEL',
      },
      {
        accessorKey: 'isCurrentlyEmployed',
        meta: { filterVariant: 'boolean' },

        header: 'Zatrudniony',

        cell: (row) => {
          return <div>{row.getValue() ? 'Tak' : 'Nie'}</div>;
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data: employees ?? [],
    columns,
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <InstitutionAdminDashboard>
      <div className={'tw-p-4 tw-pr-8'}>
        <div className={'tw-rounded tw-bg-white '}>
          <div
            className={
              'tw-shadow-nau-shadow tw-relative tw-mb-4 tw-w-full tw-rounded-t-md tw-border tw-border-gray-300 tw-bg-nau-green-light tw-p-6'
            }>
            <div className='tw-text-2xl tw-font-bold'>
              <div className={'tw-text-white'}>
                <BuildingIcon className={'tw-mr-2 tw-inline-flex tw-h-10 tw-w-10 '} /> Zarządzanie pracownikami
              </div>
              <div className={'tw-mt-8 tw-text-sm tw-font-bold tw-text-white'}>{getInstName()}</div>
            </div>
          </div>
          <div className={'tw-p-4 '}>
            <div className={'tw-mb-8 tw-flex tw-justify-between tw-p-2'}>
              <div className={' tw-flex tw-gap-4 tw-text-2xl tw-font-bold'}>
                <div
                  className={
                    'tw-flex tw-flex-col tw-gap-2 tw-rounded-lg tw-border tw-border-gray-300 tw-p-4 tw-shadow'
                  }>
                  <div className={'tw-text-base tw-font-normal'}>Wszyscy pracownicy</div>
                  <div
                    className={
                      'tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-3xl tw-font-bold tw-text-green-600'
                    }>
                    <svg
                      className={'tw-inline-flex tw-h-8 '}
                      fill={'currentColor'}
                      viewBox='0 0 320 512'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path d='M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304l0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-223.1L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6l29.7 0c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9 232 480c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-128-16 0z' />
                    </svg>
                    {table.getFilteredRowModel().rows.length}
                  </div>
                </div>
                <div
                  className={
                    'tw-flex tw-flex-col tw-gap-2 tw-rounded-lg tw-border tw-border-gray-300 tw-p-4 tw-shadow'
                  }>
                  <div className={'tw-text-base tw-font-normal'}>Pracownicy niezatrudnieni</div>
                  <div
                    className={
                      'tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-3xl tw-font-bold tw-text-red-600'
                    }>
                    <svg
                      className={'tw-inline-flex tw-h-8 '}
                      fill={'currentColor'}
                      viewBox='0 0 320 512'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path d='M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304l0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-223.1L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6l29.7 0c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9 232 480c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-128-16 0z' />
                    </svg>
                    {table.getFilteredRowModel().rows.filter((row) => !row.original.isCurrentlyEmployed).length}
                  </div>
                </div>
              </div>

              <div className={''}>
                <MyButton variant={'primary'}>Dodaj pracownika</MyButton>
              </div>
            </div>

            <div className={'tw-w-full tw-overflow-x-auto '}>
              <MyTanstackTable table={table} />
            </div>
          </div>
        </div>
      </div>
    </InstitutionAdminDashboard>
  );
});
