import React from 'react';
import { StarIcon } from '@heroicons/react/24/solid';

interface ProductCardProps {
  image: string;
  name: string;
  category: string;
  seller: string;
  availability: string;
  pricePerUnit: string;
  totalPrice: string;
  rating: number;
  reviews: number;
}

export const ProductTile: React.FC<ProductCardProps> = ({
  image,
  name,
  category,
  seller,
  availability,
  pricePerUnit,
  totalPrice,
  rating,
  reviews,
}) => {
  return (
    <div className='tw-group tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow-md tw-transition-transform tw-duration-300 hover:tw-scale-105'>
      <div className='tw-relative'>
        <img
          alt={name}
          className='tw-h-48 tw-w-full tw-object-cover tw-transition-opacity tw-duration-300 group-hover:tw-opacity-80'
          src={image}
        />
        <div className='tw-absolute tw-left-2 tw-top-2 tw-flex tw-gap-2 tw-rounded-full tw-bg-white tw-px-3 tw-py-1 tw-text-sm tw-font-medium tw-opacity-90 tw-shadow-md tw-transition-opacity group-hover:tw-opacity-100'>
          Wybór dyrektorów <StarIcon className={'tw-h-4 tw-w-4 tw-text-yellow-500'} />
          <StarIcon className={'tw-h-4 tw-w-4 tw-text-yellow-500'} />
          <StarIcon className={'tw-h-4 tw-w-4 tw-text-yellow-500'} />
        </div>
      </div>
      <div className='tw-p-4'>
        <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>{name}</h3>
        <p className='tw-text-sm tw-text-gray-500'>Kategoria: {category}</p>
        <p className='tw-text-sm tw-text-gray-500'>Producent: {seller}</p>
        <p className='tw-text-sm tw-text-gray-500'>Dostępność: {availability}</p>
        <div className='tw-my-2 tw-flex tw-items-center'>
          <span className='tw-font-bold tw-text-yellow-500'>{rating}</span>
          <span className='tw-ml-1 tw-text-sm tw-text-gray-500'>({reviews} opinii)</span>
        </div>
        <p className='tw-text-sm tw-font-medium tw-text-gray-700'>
          {pricePerUnit} · Łącznie: {totalPrice}
        </p>
      </div>
    </div>
  );
};
