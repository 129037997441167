import React, { useEffect } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import MySelectInput from '../../../components/FormControls/MySelectInput/MySelectInput';
import MyCheckbox from '../../../components/FormControls/MyCheckbox/MyCheckbox';
import { SportCardCompany } from '../../../enums/sportCardCompany.enums';
import { SportCardStatus, SportCardSubscriptionPlan } from '../../../enums/sportCard.enums';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { useSwrAgent } from '../../../api/useSwrAgent';
import agent from '../../../api/agent';
import FileHelper from '../../../helpers/FileHelper';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { history } from '../../../index';
import { SportCardTabsE } from '../SportCardTabs/SportCardTabs';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import { ISportCardApplicationFormValues } from '../../../types/sportCard';
import { InstitutionSearchByAddressForm } from '../../../components/Institution/InstitutionSearchByAddressForm';
import RegexHelpers from '../../../helpers/RegexHelpers';
import ValidationHelper from '../../../helpers/ValidationHelper';

const mainCardSubscriptionTypeOptions = [
  { value: SportCardSubscriptionPlan.OptimalEmployee, label: 'Abonament Optymalny Pracownik - 105 zł' },
  { value: SportCardSubscriptionPlan.ComfortEmployee, label: 'Abonament Komfort Pracownik - 68 zł' },
];

const secondaryCardSubscriptionTypeOptions = [
  { value: SportCardSubscriptionPlan.OptimalCompanion, label: 'Abonament Optymalny Osoba Towarzysząca - 149 zł' },
  { value: SportCardSubscriptionPlan.OptimalChild, label: 'Abonament Optymalny Dziecko - 85 zł' },
  { value: SportCardSubscriptionPlan.OptimalChildPool, label: 'Abonament Optymalny Dziecko – Basen - 54 zł' },
  { value: SportCardSubscriptionPlan.ComfortCompanion, label: 'Abonament Komfort Osoba Towarzysząca - 119 zł' },
  { value: SportCardSubscriptionPlan.ComfortChild, label: 'Abonament Komfort Dziecko - 75 zł' },
  { value: SportCardSubscriptionPlan.ComfortChildPool, label: 'Abonament Komfort Dziecko – Basen - 48 zł' },
];

interface ISportCardApplicationFormProps {
  sportCardCompany: SportCardCompany;
  onSave?: () => void;
}
const PZUSportCardApplicationForm = (props: ISportCardApplicationFormProps) => {
  const { data: userProfile, isLoading: isLoadingUserProfile } = useSwrAgent().Users.GetUserProfile();

  const [mainCardId, setMainCardId] = React.useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const validationSchema = (sportCardCompany: SportCardCompany) =>
    Yup.object().shape({
      pesel: Yup.string()
        .required('Pole jest wymagane')
        .matches(RegexHelpers.peselRegExp, 'Nieprawidłowy PESEL')
        .test('control-sum-test', 'Nieprawidłowy PESEL', (value) => ValidationHelper.checkPeselControlSum(value)),
      firstName: Yup.string().required('Pole jest wymagane'),
      lastName: Yup.string().required('Pole jest wymagane'),
      email: Yup.string().email('Nieprawidłowy email').required('Pole jest wymagane'),
      phoneNumber: Yup.string()
        .matches(/^\d{9}$/, 'Nieprawidłowy numer telefonu')
        .required('Pole jest wymagane'),
      subscriptionPlan: Yup.number().required('Pole jest wymagane'),
      mainCardId: Yup.number().nullable(),
      company: Yup.number().required('Pole jest wymagane'),
      pzuAcceptsTerms:
        sportCardCompany === SportCardCompany.PZU
          ? Yup.bool().required('Pole jest wymagane').oneOf([true], 'Pole jest wymagane')
          : Yup.bool().notRequired(),
      pzuAcceptsDataProcessing:
        sportCardCompany === SportCardCompany.PZU
          ? Yup.bool().required('Pole jest wymagane').oneOf([true], 'Pole jest wymagane')
          : Yup.bool().notRequired(),
      pzuSelfCompletion:
        sportCardCompany === SportCardCompany.PZU
          ? Yup.bool().required('Pole jest wymagane').oneOf([true], 'Pole jest wymagane')
          : Yup.bool().notRequired(),
    });

  useEffect(() => {
    setIsLoading(true);
    agent.SportCard.getSportCardsForUser(props.sportCardCompany)
      .then((cards) => {
        const mainCardId = cards?.find(
          (card) => card.mainCardId === null && card.sportCardStatus === SportCardStatus.Active,
        )?.id;
        setMainCardId(mainCardId);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      {(isLoadingUserProfile || isLoading) && <LoadingComponent />}
      <Formik
        enableReinitialize={true}
        initialValues={{
          pesel: mainCardId ? '' : userProfile?.pesel ?? '',
          firstName: mainCardId ? '' : userProfile?.firstName ?? '',
          lastName: mainCardId ? '' : userProfile?.lastName ?? '',
          email: mainCardId ? '' : userProfile?.email ?? '',
          mainCardId: mainCardId,
          phoneNumber: '',
          subscriptionPlan: undefined,
          company: props.sportCardCompany,
          pzuAcceptsTerms: false,
          pzuAcceptsDataProcessing: false,
          pzuSelfCompletion: false,
          institutionSopId: undefined,
          institutionName: '',
          institutionRegon: '',
          submitButtonType: 'addOneCard',
          city: '',
          zipCode: '',
          street: '',
        }}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<ISportCardApplicationFormValues>) => {
          try {
            if (!values.institutionSopId && !values.mainCardId) {
              toast.error('Wybierz placówkę');
              return;
            }

            if (values.submitButtonType === 'addAnotherCard') {
              await agent.SportCard.createSportCard(values);

              const userSportCardsForCompany = await agent.SportCard.getSportCardsForUser(props.sportCardCompany);
              const mainCardId = userSportCardsForCompany?.find((card) => card.mainCardId === null)?.id;

              await setFieldValue('pesel', '');
              await setFieldValue('firstName', '');
              await setFieldValue('lastName', '');
              await setFieldValue('email', '');
              await setFieldValue('phoneNumber', '');
              await setFieldValue('subscriptionPlan', undefined);
              await setFieldValue('company', values.company);
              await setFieldValue('pzuAcceptsTerms', false);
              await setFieldValue('pzuAcceptsDataProcessing', false);
              await setFieldValue('pzuSelfCompletion', false);
              await setFieldValue('mainCardId', mainCardId);

              const element = document.getElementById('sport-card-application-form-top');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
              toast.success('Karta została dodana, prosimy o wypełnienie danych kolejnej osoby');
            } else {
              await agent.SportCard.createSportCard(values);
              toast.success('Karta została dodana');
              history.push(PathRoute.SPORT_CARD + `?tab=${SportCardTabsE.MyCards}`);
            }
          } finally {
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema(props.sportCardCompany)}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className='tw-w-full'>
            {!values.mainCardId && (
              <>
                <div
                  className='tw-mb-4 tw-mt-4 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'
                  id={'sport-card-application-form-top'}>
                  Podaj nazwę placówki, w celu weryfikacji usługi
                </div>
                <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-4'>
                  <InstitutionSearchByAddressForm
                    cityFormikName={'city'}
                    institutionFullNameFormikName={'institutionName'}
                    institutionSopIdFormikName={'institutionSopId'}
                    postCodeFormikName={'zipCode'}
                    regonFormikName={'institutionRegon'}
                    streetFormikName={'street'}
                  />
                </div>
              </>
            )}
            <div className='tw-mb-8 tw-pt-4 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
              {values.mainCardId
                ? 'Zgłoszenie karty dla dziecka lub osoby towarzyszącej'
                : 'Zgłoszenie Karty Sportowej'}
            </div>
            <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-4'>
              <MyTextInput disabled={!values.mainCardId && !!userProfile?.pesel} label='Pesel' name='pesel' />
              <MyTextInput disabled={!values.mainCardId && !!userProfile?.firstName} label='Imię' name='firstName' />
              <MyTextInput disabled={!values.mainCardId && !!userProfile?.lastName} label='Nazwisko' name='lastName' />
              <MyTextInput disabled={!values.mainCardId && !!userProfile?.email} label='Email' name='email' />
              <MyTextInput label='Numer telefonu' name='phoneNumber' />
              <MySelectInput
                className={'tw-z-50'}
                label='Plan abonamentu'
                name='subscriptionPlan'
                options={values.mainCardId ? secondaryCardSubscriptionTypeOptions : mainCardSubscriptionTypeOptions}
                value={values.subscriptionPlan}
              />

              {props.sportCardCompany === SportCardCompany.PZU && (
                <>
                  <MyCheckbox
                    checked={values.pzuAcceptsTerms}
                    label={
                      <div>
                        Znam i akceptuję Regulamin świadczenia usług drogą elektroniczną w zakresie korzystania z
                        zasobów Interfejsu wraz z załącznikami, w tym Zasadami korzystania z abonamentu PZU Sport.
                        <div
                          className='tw-transform-gpu tw-cursor-pointer tw-text-nau-green-light tw-underline tw-transition-all tw-duration-200'
                          onClick={() =>
                            FileHelper.downloadFile(
                              PathRoute.SPORT_CARD_PZU_REGULATIONS,
                              'Zasady_korzystania_z_PZU_Sport.pdf',
                            )
                          }>
                          Pobierz
                        </div>
                      </div>
                    }
                    name='pzuAcceptsTerms'
                    variant={'big'}
                  />
                  <MyCheckbox
                    checked={values.pzuAcceptsDataProcessing}
                    label={
                      <div>
                        Oświadczam, że zapoznałam/em się z informacją o przetwarzaniu moich danych osobowych, zawartą w
                        dostarczonym mi dokumencie Informacja Administratora Danych Osobowych.
                        <div
                          className='tw-transform-gpu tw-cursor-pointer tw-text-nau-green-light tw-underline tw-transition-all tw-duration-200'
                          onClick={() =>
                            FileHelper.downloadFile(
                              PathRoute.SPORT_CARD_PZU_RODO,
                              'Informacja_Administratora_Danych_Osobowych.pdf',
                            )
                          }>
                          Pobierz
                        </div>
                      </div>
                    }
                    name='pzuAcceptsDataProcessing'
                    variant={'big'}
                  />
                  <MyCheckbox
                    checked={values.pzuSelfCompletion}
                    label='Powyższy formularz wypełniłem osobiście.'
                    name='pzuSelfCompletion'
                    variant={'big'}
                  />
                </>
              )}
            </div>

            <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-4'>
              <MyButton
                className='tw-mt-4 tw-w-full'
                onClick={() =>
                  FileHelper.downloadFile(
                    PathRoute.SPORT_CARD_DEDUCTION_REQUEST_FILE,
                    'WNIOSEK O DOKONYWANIE POTRĄCEŃ.pdf',
                  )
                }
                type='button'
                variant={'greenDark'}>
                Pobierz wniosek o dokonywanie potrąceń i dostarcz go do Kadr
              </MyButton>
              <MyButton
                className='tw-w-full'
                disabled={isSubmitting}
                isLoading={isSubmitting && values.submitButtonType === 'addOneCard'}
                onClick={() => setFieldValue('submitButtonType', 'addOneCard')}
                type='submit'
                variant={'greenDark'}>
                Wyślij
              </MyButton>

              <MyButton
                className=' tw-w-full'
                disabled={isSubmitting}
                isLoading={isSubmitting && values.submitButtonType === 'addAnotherCard'}
                onClick={() => setFieldValue('submitButtonType', 'addAnotherCard')}
                type='submit'
                variant={'primary'}>
                Chcę dodać kolejną osobę towarzyszącą lub dziecko
              </MyButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PZUSportCardApplicationForm;
