import React, { useState } from 'react';
import { useStore } from '../../store/store';
import { useSwrAgent } from '../../api/useSwrAgent';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import { PhoneModel, PhoneSubscriptionLead, StepType } from '../../types/phoneSubscription';
import * as Yup from 'yup';
import { PhoneTile } from './PhoneTile';
import { usePhoneSubscriptionLeadStore } from '../../store/PhoneSubscription/phoneSubscriptionStore';
import { AnotherPhoneFormModal } from './AnotherPhoneFormModal';
import agent, { requests } from '../../api/agent';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import { PhoneDescription } from './PhoneDescription';
import { AnimatePresence, motion } from 'motion/react';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  userId: Yup.number().required('Pole "Identyfikator użytkownika" jest wymagane.'),
  statusId: Yup.number().required('Pole "Status" jest wymagane.'),
  currentStepId: Yup.number().required('Pole "Obecny krok" jest wymagane.'),
  selectedPhoneId: Yup.number().nullable(),
  selectedSubscriptionId: Yup.number().nullable(),
});

const otherPhoneModel: PhoneModel = {
  id: 0,
  modelName: 'Interesuje mnie inny model',
};

interface PhoneFormProps {
  afterSave?: () => void;
}

export const PhoneForm = (props: PhoneFormProps) => {
  const { data: phoneModels, isLoading: arePhoneModelsLoading } = useSwrAgent().NauMobile.GetAvailablePhonesModel();
  const { lead, updateLead } = usePhoneSubscriptionLeadStore();
  const { modalStore } = useStore();
  const selectedPhone = phoneModels?.find((phone) => phone.id === lead.selectedPhoneId);
  const phoneModelsToUse = [...(phoneModels ?? []), otherPhoneModel];

  const getPercentageDiscount = (values: PhoneSubscriptionLead) => {
    const hasBrOrRrPolicy = values.hasBrOrRrPolicy;
    const lifeInsuranceOwnership = values.lifeInsuranceOwnership;
    let percentageDiscount = 0;
    if (hasBrOrRrPolicy) {
      percentageDiscount += 10;
    }
    if (lifeInsuranceOwnership) {
      percentageDiscount += 10;
    }
    return percentageDiscount;
  };

  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white  tw-px-2 tw-py-6 tw-shadow-md sm:tw-px-6 '>
      <LoadingComponent content='Ładowanie...' visible={arePhoneModelsLoading} />

      <Formik
        enableReinitialize={true}
        initialValues={lead}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<PhoneSubscriptionLead>) => {
          try {
            await validationSchema.validate(values);
          } catch (error: any) {
            toast.error(error.message as string);
            return;
          }
          if (values.selectedPhoneId === null || values.selectedPhoneId === undefined) {
            toast.info('Proszę wybrać telefon');
            return;
          }

          if (values.selectedPhoneId) {
            agent.NauMobile.updateLeadFormModel({
              ...values,
              currentStepId: StepType.SelectPhone,
            }).then(() => {
              props.afterSave?.();
            });
          } else {
            modalStore.openModal(<AnotherPhoneFormModal />, 'lg', null);
          }
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className='tw-w-full'>
            <div className={'tw-w-full'}>
              <div className='tw-mb-6 tw-mt-4 tw-text-center tw-text-2xl tw-font-semibold tw-text-nau-green-dark'>
                Wybierz urządzenie
              </div>
              <div className={'tw-mx-auto tw-grid tw-max-w-[450px] tw-grid-cols-2 tw-gap-4'}>
                {phoneModelsToUse.map((product) => (
                  <PhoneTile
                    key={product.id}
                    onClick={(phoneId: number) => {
                      if (phoneId == 0 || phoneId == undefined) {
                        updateLead({
                          selectedPhoneId: phoneId,
                          offerTypeId: undefined,
                          selectedSubscriptionId: undefined,
                          applicationTypeId: undefined,
                          providerTypeId: undefined,
                          identityDocumentScanFileId: undefined,
                          currentOperatorContractDuration: undefined,
                          currentOperatorContractType: undefined,
                          currentOperator: undefined,
                          numberToTransfer: undefined,
                        });
                      } else {
                        updateLead({ selectedPhoneId: phoneId });
                      }
                    }}
                    phone={product}
                    selectedPhoneId={lead.selectedPhoneId}
                  />
                ))}
              </div>
            </div>
            <AnimatePresence>
              {selectedPhone && (
                <motion.div animate={{ opacity: 1, scale: 1 }} className={'tw-pt-4'} initial={{ opacity: 0, scale: 0 }}>
                  <PhoneDescription phone={selectedPhone} showDescription={true} showParameters={false} />
                </motion.div>
              )}
            </AnimatePresence>
            <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
              <MyButton
                className='tw-w-full'
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type='submit'
                variant={'greenDark'}>
                Dalej
              </MyButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
