import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function MyCircleCheckIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge(props.className)}
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'>
      <g data-name='Group 1822' id='Group_1822' transform='translate(-366 -699)'>
        <circle
          cx='12'
          cy='12'
          data-name='Ellipse 26'
          fill='#0bc268'
          id='Ellipse_26'
          r='12'
          transform='translate(366 699)'
        />
        <path
          d='M767,9869.289l2.887,2.887,5.25-5.25'
          data-name='Path 4820'
          fill='none'
          id='Path_4820'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          transform='translate(-393.069 -9158.551)'
        />
      </g>
    </svg>
  );
}
