import { UserAddress } from './user';

export interface PhoneSubscriptionLead {
  id: number;
  userId: number;
  providerTypeId?: number; // Nullable
  applicationTypeId?: PhoneSubscriptionLeadType; // Nullable
  offerTypeId?: number; // Nullable
  statusId: NauMobileLeadStatus;
  currentStepId: StepType;
  selectedPhoneId?: number; // Nullable
  selectedSubscriptionId?: number; // Nullable
  phonePurchaseTypeId?: PhonePurchaseType; // Nullable
  firstName?: string; // Nullable
  lastName?: string; // Nullable
  pesel?: string; // Nullable
  contractPhoneNumber?: string; // Nullable
  contractEmail?: string; // Nullable
  maritalStatus?: string; // Nullable
  education?: string; // Nullable
  bankAccountNumber?: string; // Nullable
  residentialAddress?: string; // Nullable
  residentialPostalCode?: string; // Nullable
  residentialCity?: string; // Nullable
  correspondenceAddress?: string; // Nullable
  correspondencePostalCode?: string; // Nullable
  correspondenceCity?: string; // Nullable
  contactPhoneNumber?: string; // Nullable
  contactEmail?: string; // Nullable
  income?: number; // Nullable
  loanRepaymentsSum?: number; // Nullable
  dependentsCount?: number; // Nullable
  monthlyHouseholdExpenses?: number; // Nullable
  eInvoiceConsent: boolean;
  marketingConsent: boolean;
  identityDocumentNumber?: string; // Nullable
  identityDocumentType?: string;
  identityDocumentExpiryDate?: string; // Nullable, ISO date string
  installmentMonths?: number; // Nullable
  initialPhonePayment?: number; // Nullable
  nextMonthsPayments?: number; // Nullable
  lifeInsuranceOwnership?: boolean; // Nullable
  policyLifeScanFileId?: number; // Nullable
  lastPaymentPolicyLifeFileId?: number; // Nullable
  hasBrOrRrPolicy?: boolean; // Nullable
  hasContractNauMobile?: boolean; // Nullable
  numberToTransfer?: string; // Nullable
  currentOperator?: string; // Nullable
  currentOperatorContractType?: number; // Nullable
  currentOperatorContractDuration?: number; // Nullable
  identityDocumentScanFileId?: number; // Nullable
  approvals?: string;
  identitySerialNumber?: string;
  approvalForContract1?: boolean;
  approvalForContract2?: boolean;
  approvalForContract3?: boolean;
  approvalForContract4?: boolean;
  approvalForContract5?: boolean;
  approvalForContract6?: boolean;
  approvalForContract7?: boolean;
  approvalForContract8?: boolean;
  approvalForContractSecond1?: boolean;
  approvalForContractSecond2?: boolean;
  approvalForContractSecond3?: boolean;
  sameAsResidential?: boolean;
}

export enum StepType {
  Begin = 0,
  SelectProvider = 1,
  SelectDiscounts = 2,
  SelectPhone = 3,
  SendDirectorData = 4,
  BuyPhone = 5,
  CashYourOfferTwo = 12,
  LoanYourOfferOne = 6,
  LoanYourOfferTwo = 7,
  LoanYourOfferThree = 8,
  CashYourOfferOne = 9,
  SummaryInstallments = 10,
  SummaryCash = 11,
}

export enum ProviderType {
  NauMobile = 1, // "NAU Mobile"
  SimNau = 2, // "Sim NAU"
}

export const ProviderTypeOptions = [
  { value: 1, label: 'NAU Mobile' },
  { value: 2, label: 'Sim NAU' },
];
export enum PhoneOfferType {
  Subscription = 1, // "Abonament"
  Phone = 2, // "Telefon"
  SubscriptionAndPhone = 3, // "Abonament i telefon"
}

export const PhoneOfferTypeOptions = [
  { value: PhoneOfferType.Subscription, label: 'Abonament' },
  { value: PhoneOfferType.Phone, label: 'Telefon' },
  { value: PhoneOfferType.SubscriptionAndPhone, label: 'Abonament z telefonem' },
];
export enum PhonePurchaseType {
  Cash = 1, // "Gotówka"
  Installments = 2, // "Raty"
}

export const PhonePurchaseTypeOptions = [
  { value: 1, label: 'Jednorazowa płatność' },
  { value: 2, label: 'Na raty' },
];

export enum PhoneSubscriptionLeadStatus {
  New = 1, // "Nowa"
  Waiting = 2, // "Oczekująca na akceptację"
  Active = 3, // "Aktywna"
  Rejected = 4, // "Odrzucona"
  Terminated = 5, // "Rozwiązana"
}

export const PhoneSubscriptionLeadStatusOptions = [
  { value: 1, label: 'Nowa' },
  { value: 2, label: 'Oczekująca na akceptację' },
  { value: 3, label: 'Aktywna' },
  { value: 4, label: 'Odrzucona' },
  { value: 5, label: 'Rozwiązana' },
];

export enum PhoneSubscriptionLeadType {
  BuyNewNumber = 1, // "Kup nowy numer"
  TransferNumber = 2, // "Przenieś numer"
}

export const PhoneSubscriptionLeadTypeOptions = [
  { value: 1, label: 'Kup nowy numer' },
  { value: 2, label: 'Przenieś numer' },
];

export enum CurrentPhoneSubscriptionContractType {
  Prepaid = 1, // "Na kartę"
  Subscription = 2, // "Abonament"
  Mix = 3, // "Mix"
}

export const CurrentPhoneSubscriptionContractTypeOptions = [
  { value: 1, label: 'Na kartę' },
  { value: 2, label: 'Abonament' },
  { value: 3, label: 'Mix' },
];

export enum ContractPhoneSubscriptionDuration {
  FixedTerm = 1, // "Czas określony"
  IndefiniteTerm = 2, // "Czas nieokreślony"
}

export const ContractPhoneSubscriptionDurationOptions = [
  { value: 1, label: 'Czas określony' },
  { value: 2, label: 'Czas nieokreślony' },
];

export interface PhoneModel {
  id: number;
  modelName?: string; // "Nazwa modelu"
  display?: string; // "Wyświetlacz"
  memory?: string; // "Pamięć"
  camera?: string; // "Aparat"
  operatingSystem?: string; // "System operacyjny"
  processor?: string; // "Procesor"
  description?: string; // "Opis"
  availableUnits?: number; // "Liczba dostępnych sztuk"
  reservations?: number; // "Liczba rezerwacji"
  imageId?: number;
  price?: number;
}

export interface ContractModel {
  id: number;
  name?: string; // "Nazwa"
  calls?: string; // "Rozmowy"
  smsMms?: string; // "SMS/MMS"
  internetDescription?: string; // "Opis internetu"
  generalDescription?: string; // "Opis ogólny"
  monthlyPrice: number; // "Cena za miesiąc"
  imageFileId: number; // "Zdjęcie"
  providerTypeId: number; // "Typ dostawcy"
}

export enum PhoneSubscriptionPromotionVerificationPosition {
  Director = 1,
  ViceDirector = 2,
  Teacher = 3,
  AdminEmployee = 4,
}

export const PhoneSubscriptionPromotionVerificationPositionOptions = [
  { value: PhoneSubscriptionPromotionVerificationPosition.Director, label: 'Dyrektor' },
  { value: PhoneSubscriptionPromotionVerificationPosition.ViceDirector, label: 'Wicedyrektor' },
  { value: PhoneSubscriptionPromotionVerificationPosition.Teacher, label: 'Nauczyciel' },
  { value: PhoneSubscriptionPromotionVerificationPosition.AdminEmployee, label: 'Pracownik administracji' },
];

export interface IProfileAndInstitutionDirectorDataFormValues {
  firstName: string;
  lastName: string;
  institutionSopId?: number;
  institutionFullName: string;
  institutionRegon: string;
  userPosition?: PhoneSubscriptionPromotionVerificationPosition;
  email: string;
  pesel: string;
  userMainAddress?: UserAddress;
  userCorrespondenceAddress?: UserAddress;
  canEditPesel?: boolean;
}
export interface IPhoneSubscriptionPromotionValidationFormValues {
  directorFirstName: string;
  directorLastName: string;
  institutionSopId?: number;
  institutionFullName: string;
  institutionRegon: string;
  userPosition?: PhoneSubscriptionPromotionVerificationPosition;
  hasBrOrRrPolicy?: boolean;
  lifeInsuranceOwnership?: boolean;
  policyLifeScanFileId?: number;
  lastPaymentPolicyLifeFileId?: number;
}

export interface IInterestedInAnotherPhoneFormValues {
  phoneModel: string;
  phoneColor: string;
  email: string;
  phone: string;
  contactApproval: boolean;
}

export enum NauMobileLeadStatus {
  New = 1,
  Waiting = 2,
  Approved = 3,
  Rejected = 4,
}
