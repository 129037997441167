import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function BuildingIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge(props.className)}
      fill='none'
      height='83'
      viewBox='0 0 83 83'
      width='83'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M71.4444 80V11.5556C71.4444 6.83045 67.614 3 62.8889 3H20.1111C15.386 3 11.5556 6.83045 11.5556 11.5556V80M71.4444 80L80 79.9999M71.4444 80H50.0556M11.5556 80L3 79.9999M11.5556 80H32.9444M28.6667 20.111H32.9444M28.6667 37.2221H32.9444M50.0556 20.111H54.3333M50.0556 37.2221H54.3333M32.9444 80V58.611C32.9444 56.2485 34.8597 54.3332 37.2222 54.3332H45.7778C48.1403 54.3332 50.0556 56.2485 50.0556 58.611V80M32.9444 80H50.0556'
        id='Icon'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='5'
      />
    </svg>
  );
}
