import React, { useEffect, useState } from 'react';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import { useStore } from '../../store/store';
import SubpageHeader from '../../components/SubpageHeader/SubpageHeader';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import { PhoneForm } from './PhoneForm';
import { usePhoneSubscriptionLeadStore } from '../../store/PhoneSubscription/phoneSubscriptionStore';
import agent from '../../api/agent';
import { PhonePaymentForm } from './PhonePaymentForm';
import { NauMobileLeadStatus, StepType } from '../../types/phoneSubscription';
import { InstallmentsFormOne } from './Installments/InstallmentsFormOne';
import { InstallmentsFormTwo } from './Installments/InstallmentsFormTwo';
import { InstallmentsFormThree } from './Installments/InstallmentsFormThree';
import { InstallmentThankYouPage } from './Installments/InstallmentsSummaryPage';
import { CashFormSummaryPage } from './Cash/CashFormSummaryPage';
import { CashFormOne } from './Cash/CashFormOne';
import { PathRoute } from '../../constants/pathRoute/Route';
import { history } from '../../index';
import { PhoneSubscriptionPromotionHasBeenUsedInfoModal } from './PhoneSubscriptionPromotionHasBeenUsedInfoModal';
import { CashFormTwo } from './Cash/CashFormTwo';
import { SubscriptionForm } from './SubscriptionForm';
import { PromoForm } from './PromoForm';
import { PhoneSubscriptionPromotionValidationForm } from './PhoneSubscriptionPromotionValidationForm';

export const PhoneSubscriptionMainPage = () => {
  const { modalStore } = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasPersonalData, setHasPersonalData] = useState<boolean>(false);
  const { step, setStep, updateLead } = usePhoneSubscriptionLeadStore();

  const fetchLeadData = async () => {
    setIsLoading(true);
    return agent.NauMobile.getLeadFormModel()
      .then((response) => {
        updateLead(response);
        return response;
      })
      .finally(() => setIsLoading(false));
  };

  const scrollToTop = () => {
    const topElement = document.getElementById('customer-main-container');
    topElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    setIsLoading(true);

    agent.NauMobile.getLeadFormModel()
      .then((response) => {
        if (response.statusId !== NauMobileLeadStatus.New) {
          history.push(PathRoute.PUSTY);
          modalStore.openModal(<PhoneSubscriptionPromotionHasBeenUsedInfoModal />, 'lg', null);
        } else {
          agent.Users.hasUserPersonalData().then((resp) => {
            setHasPersonalData(resp);
          });
          updateLead(response);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);
  return (
    <CustomerDashboard>
      <LoadingComponent content='Ładowanie...' visible={isLoading} />
      <div className='tw-mx-auto md:tw-w-1/2' id={'phone-subscription-page-top-element'}>
        <SubpageHeader
          headerClassName={'tw-text-3xl'}
          iconHeight={35}
          title={step == StepType.SelectPhone ? 'Specjalnie dla Ciebie' : 'Twoja oferta'}
        />
        {step === StepType.SendDirectorData && (
          <PhoneSubscriptionPromotionValidationForm
            hasPersonalData={hasPersonalData}
            afterSave={() => {
              fetchLeadData().then(() => {
                setStep(StepType.SelectPhone);
                scrollToTop();
              });
            }}
          />
        )}

        {step === StepType.SelectPhone && (
          <PhoneForm
            afterSave={() => {
              fetchLeadData().then(() => {
                setStep(StepType.SelectDiscounts);
                scrollToTop();
              });
            }}
          />
        )}
        {step === StepType.SelectDiscounts && (
          <PromoForm
            afterSave={() => {
              fetchLeadData().then(() => {
                setStep(StepType.SelectProvider);
                scrollToTop();
              });
            }}
          />
        )}
        {step === StepType.SelectProvider && (
          <SubscriptionForm
            afterSave={() => {
              fetchLeadData().then(() => {
                setStep(StepType.BuyPhone);
                scrollToTop();
              });
            }}
          />
        )}
        {step === StepType.BuyPhone && (
          <PhonePaymentForm
            afterSave={(nextStep: StepType) => {
              fetchLeadData().then(() => {
                setStep(nextStep);
                scrollToTop();
              });
            }}
          />
        )}
        {step === StepType.LoanYourOfferOne && (
          <InstallmentsFormOne
            afterSave={() => {
              fetchLeadData().then(() => {
                setStep(StepType.LoanYourOfferTwo);
                scrollToTop();
              });
            }}
          />
        )}
        {step === StepType.LoanYourOfferTwo && (
          <InstallmentsFormTwo
            afterSave={() => {
              fetchLeadData().then(() => {
                setStep(StepType.LoanYourOfferThree);
                scrollToTop();
              });
            }}
          />
        )}
        {step === StepType.LoanYourOfferThree && (
          <InstallmentsFormThree
            afterSave={() => {
              fetchLeadData().then(() => {
                setStep(StepType.SummaryInstallments);
                scrollToTop();
              });
            }}
          />
        )}

        {step === StepType.SummaryInstallments && <InstallmentThankYouPage />}

        {step === StepType.CashYourOfferOne && (
          <CashFormOne
            afterSave={() => {
              fetchLeadData().then(() => {
                setStep(StepType.CashYourOfferTwo);
                scrollToTop();
              });
            }}
          />
        )}
        {step === StepType.CashYourOfferTwo && (
          <CashFormTwo
            afterSave={() => {
              fetchLeadData().then(() => {
                setStep(StepType.SummaryCash);
                scrollToTop();
              });
            }}
          />
        )}
        {step === StepType.SummaryCash && <CashFormSummaryPage />}
      </div>
    </CustomerDashboard>
  );
};
