import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useStore } from '../../store/store';
import { PathRoute } from '../../constants/pathRoute/Route';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import CallBooking from '../../components/CallBooking/CallBooking';
import { LeadCampaignType } from '../../types/leads';

export const PromoOnlyForDirectorModal = () => {
  const { modalStore } = useStore();

  return (
    <div className='tw-w-full '>
      <div className={'tw-flex tw-flex-col tw-gap-10 tw-px-2 tw-py-6 sm:tw-px-6'}>
        <div className={'tw-text-center tw-text-2xl tw-font-semibold tw-text-nau-sea-green'}>
          Informujemy, że Promocja na dzień dzisiejszy skierowana jest do Dyrektorów placówek
        </div>
        <div className={'tw-text-center tw-text-xl tw-text-nau-sea-green'}>
          Zachęcamy do skorzystania z pozostałych benefitów{' '}
        </div>
        <div className={'tw-text-center tw-text-xl tw-text-nau-sea-green'}>
          Oferta będzie rozwijana na początku 2025 roku, jeśli jesteś zainteresowany pozostaw swoje dane do kontaktu
        </div>

        <div className={'tw-flex tw-flex-col tw-gap-4'}>
          <Button
            className={'tw-w-full'}
            onClick={() => {
              modalStore.closeModal();
              setTimeout(() => {
                modalStore.openModal(
                  <CallBooking leadCampaignType={LeadCampaignType.NauMobileDirectorContact} />,
                  'lg',
                  null,
                );
              }, 50);
            }}
            variant='primary'>
            Jestem zainteresowany
          </Button>
          <div className={'tw-text-sm tw-text-nau-gray-2'}>
            Wyrażam zgodę na telefoniczny lub e-mailowy kontakt ze mną przez NAU Profit sp. z o. o. z siedzibą w
            Warszawie, w celu związanym z otrzymaniem informacji dotyczących urządzenia / usługi, którym/ą jestem
            zainteresowany/a.
          </div>

          <Link className={'tw-w-full'} to={PathRoute.PUSTY}>
            <MyButton
              className={'tw-w-full'}
              onClick={() => {
                modalStore.closeModal();
              }}
              variant='gray'>
              Wróć do strony głównej
            </MyButton>
          </Link>
        </div>
      </div>
    </div>
  );
};
