import { useField } from 'formik';
import * as React from 'react';
import { twMerge } from '../../../index';
import { MyCheckboxProps } from './types';
import MyDisclosure from '../../Disclosure/MyDisclosure';
import { ChevronDown } from 'react-bootstrap-icons';

const variantStyles = {
  big: 'tw-h-6 tw-w-6 tw-border-nau-green-dark tw-border-2 ',
};
const variantLabelStyles = {
  big: ' tw-text-nau-gray',
};
export default function MyCheckbox({
  name,
  label,
  disabled,
  hidden,
  className,
  labelClassName,
  wrapperClassName,
  onChange,
  variant,
  align,
  moreInfoText,
  ...restProps
}: MyCheckboxProps) {
  const [field, meta] = useField(name);
  const error = meta.touched && meta.error;

  className = twMerge(
    'tw-h-4 tw-w-4 tw-cursor-pointer tw-rounded tw-ring-0 focus:tw-outline-0 focus:tw-ring-0 focus:tw-ring-transparent focus:tw-ring-offset-0',
    'tw-text-nau-gray tw-border-gray-300 tw-font-normal',
    error && 'tw-placeholder:text-red-300 tw-border-red-500 tw-text-red-900',
    disabled && 'tw-text-gray-300',
    variant && variantStyles[variant],
    align == 'start' && 'tw-mt-[5px]',
    className,
  );

  labelClassName = twMerge(variant && variantLabelStyles[variant], labelClassName);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={twMerge(hidden && 'tw-hidden', wrapperClassName)}>
      <div className={`tw-flex tw-items-${align ? align : 'center'}`}>
        <input
          {...field}
          {...restProps}
          checked={field.value}
          className={className}
          disabled={disabled}
          onChange={handleChange}
          type='checkbox'
        />
        <div
          className={`tw-ml-3 tw-text-left tw-text-sm ${align == 'center' ? 'tw-leading-6' : 'tw-flex tw-items-start'}`}>
          <label className={twMerge('tw-font-medium tw-text-gray-900', labelClassName)}>{label}</label>
        </div>
      </div>
      {moreInfoText && (
        <div>
          <MyDisclosure
            header={(open) => (
              <div className={'tw-flex tw-items-center tw-p-2 tw-text-nau-green-light'}>
                więcej
                <ChevronDown className={twMerge('tw-ml-2 tw-h-5 tw-w-5 tw-transition-all', open && 'tw-rotate-180')} />
              </div>
            )}
            renderBody={(open) => (
              <div className={'tw-px-2 tw-text-sm tw-font-normal tw-text-nau-gray'}>{moreInfoText}</div>
            )}
          />
        </div>
      )}
      {error && <div className='tw-mt-1 tw-text-left tw-text-sm tw-font-normal tw-text-red-600'>{meta.error}</div>}
    </div>
  );
}
