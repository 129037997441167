export enum Role {
  SuperAdmin = 1,
  Admin = 2,
  InstitutionAdmin = 3,
  Customer = 4,
}

export enum InstitutionRole {
  SuperAdmin = 1,
  Customer = 2,
  Director = 3,
  InstitutionAdmin = 4,
}
